import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { VideoCameraIcon, XMarkIcon } from "@heroicons/react/24/outline"

export default function QRCodeAlert({ open, setOpen, phoneFormatted, qr_code }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="flex justify-between">
                  <Dialog.Title as="h3" className="text-xl font-medium">
                    Share QR Code
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-center my-3">
                  <VideoCameraIcon className="h-4 w-4" />
                  <a href="https://txtmsg.app/l/qr_code_demo" target="_blank" className="text-blue-600 ml-2">
                    How it works
                  </a>
                </div>
                <p className="mt-3 text-base text-black/50">
                  People who scan this QR code from a mobile phone will be prompted to send a new message to your phone
                  number: {phoneFormatted}
                </p>
                <div className="mt-8 mb-4 flex justify-center">
                  <img className="w-60 lg:w-66" src={qr_code} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
