import { useCallback, useState, useEffect } from 'react'
import Rollbar from 'rollbar'

const expectedError = error => {
  if (!error) return true
  if (window.location.pathname === '/new-password') return true
  const e = JSON.stringify(error)
  return e.includes('Incorrect Email/Password') ||
    e.includes('Name has already been taken')
}

const useRollbar = () => {
  const [rollbar, setRollbar] = useState(null)
  useEffect(() => {
    setRollbar(new Rollbar({
      accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
      payload: {
        environment: process.env.REACT_APP_ENV
      }
    }))
  }, [])

  const captureError = useCallback(error => {
    if (expectedError(error)) return null
    console.error(error)
    if (process.env.NODE_ENV !== 'development' && rollbar) rollbar.error(error)
  }, [rollbar])

  return {
    captureError
  }
}

export default useRollbar
