import React, { useMemo, useCallback, useEffect, useContext, useState } from "react";
import AutocompleteCombo from "components/AutocompleteCombo";
import PeopleContext from "contexts/PeopleContext";

const ListFilter = () => {
  const {
    setSelectedListName,
    lists,
    fetchLists,
    activeList,
    setActiveList,
    setSortBy,
    setSortByOrder
  } = useContext(PeopleContext);

  useEffect(() => {
    setActiveList(() => new URLSearchParams(window.location.search).get("alid"));
  }, []);

  const [selectedList, setSelectedList] = useState(null);

  const onChange = useCallback((list) => {
    if (list) {
      setSelectedListName(list.name);
      setActiveList(list.id);

      setSortBy("created_at");
      setSortByOrder("asc");

      window.history.pushState({}, '', window.location.href.split("?")[0] + `?alid=${list.id}&desc=false&sort=created_at`);
    } else {
      setActiveList(null);

      setSortBy("firstname");
      setSortByOrder("asc");

      window.history.pushState({}, '', window.location.href.split("?")[0])
    }
    setSelectedList(list);
  }, [setSelectedList, setSelectedListName, setActiveList]);

  useEffect(() => {
    const updateOptions = async () => {
      if (!lists) {
        await fetchLists();
      }
    };

    updateOptions();    
    const list = lists?.find((list) => parseInt(list.id) === parseInt(activeList));

    setSelectedList(list || null);
    setSelectedListName(list?.name ?? '');
  }, [lists, activeList, fetchLists]);

  const options = useMemo(() => lists || [], [lists]);

  return useMemo(
    () => (
      <div className="w-full">
        <h3>Filter by list</h3>
        <AutocompleteCombo
          fullyRounded={true}
          labelText=""
          options={options}
          selectedValue={selectedList}
          onChange={onChange}
          optionKeyAttribute="id"
          optionLabelAttribute="name"
          secondaryOptionLabelAttribute="size"
        />
      </div>
    ),
    [
      onChange,
      selectedList,
      options
    ]
  );
};

export default ListFilter;
