import React, { useMemo, useCallback, forwardRef, useEffect, useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomersContext from 'contexts/CustomersContext'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import useStyles from 'pages/customers/_styles/segmentsDialog'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Fuse from 'fuse.js'
import Slide from '@material-ui/core/Slide'
import UserContext from "contexts/UserContext"

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SegmentsDialog = () => {
  const { eventId } = useContext(UserContext);
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState([])
  const [selected, setSelected] = useState(null)
  const {
    closeSegmentsDialog,
    segmentsDialog,
    getSegmentOptions,
    segmentList,
    applySegment,
    setSegmentList
  } = useContext(CustomersContext)
  const classes = useStyles()

  const handleSelect = useCallback(e => setSelected(e.target.value), [])

  useEffect(() => {
    setSearch('')
    const fetchSegmentList = async () => {
      const res = await getSegmentOptions()
      if (res) setSegmentList(res)
    }
    if (segmentsDialog) fetchSegmentList()
  }, [segmentsDialog, getSegmentOptions, setSegmentList])

  useEffect(() => {
    if (!segmentList) return
    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'name'
      ]
    }
    const fuse = new Fuse(segmentList, options)
    if (search) {
      const results = fuse.search(search)
      setFiltered(results.map(i => ({
        id: i.item.id,
        name: i.item.name
      })))
    } else setFiltered(segmentList)
  }, [search, segmentList])

  const handleAddSegment = useCallback(() => {
    applySegment(eventId, selected);
    closeSegmentsDialog()
  }, [applySegment, eventId, closeSegmentsDialog, selected])

  return useMemo(() => (
    <Dialog
      open={segmentsDialog}
      TransitionComponent={Transition}
      fullWidth
      onClose={closeSegmentsDialog}
      classes={{
        paper: classes.paper
      }}
      maxWidth='sm'
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography component='h2' variant='h6'>Select segment</Typography>
      </DialogTitle>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder='Search segments'
          onChange={e => setSearch(e.target.value)}
          value={search}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
        />
      </div>
      <DialogContent>
        <RadioGroup value={selected} onChange={handleSelect}>
          {filtered.map(cl =>
            <FormControlLabel
              value={cl.id}
              key={cl.id}
              control={<Radio color='primary' />}
              label={cl.name}
            />)}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSegmentsDialog}>
            Cancel
        </Button>
        <Button onClick={handleAddSegment} variant='contained' color='primary'>
            Add
        </Button>
      </DialogActions>
    </Dialog>
  ), [classes.dialogTitle, classes.inputInput, classes.inputRoot, classes.paper, classes.search, classes.searchIcon, closeSegmentsDialog, filtered, handleAddSegment, handleSelect, search, segmentsDialog, selected])
}

export default SegmentsDialog
