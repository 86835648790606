import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    fontWeight: 'bold',
    textTransform: 'initial',
    fontSize: 16,
    color: 'white'
  },
  logo: {
    width: 200,
    marginBottom: theme.spacing(2)
  },
  fieldTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: 16
  },
  forgotPass: {
    textAlign: 'right'
  },
  forgotPassLink: {
    color: theme.palette.text.primary,
    textDecoration: 'underline'
  },
  or: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.midGray.main
  }
}))
