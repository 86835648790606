import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  inputs: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1 / 2)
  }
}))

export default useStyles
