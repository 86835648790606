import React, { useCallback, useContext, useEffect, useState } from "react";
import MainLayout from "components/MainLayout";
import Tabs from "components/Tabs";
import { settingsTabs } from "utils/navigation";
import UserContext from "contexts/UserContext";
import { useSnackbar } from "notistack";

const AccountSettings = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    phone,
    setPhone,
    inboundMessageOptIn,
    setInboundMessageOptIn,
    loading,
    onUpdate
  } = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [pages, setPages] = useState([
    { name: 'Settings', href: '/settings', current: true }
  ]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: "Settings", href: `/settings`, current: false },
        { name: "Account", href: `/settings/account`, current: true },
      ]);
    } else if (scrollPosition < 120) {
      setPages([
        { name: "Settings", href: `/settings`, current: false },
      ]);
    }
  }, [scrollPosition])

  const onSaveAccountSettings = useCallback(() => {
    onUpdate(enqueueSnackbar);
  }, [onUpdate, enqueueSnackbar]);

  return (
    <MainLayout pages={pages}>
      <Tabs title="Settings" tabs={settingsTabs} current="account" />
      <div className="w-full max-w-500">
        <form>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    First name
                  </label>
                  <div className="mt-2">
                    <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        type="text"
                        name="firstname"
                        id="firstname"
                        autoComplete="firstName"
                        required={true}
                        placeholder="Tina"
                        value={firstName || ""}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    Last name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        autoComplete="lastName"
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        placeholder="Fey"
                        value={lastName || ""}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-slate-500"
                        placeholder="foo@bar.com"
                        value={email || ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    Phone number
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="phone"
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-slate-500"
                        placeholder="+15552345484"
                        value={phone || ""}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <fieldset>
                    <div className="mt-3 space-y-3">
                      <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="notification"
                            name="notification"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            checked={inboundMessageOptIn}
                            value={inboundMessageOptIn}
                            onChange={(e) => setInboundMessageOptIn(e.target.checked)}
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="notification" className="font-medium text-gray-900">
                            Text me when a contact sends an inbound message
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              onClick={onSaveAccountSettings}
              type="button"
              disabled={loading}
              className="mr-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {`${loading ? "Saving..." : "Save"}`}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  )
};

export default AccountSettings;
