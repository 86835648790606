import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  overrides: {
    MuiChip: {
      label: {
        fontSize: 16,
        color: '#FFF'
      },
      deleteIconColorPrimary: {
        color: 'rgba(255, 255, 255, 0.7)'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        fontWeight: 'bold'
      },
      containedPrimary: {
        color: '#FFF'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000',
        fontSize: 12
      },
      popper: {
        marginBottom: -20
      }
    },
    MuiTableCell: {
      root: {
        padding: 8
      },
      body: {
        padding: 8
      },
      head: {
        fontWeight: 'bold'
      }
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          backgroundColor: 'none'
        }
      }
    }
  },
  typography: {
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 24,
      color: '#000000'
    },
    pageSubtitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#000'
    },
    fontFamily: 'Inter var'
  },
  palette: {
    primary: {
      main: '#242FB6'
    },
    midGray: {
      main: '#808589'
    },
    lightGray: {
      main: '#E4E5E6'
    }
  }
})
