import React, { forwardRef, useEffect, useState, useCallback, useContext, useRef, useMemo } from 'react'
import EmailEditor from 'react-email-editor'
import CustomersContext from 'contexts/CustomersContext'
import Grid from '@material-ui/core/Grid'
import useStyles from 'pages/customers/broadcastDialog/_styles/createTemplate'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import cx from 'classnames'
import CONCIERGE_MERGE_TAGS from 'constants/mergeTags'
import EMOJI_REGEXP from 'constants/emojiRegexp'

import { ButtonGroup } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import useRollbar from '../../../hooks/useRollbar'
import { Base64 } from 'js-base64';

const EditTemplate = forwardRef((props, ref) => {
  const emailEditorRef = useRef(null)
  const { selectedTemplate, editTemplate, updateTemplate } = useContext(CustomersContext)
  const classes = useStyles()
  const gridRef = useRef(null)
  const [fullscreen, setFullscreen] = useState(true)
  const [title, setTitle] = useState('')
  const [saving, setSaving] = useState(false)
  const [subject, setSubject] = useState('')
  const [error, setError] = useState(false)
  const [privateTemplate, setPrivateTemplate] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { captureError } = useRollbar()
  const { closeBroadcastDialog } = useContext(CustomersContext)

  const handlePrivateTemplate = useCallback(v => {
    setPrivateTemplate(v.target.checked)
  }, [])

  const onLoad = useCallback(() => {
    setTimeout(() => {
      if (emailEditorRef.current?.editor?.setMergeTags) {
        emailEditorRef.current.editor.setMergeTags(CONCIERGE_MERGE_TAGS)
      }
    }, 100)
    const parentWidth = gridRef.current?.offsetParent?.offsetWidth
    if (parentWidth && editTemplate) {
      gridRef.current.firstChild.firstChild.firstChild.style.minWidth = !fullscreen ? `${parentWidth}px` : '100vw'
    }
  }, [fullscreen, editTemplate])

  useEffect(() => {
    setPrivateTemplate(selectedTemplate?.private || false)
    if (selectedTemplate?.htmlDesign && selectedTemplate.htmlDesign !== '""') {
      const design = typeof selectedTemplate.htmlDesign === 'string' ? JSON.parse(selectedTemplate.htmlDesign) : selectedTemplate.htmlDesign
      emailEditorRef.current.editor.loadDesign(design)
    }
    if (selectedTemplate?.title) setTitle(selectedTemplate.title)
    if (selectedTemplate?.subject) setSubject(selectedTemplate.subject)
  }, [selectedTemplate])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  useEffect(() => {
    window.addEventListener('resize', onLoad)
    const handleEsc = (event) => {
      if (event.keyCode === 27 && fullscreen) {
        event.preventDefault()
        setFullscreen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      window.removeEventListener('resize', onLoad)
    }
  }, [fullscreen, onLoad])

  const handleTitle = useCallback(e => setTitle(e.target.value), [])
  const handleSubject = useCallback(e => setSubject(e.target.value), [])
  const handleCancel = useCallback(e => closeBroadcastDialog(), [closeBroadcastDialog])
  const handleSave = useCallback(() => ref && ref.current(), [ref])

  const onSave = useCallback(() => {
    if (!title || !subject) setError(true)
    else {
      setSaving(true)
      emailEditorRef.current.editor.exportHtml(async (data) => {
        const { design, html } = data
        if (JSON.stringify(design).match(EMOJI_REGEXP)) {
          enqueueSnackbar(
            'Emojis are currently not supported', {
              autoHideDuration: 3000,
              variant: 'error'
            })
          captureError('EditTemplate: An emoji(s) was found in a template body')
          setSaving(false)
          return
        }
        try {
          await updateTemplate(
            title,
            subject,
            Base64.encode(JSON.stringify(design).replace(/’/g, "'")),
            Base64.encode(html.replace(/’/g, "'")),
            privateTemplate
          )
          enqueueSnackbar(
            'Template updated', {
              autoHideDuration: 3000,
              variant: 'success'
            })
          setSaving(false)
        } catch (error) {
          setSaving(false)
          captureError(error)
          enqueueSnackbar(
            'Error while saving your template', {
              autoHideDuration: 3000,
              variant: 'error'
            })
        }
      })
    }
  }, [captureError, enqueueSnackbar, privateTemplate, subject, title, updateTemplate])

  ref.current = onSave

  return useMemo(() => (
    <Grid className={cx(fullscreen ? classes.fullscreen : classes.container, !editTemplate && classes.hidden)} container>
      <Grid container>
        <Grid item className={classes.textField} xs={12}>
          <h3>Edit Template</h3>
        </Grid>
        <Grid item className={classes.textField} xs={3}>
          <TextField
            value={title}
            onChange={handleTitle}
            variant='outlined'
            size='small'
            error={error && !title}
            label='Title'
            fullWidth
          />
        </Grid>
        <Grid item className={classes.textField} xs={3}>
          <TextField
            value={subject}
            error={error && !subject}
            onChange={handleSubject}
            variant='outlined'
            size='small'
            label='Subject'
            fullWidth
          />
        </Grid>
        <Grid item className={classes.textField} xs={3} container>
          <FormControlLabel
            className={classes.privateLabel}
            control={
              <Checkbox
                checked={privateTemplate}
                onChange={handlePrivateTemplate}
                color='primary'
              />
            }
            label='Keep this template private'
          />
          {privateTemplate}

        </Grid>
        <Grid item className={classes.textField} xs={3} container>
          {saving
            ? <Grid container justifyContent='center'>
              Saving ..
              </Grid>
            : <ButtonGroup>
              <Button
                className={classes.buttonTest}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                onClick={handleSave}
              >
                Save
              </Button>
              </ButtonGroup>}
        </Grid>
      </Grid>
      <Grid className={classes.iframe} ref={gridRef}>
        <EmailEditor
          options={{ features: { textEditor: { emojis: false } } }}
          ref={emailEditorRef}
          onLoad={onLoad}
        />
      </Grid>
    </Grid>
  ), [fullscreen, classes.fullscreen, classes.container, classes.hidden, classes.textField, classes.privateLabel, classes.buttonTest, classes.iframe, editTemplate, title, handleTitle, error, subject, handleSubject, privateTemplate, handlePrivateTemplate, saving, handleCancel, handleSave, onLoad])
})

export default EditTemplate
