import { useMemo, useCallback, useEffect, useState, useContext } from 'react'
import useApi from 'hooks/useApi'
import {
  CUSTOMER_SEARCH
} from 'utils/queries'
import { sortQuery, getSearchConditions } from 'utils/formatter'
import useCustomersFilter from 'hooks/useCustomersFilter'
import useCustomersOptions from 'hooks/useCustomersOptions'
import useCustomersSelect from 'hooks/useCustomersSelect'
import useCustomersBroadcast from 'hooks/useCustomersBroadcast'
import useSegment from 'hooks/useSegment'
import useRollbar from 'hooks/useRollbar'
import UserContext from "contexts/UserContext"

const pageSize = 50;

const useCustomers = () => {
  const { eventId } = useContext(UserContext);
  const { query } = useApi()
  const filterHooks = useCustomersFilter()
  const optionsHooks = useCustomersOptions()
  const {
    state,
    dispatch,
    debounceState
  } = filterHooks
  const segmentHooks = useSegment(state, dispatch, filterHooks.resetAll)
  const {
    clearSelect,
    selectAllCustomers: selectAllCustomersHook,
    selectedCustomers,
    ...selectHooks
  } = useCustomersSelect(state)
  const broadcastHooks = useCustomersBroadcast(selectedCustomers)
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const { captureError } = useRollbar()

  const hasPrevious = useMemo(() => page > 1, [page])
  const hasNextTraditional = useMemo(() => total > pageSize * page, [page, total])
  const hasNext = useMemo(() => total > customers.length, [customers, total])

  const selectAllCustomers = useCallback(
    () => selectAllCustomersHook(setLoading, debounceState, total),
    [debounceState, selectAllCustomersHook, total])

  const [prevSearch, setPrevSearch] = useState('')
  const [prevSort, setPrevSort] = useState('')
  const [clickedCustomer, setClickedCustomer] = useState({})

  const [selectedListName, setSelectedListName] = useState("");
  
  useEffect(() => {
    if (!eventId) return;

    const getSearch = async () => {
      try {
        const search = getSearchConditions(debounceState)
        const sort = sortQuery(debounceState.sort.id, debounceState.sort.desc)
        if (prevSearch !== search || sort !== prevSort) {
          clearSelect()
          setPrevSearch(search)
          setPrevSort(sort)
          setLoading(true)
          const res = await query(CUSTOMER_SEARCH(eventId, search, 1, sort, pageSize))
          setLoading(false)
          const { data: { advancedCustomerSearch: { customers, totalCount } } } = res
          setCustomers(customers)
          setTotal(totalCount)
          setPage(1)
        }
      } catch (e) {
        captureError(e)
        setLoading(false)
      }
    }
    getSearch()
  }, [debounceState, page, query, prevSearch, customers, prevSort, clearSelect, captureError, eventId])

  const traditionalPreviousPage = useCallback(async () => {
    setLoading(true)
    const res = await query(CUSTOMER_SEARCH(eventId, prevSearch, page - 1, prevSort, pageSize))
    const { data: { advancedCustomerSearch: { customers: nc } } } = res
    setCustomers(nc)
    setPage(page - 1)
    setLoading(false)
  }, [page, prevSearch, prevSort, query, eventId])

  const traditionalNextPage = useCallback(async () => {
    setLoading(true)
    const res = await query(CUSTOMER_SEARCH(eventId, prevSearch, page + 1, prevSort, pageSize))
    const { data: { advancedCustomerSearch: { customers: nc } } } = res
    setCustomers(nc)
    setPage(page + 1)
    setLoading(false)
  }, [page, prevSearch, prevSort, query, eventId])

  const nextPage = useCallback(async () => {
    setLoading(true)
    const res = await query(CUSTOMER_SEARCH(eventId, prevSearch, page + 1, prevSort, pageSize))
    const { data: { advancedCustomerSearch: { customers: nc } } } = res
    setCustomers([...customers, ...nc])
    setPage(page + 1)
    setLoading(false)
  }, [customers, page, prevSearch, prevSort, query, eventId])

  const reloadSearch = useCallback(async () => {
    setLoading(true)
    const res = await query(CUSTOMER_SEARCH(eventId, prevSearch, 1, prevSort, pageSize))
    const { data: { advancedCustomerSearch: { customers, totalCount } } } = res
    setCustomers(customers)
    setTotal(totalCount)
    setPage(1)
    setLoading(false)
  }, [prevSearch, prevSort, query, eventId])

  return {
    customers,
    loading,
    total,
    page,
    setPage,
    selectAllCustomers,
    hasPrevious,
    hasNextTraditional,
    hasNext,
    traditionalPreviousPage,
    traditionalNextPage,
    nextPage,
    reloadSearch,
    selectedCustomers,
    clickedCustomer,
    setClickedCustomer,
    selectedListName,
    setSelectedListName,
    ...selectHooks,
    ...filterHooks,
    ...optionsHooks,
    ...segmentHooks,
    ...broadcastHooks
  }
}

export default useCustomers
