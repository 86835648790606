import React, { useCallback, useContext, useMemo } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import EmailIcon from '@material-ui/icons/Email'
import CustomersContext from 'contexts/CustomersContext'

const useStyles = makeStyles(theme => ({
  emptyContainer: {
    height: '100%'
  },
  button: {
    marginTop: theme.spacing()
  }
}))

const EmptyTemplate = () => {
  const classes = useStyles()
  const { broadcastTemplates, openCreateTemplate } = useContext(CustomersContext)
  const linkNewTemplate = useCallback(() => openCreateTemplate(), [openCreateTemplate])
  const hasTemplates = useMemo(() => broadcastTemplates?.length !== 0, [broadcastTemplates])
  const title = useMemo(() => hasTemplates ? 'No preview available' : 'No templates yet', [hasTemplates])
  const subtitle = useMemo(() => hasTemplates ? 'Select a recent template' : 'Get started sending broadcasts by creating a new template', [hasTemplates])

  const onCreate = useMemo(() => hasTemplates ? null : linkNewTemplate, [hasTemplates, linkNewTemplate])
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.emptyContainer}
      direction='column'
    >
      {!onCreate ? <VisibilityIcon /> : <EmailIcon />}
      <Typography><b>{title}</b></Typography>
      <Typography>{subtitle}</Typography>
      {onCreate &&
        <Button color='primary' onClick={onCreate} variant='contained' className={classes.button}>
          Create new template
        </Button>}
    </Grid>
  )
}

export default EmptyTemplate
