import useApi from 'hooks/useApi'
import { LIST_SEARCH } from 'utils/queries'
import { CREATE_LIST, UPDATE_LIST, ARCHIVE_LIST } from 'utils/mutations'
import { useCallback, useState } from "react";
import useRollbar from "./useRollbar";
import { enqueueSnackbar } from "notistack";

const pageSize = 50;

const useLists = () => {
  const { captureError } = useRollbar();
  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [name, setName] = useState("");
  const { apolloClient, query } = useApi();

  const fetchLists = useCallback(async (eventId) => {
    try {
      setLoading(true);
      const {
        data: { listSearch },
      } = await query(
        LIST_SEARCH(null, eventId, null, pageSize)
      );

      setLists(listSearch.lists);
    } catch (e) {
      captureError(e);
      console.log("Failed to load lists", e);
    }
    setLoading(false);
  }, [captureError, query]);

  const onCreate = useCallback(async (eventId, name) => {
    try {
      setLoading(true);

      await apolloClient.mutate({
        mutation: CREATE_LIST(eventId, name)
      });

      enqueueSnackbar("List has been created", {
        autoHideDuration: 3000,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      return false;
    }
    
    setLoading(false);
    return true;
  }, [name, apolloClient, captureError]);

  const onUpdate = useCallback(async (id, new_name) => {
    try {
      setLoading(true);

      await apolloClient.mutate({
        mutation: UPDATE_LIST(id, new_name)
      });

      enqueueSnackbar("List has been renamed", {
        autoHideDuration: 3000,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      return false;
    }
    setLoading(false);

    return true;
  }, [apolloClient, captureError]);

  const onArchive = useCallback(async (id) => {
    try {
      setLoading(true);

      await apolloClient.mutate({
        mutation: ARCHIVE_LIST(id)
      });

      enqueueSnackbar("List has been archived", {
        autoHideDuration: 3000,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      return false;
    }
    
    setLoading(false);
    return true;
  }, [apolloClient, captureError]);

  return {
    loading,
    setLoading,
    lists,
    setLists,
    fetchLists,
    name,
    setName,
    onCreate,
    onUpdate,
    onArchive
  };
};

export default useLists;
