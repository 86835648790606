import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import NumberFormat from 'react-number-format'
import Proptypes from 'prop-types'

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value
        }
      })
    }}
    thousandSeparator
    isNumericString
    prefix='$'
  />
)

NumberFormatCustom.propTypes = {
  inputRef: Proptypes.func,
  onChange: Proptypes.func
}

const RangeNumber = ({ isCurrency, fromLabel, toLabel, onChange, from, to, filterKey }: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent='space-around'
      spacing={1}
      direction='row'
    >
      <Grid item xs={6}>
        <TextField
          label={fromLabel}
          id={`fromInput-${filterKey}`}
          fullWidth
          value={from || ''}
          onChange={e => onChange(e.target.value, to)}
          type={isCurrency ? 'text' : 'number'}
          inputProps={{
            max: to
          }}
          InputProps={{
            inputComponent: isCurrency ? NumberFormatCustom : Input,
            startAdornment: <InputAdornment position='start'><div /></InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={toLabel}
          value={to || ''}
          id={`toInput-${filterKey}`}
          onChange={e => onChange(from, e.target.value)}
          fullWidth
          type={isCurrency ? 'text' : 'number'}
          inputProps={{
            min: from
          }}
          InputProps={{
            inputComponent: isCurrency ? NumberFormatCustom : Input,
            startAdornment: <InputAdornment position='start'><div /></InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  )
}

RangeNumber.propTypes = {
  onChange: Proptypes.func,
  from: Proptypes.string,
  to: Proptypes.string,
  fromLabel: Proptypes.string,
  toLabel: Proptypes.string,
  isCurrency: Proptypes.bool
}

export default React.memo(RangeNumber)
