import React, { useCallback, memo, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const AutocompleteInput = ({
  keyValue,
  label,
  onChange,
  textValue,
  options,
  multiple,
  onLoadOptions,
  inputValue,
  setInputValue
}) => {
  const selected = useMemo(() => options.find(o => o.id === textValue) || null, [options, textValue])

  const handleChange = useCallback((e, v) => {
    if (v && v.id) onChange(keyValue, v.id)
    else onChange(keyValue, null)
  }, [keyValue, onChange])

  return useMemo(() => (
    <Grid item xs={12}>
      <Autocomplete
        options={options}
        onChange={handleChange}
        value={selected}
        inputValue={inputValue}
        onInputChange={setInputValue}
        getOptionLabel={(option) => option.name}
        renderInput={(params) =>
          <TextField
            {...params}
            label={label}
            onFocus={onLoadOptions}
          />}
      />
    </Grid>
  ), [handleChange, label, onLoadOptions, options, selected, inputValue, setInputValue])
}

export default memo(AutocompleteInput)
