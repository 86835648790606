import React, { memo, useCallback, useMemo, useEffect, useState, useContext } from 'react'
import Proptypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CustomersContext from 'contexts/CustomersContext'
import LifetimeSpend from 'pages/customers/filters/LifetimeSpend'
import LastPurchase from 'pages/customers/filters/LastPurchase'
import PurchaseCount from 'pages/customers/filters/PurchaseCount'
import Address from 'pages/customers/filters/Address'
import PurchaseHistory from 'pages/customers/filters/PurchaseHistory'
import AssignedAssociate from 'pages/customers/filters/AssignedAssociate'
import CustomerProperties from 'pages/customers/filters/CustomerProperties'
import ActiveList from 'pages/customers/filters/ActiveList'
import EmailHistory from 'pages/customers/filters/EmailHistory'
import EventHistory from 'pages/customers/filters/EventHistory'
import LastContacted from 'pages/customers/filters/LastContacted'
import ContactInformation from 'pages/customers/filters/ContactInformation'
import Subscriptions from 'pages/customers/filters/Subscriptions'

const FilterOption = ({
  title,
  keyName
}) => {
  const [expanded, setExpanded] = useState(false)
  const { state } = useContext(CustomersContext)

  const changed = useMemo(() => state[keyName].changed, [keyName, state])

  useEffect(() => {
    if (keyName === 'contactInformation') return setExpanded(true)
  }, [keyName, expanded])

  useEffect(() => {
    setExpanded(changed)
  }, [changed])

  const toggleExpanded = useCallback(() => setExpanded(e => !e), [])

  const option = useMemo(() => {
    switch (keyName) {
      case 'contactInformation':
        return <ContactInformation />
      case 'subscriptions':
        return <Subscriptions />
      case 'lifetimeSpend':
        return <LifetimeSpend />
      case 'lastPurchase':
        return <LastPurchase />
      case 'purchaseCount':
        return <PurchaseCount />
      case 'address':
        return <Address />
      case 'purchaseHistory':
        return <PurchaseHistory />
      case 'assignedAssociate':
        return <AssignedAssociate />
      case 'customerProperties':
        return <CustomerProperties />
      case 'activeList':
        return <ActiveList />
      case 'emailHistory':
        return <EmailHistory />
      case 'eventHistory':
        return <EventHistory />
      case 'lastContacted':
        return <LastContacted />
      default:
        return null
    }
  }, [keyName])

  return useMemo(() => (
    <Accordion
      onChange={toggleExpanded}
      expanded={expanded}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {option}
      </AccordionDetails>
    </Accordion>
  ), [expanded, option, title, toggleExpanded])
}

FilterOption.propTypes = {
  title: Proptypes.string,
  details: Proptypes.object,
  keyName: Proptypes.string
}

export default memo(FilterOption)
