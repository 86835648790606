import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import TextInput from 'pages/customers/filters/inputs/TextInput'

const ContactInformation = () => {
  const {
    state: {
      contactInformation: {
        email,
        phone
      }
    },
    dispatch
  } = useContext(CustomersContext)

  const [curEmail, setCurEmail] = useState('')
  const [curPhone, setCurPhone] = useState('')

  const createChips = useCallback(() => {
    const chips = {}
    if (email) chips.email = `Email: ${email}`
    if (phone) chips.phone = `Phone: ${phone}`
    dispatch({ type: 'CONTACT_INFORMATION_UPDATE', params: { key: 'chips', value: chips } })
  }, [email, dispatch, phone])

  const onChange = useCallback((key, value) => {
    if (key === 'email') {
      dispatch({ type: 'CONTACT_INFORMATION_UPDATE', params: { key: 'email', value } })
    } else {
      dispatch({ type: 'CONTACT_INFORMATION_UPDATE', params: { key: 'phone', value } })
    }
  }, [dispatch])

  useEffect(() => {
    const emailString = JSON.stringify(email)
    const phoneString = JSON.stringify(phone)
    if (JSON.stringify(email) !== curEmail || JSON.stringify(phone) !== curPhone) {
      setCurEmail(emailString)
      setCurPhone(phoneString)
      createChips()
    }
  }, [setCurEmail, curEmail, createChips, email, phone, curPhone])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <TextInput label='Email' textValue={email} keyValue='email' onChange={onChange} />
      <TextInput label='Phone' textValue={phone} keyValue='phone' onChange={onChange} />
    </Grid>
  ), [email, onChange, phone])
}

export default ContactInformation
