import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 375

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  pageSubtitle: {
    ...theme.typography.pageSubtitle,
    padding: theme.spacing(2)
  },
  pageTitle: {
    ...theme.typography.pageTitle,
    padding: theme.spacing(2)
  },
  drawerPaper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    width: drawerWidth
  },
  optionsContainer: {
    width: 'auto'
  }
}))

export default useStyles
