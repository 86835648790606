import React, { useState, useEffect, useCallback } from 'react'
import useLists from 'hooks/useLists'
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

const RenameModal = (props) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const listsHooks = useLists();
  const { onUpdate } = listsHooks;

  useEffect(() => {
    setName(props.originalName);
  }, [props.originalName]);

  const handleRename = useCallback(async () => {
    setLoading(true);
    if (await onUpdate(props.id, name)) {
      props.refresh()
      props.handleClose();
    }
    setLoading(false);
  }, [props.id, props.handleClose, name]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Rename List</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter a new name for this list
        </DialogContentText>
        <TextField            
          style={{ width: "94%", margin: "3%" }}
          label="Orignal List Name"
          name="listname"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={props.handleClose}
        >
          Close
        </button>
        <button
          type="button"
          autoFocus
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleRename}
          disabled={loading}
        >
          {loading?"Saving...": "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default RenameModal;
