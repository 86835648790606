import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { LinkIcon } from "@heroicons/react/24/outline";

const AddLink = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const handleOnChange = (e) => {
    const value = e.target.value;

    setIsDisabled(value.trim() == ''); 
    props.setLinkState(value)
  }
  
  return (
    <>
      {props.type === "button" && (
        <button
          onClick={props.handleModalOpen}
          className="btn push-right mt-2 sm:mt-0 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
        >
          <LinkIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          Add Link
        </button>
      )}

      {props.type === "icon" && (
        <button
          onClick={props.handleModalOpen}
          className="disabled:opacity-25 background-transparent outline-none focus:outline-none relative inline-flex items-center
          rounded-md px-3 py-2 text-sm font-semibold text-gray-600"
        >
          <LinkIcon className="h-4 w-4 mr-1" />
          Add Link
        </button>
      )}

      <Dialog
        open={props.modalStatus}
        onClose={props.handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Add Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a URL below to insert a shortened link into your message. <br/> The shortened link redirects to your URL.
          </DialogContentText>
          <TextField            
            style={{ width: "94%", margin: "3%" }}
            label="e.g. www.google.com"
            name="link"
            fullWidth
            value={props.link}
            onChange={handleOnChange}
            autoFocus
            error={props.isError}
          />
          { props.isError && <p className="text-xs text-red-600 px-4">Invalid Link entered</p> }
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={props.handleModalClose}
          >
            Close
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-25"
            onClick={props.addLinkClickHandler}
            disabled={isDisabled}
          >
            Add Link
          </button>
        </DialogActions>
      </Dialog>

    </>
  );
};
export default AddLink;
