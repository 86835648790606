export const lastPurchase = {
  '30m': { label: 'Last 30 Days', from: '{D-30d}', to: '{D+1d}', chip: 'Most Recent Order Date: Within 30 Days' },
  '30to60': { label: '30 - 60 Days', from: '{D-60d}', to: '{D-30d}', chip: 'Most Recent Order Date: Between 30 and 60 Days' },
  '60to90': { label: '60 - 90 Days', from: '{D-90d}', to: '{D-60d}', chip: 'Most Recent Order Date: Between 60 and 90 Days' },
  '90p': { label: '90+ Days', from: '', to: '{D-90d}', chip: 'Most Recent Order Date: Before 90 Days' },
  custom: { label: 'Custom Range' },
  all: { label: 'All', from: '', to: '' }
}

export const purchaseCount = {
  0: { label: '0 Purchases', from: '0', to: '0', chip: 'Number of Orders: 0' },
  1: { label: '1 Purchase', from: '1', to: '1', chip: 'Number of Orders: 1' },
  '2+': { label: '2+ Purchases', from: '2', to: '', chip: 'Number of Orders: 2+' },
  custom: { label: 'Custom Range' },
  all: { label: 'All', from: '', to: '' }
}

export const lastContacted = {
  '30m': { label: 'Last 30 Days', from: '{D-30d}', to: '{D+1d}', chip: 'Last Contacted: Within 30 Days' },
  '30to60': { label: '30 - 60 Days', from: '{D-60d}', to: '{D-30d}', chip: 'Last Contacted: Between 30 and 60 Days' },
  '60to90': { label: '60 - 90 Days', from: '{D-90d}', to: '{D-60d}', chip: 'Last Contacted: Between 60 and 90 Days' },
  '90p': { label: '90+ Days', from: '', to: '{D-90d}', chip: 'Last Contacted: Before 90 Days' },
  never: { label: 'Never', chip: 'Last Contacted: Never' },
  custom: { label: 'Custom Range' },
  all: { label: 'All', from: '', to: '' }
}

export const eventHistory = {
  '30m': { label: 'Last 30 Days', from: '{D-30d}', to: '{D+1d}', chip: 'Within 30 Days' },
  '30to60': { label: '30 - 60 Days', from: '{D-60d}', to: '{D-30d}', chip: 'Between 30 and 60 Days' },
  '60to90': { label: '60 - 90 Days', from: '{D-90d}', to: '{D-60d}', chip: 'Between 60 and 90 Days' },
  '90p': { label: '90+ Days', from: '', to: '{D-90d}', chip: 'Before 90 Days' },
  custom: { label: 'Custom Range' },
  all: { label: 'All', from: '', to: '', chip: 'All' }
}
