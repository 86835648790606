import React, { Fragment, useContext, useEffect, useState } from "react"
import MainLayout from "components/MainLayout"
import Tabs from "components/Tabs"
import { settingsTabs } from "utils/navigation"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import UserContext from "contexts/UserContext"
import useRouter from "hooks/useRouter"

const AdvancedSettings = () => {
  const { navigate } = useRouter();
  const { associateAccounts, switchAssociateAccount } = useContext(UserContext)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [pages, setPages] = useState([{ name: "Advanced", href: "/advanced", current: true }])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: "Settings", href: `/settings`, current: false },
        { name: "Advanced", href: `/settings/advanced`, current: true }
      ])
    } else if (scrollPosition < 120) {
      setPages([{ name: "Settings", href: `/settings`, current: false }])
    }
  }, [scrollPosition])

  const handleClick = async (id) => {
    await switchAssociateAccount(id, () => {
      navigate('/');
    })
  }

  return (
    <MainLayout pages={pages}>
      <Tabs title="Settings" tabs={settingsTabs} current="advanced" />
      <div className="w-full max-w-500">
        <div className="flex justify-between my-6 mx-2">
          <h3 className="text-sm font-medium leading-6 text-gray-900">Switch Account</h3>
          <Menu as="div" className="relative inline-block text-left">
            <div className="">
              <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium border border-gray-400 bg-white shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                Select an account
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                {associateAccounts.length && associateAccounts.map(account => (
                  <div className="px-1 py-1" key={account.id}>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-indigo-600 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => handleClick(account.id)}
                        >
                          {account.name}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </MainLayout>
  )
}

export default AdvancedSettings
