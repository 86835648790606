import React, { useContext, useEffect, useState } from "react"
import { SchedulesProvider } from "contexts/SchedulesContext"
import { Menu } from "@headlessui/react"
import { CalendarIcon, EllipsisHorizontalIcon, MapPinIcon } from "@heroicons/react/20/solid"
import { formatStartEndDate, getTimeZone, timeZoneAbbreviation } from "utils/formatter"
import useSchedules from "hooks/useSchedules"
import SessionDialog from "components/schedule/SessionDialog"
import MainLayout from "components/MainLayout"
import UserContext from "contexts/UserContext"
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/outline"
import { classNames } from "utils/misc"

const Schedule = ({ eventId }) => {
  const schedulesHooks = useSchedules()
  const { sessions, fetchSessions, onDelete } = schedulesHooks

  const { setEventId, currentEventName } = useContext(UserContext)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [pages, setPages] = useState([])

  const [addDialog, setAddDialog] = useState(false)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(
    () => setPages([{ name: currentEventName, href: `/events/${eventId}/people`, current: false }]),
    [currentEventName]
  )

  useEffect(() => {
    setEventId(eventId)
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
        { name: "Event Schedule", href: `/events/${eventId}/schedule`, current: true }
      ])
    } else if (scrollPosition < 120) {
      setPages([{ name: currentEventName, href: `/events/${eventId}/people`, current: false }])
    }
  }, [scrollPosition])

  useEffect(() => {
    const getEventSessions = async () => {
      await fetchSessions()
    }
    getEventSessions()
  }, [fetchSessions])

  const openAddDialog = () => {
    setAddDialog(true)
  }

  const closeAddDialog = () => {
    setAddDialog(false)
  }

  return (
    <SchedulesProvider value={schedulesHooks}>
      <MainLayout pages={pages}>
        <div className="w-full max-w-700">
          <div className="flex flex-wrap items-center justify-between">
            <h1 id="primary-heading" className="font-bold text-3xl">
              Event Schedule
            </h1>
            <button
              type="button"
              onClick={openAddDialog}
              className="inline-flex items-center mb-2 mt-2 sm:m-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <PlusIcon className="w-3 h-3 text-white mr-1" /> Add
            </button>
          </div>

          <div className="mt-4">
            <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
              {sessions.map(session => (
                <li key={session.id} className="relative flex space-x-6 py-6 xl:static">
                  <div className="flex-auto">
                    <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{session.name}</h3>
                    <p className="flex items-start space-x-3">{session.description}</p>
                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                      <div className="flex items-start space-x-3">
                        <dt className="mt-0.5">
                          <span className="sr-only">Date</span>
                          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                          <time dateTime={session.startDatetimeUtc}>
                            {formatStartEndDate(session.startDatetimeUtc, session.endDatetimeUtc)}
                          </time>
                        </dd>
                      </div>
                      <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <span className="sr-only">Location</span>
                          <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                          <a href={session.locationUrl} target="_blank">
                            {session.locationName}
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a 
                              href="#" 
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'flex px-4 py-2 text-sm'
                              )}
                            >
                              Edit
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'flex px-4 py-2 text-sm'
                              )}
                              onClick={() => onDelete(session.id)}
                            >
                              Remove
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Menu>
                </li>
              ))}
            </ol>
          </div>
          {sessions?.length ? (
            <p className="px-3 py-4 border-t-[1px] bt-gray-600 sticky bottom-[0px] bg-[#fafafa] z-5" colSpan={3}>
              All times in {getTimeZone()} ({timeZoneAbbreviation()})
            </p>
          ) : null}
        </div>
        {addDialog && <SessionDialog open={addDialog} closeDialog={closeAddDialog} />}
      </MainLayout>
    </SchedulesProvider>
  )
}

export default Schedule
