import React, { useContext, useEffect } from "react";
import MainLayout from "components/MainLayout";
import SmsBroadcastForm from "./broadcasts/SmsBroadcastForm";
import UserContext from "contexts/UserContext";

const NewSmsBroadcast = ({ eventId }) => {
  const { setEventId, currentEventName } = useContext(UserContext);
  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const pages = [
    { name: currentEventName, href: `/events/${eventId}/broadcasts`, current: false },
    { name: 'New Broadcast', href: `/events/${eventId}/broadcasts/new`, current: true }
  ];
  
  return (
    <MainLayout pages={pages}>
      <div className='w-full max-w-700'>
        <h1 id="primary-heading" className="font-bold text-3xl mb-10">New Broadcast</h1>
        <SmsBroadcastForm />
      </div>
    </MainLayout>
  )
};

export default NewSmsBroadcast;
