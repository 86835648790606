import React, { useContext, useEffect, useState } from "react";
import useCustomer from "hooks/useCustomer";
import MainLayout from "components/MainLayout";
import PersonForm from "components/person/PersonForm";
import UserContext from "contexts/UserContext";

const EditPerson = ({ eventId, id }) => {
  const { customer, fetchCustomer } = useCustomer();

  const { setEventId, currentEventName } = useContext(UserContext);
  const [scrollPosition, setScrollPosition] = useState(0)
  const [pages, setPages] = useState([])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    const initCustomer = async () => {
      await fetchCustomer(id);
    };
    initCustomer();
  }, [id, fetchCustomer]);

  useEffect(
    () => setPages([
      { name: currentEventName, href: `/events/${eventId}/people`, current: false },
      { name: customer.fullName ?? '', href: `/events/${eventId}/person/${id}`, current: false },
    ]),
    [currentEventName, customer.fullName]
  )

  useEffect(() => {
    setEventId(eventId)
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
        { name: customer.fullName ?? '', href: `/events/${eventId}/person/${id}`, current: false },
        { name: 'Edit', href: `/events/${eventId}/person/edit/${id}`, current: true }
      ])
    } else if (scrollPosition < 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
        { name: customer.fullName ?? '', href: `/events/${eventId}/person/${id}`, current: false },
      ])
    }
  }, [scrollPosition])

  return (
    <MainLayout pages={pages}>
      <div className="w-full max-w-700">
        <PersonForm eventId={eventId} id={id} />
      </div>
    </MainLayout>
  )
};

export default EditPerson;
