import React, { useMemo, useContext } from 'react'
import useStyles from 'pages/customers/_styles/pagination'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import Typography from '@material-ui/core/Typography'

const Pagination = () => {
  const classes = useStyles()
  const { total, customers } = useContext(CustomersContext)

  return useMemo(() => (
    <Grid
      container
      justifyContent='flex-end'
      className={classes.grid}
    >
      <Typography className={classes.rangeLabel}>
        {total === 0 ? 0 : 1} - {customers.length} of {total}
      </Typography>
    </Grid>
  ), [classes.grid, classes.rangeLabel, customers.length, total])
}

export default Pagination
