import React, { Fragment, useEffect, useContext, useState, useCallback, useMemo } from 'react'
import Fuse from 'fuse.js'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'
import useSpinner from 'hooks/useSpinner'
import PeopleContext from "contexts/PeopleContext"
import { classNames } from "utils/misc";

const ListDialog = () => {
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [addingNewList, setAddingNewList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedList, setSelectedList] = useState("");

  const {
    clearSelect,
    selectedPeople,
    addPersonToList,
    selectedDialog,
    closeSelectedDialog,
    fetchLists,
    createList,
    lists,
    activeList,
    setActiveList
  } = useContext(PeopleContext);

  useEffect(() => {
    fetchLists()
  }, []);

  useEffect(() => {
    if (!lists) return;
    
    const updatedList = lists.filter((list) => list.name !== 'All' && list.id !== activeList);

    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'name'
      ]
    };
    const fuse = new Fuse(updatedList, options);
    if (search) {
      const results = fuse.search(search);
      setFiltered(results.map(i => ({
        id: i.item.id,
        name: i.item.name
      })));
    } else setFiltered(updatedList);
  }, [search, lists]);

  const onSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onListOptionChange = useCallback((e) => {
    setSelectedList(e.target.value);
  }, [setSelectedList]);

  const { renderSpinner } = useSpinner(loading);
  const newListSaveDisabled = useMemo(() => newListName === "" || loading, [newListName, loading]);

  const onAddNewListClick = useCallback((e) => setAddingNewList(true), []);

  const onAddNewListCancelClick = useCallback((e) => {
    setAddingNewList(false);
    setNewListName("");
  }, []);

  const onNewListNameChange = useCallback((e) => setNewListName(e.target.value), []);

  const onSaveNewList = useCallback(async () => {
    setLoading(true);

    const newListId = await createList(newListName, false);
    setSelectedList(newListId);

    setNewListName("");
    setAddingNewList(false);
    setLoading(false);
  }, [newListName, createList]);

  const onAddToListClick = useCallback(() => {
    addPersonToList(selectedList, () => {
      setSelectedList(null);
      setActiveList(selectedList);
      window.history.pushState({}, '', window.location.href.split("?")[0] + `?alid=${selectedList}&desc=false&sort=firstname`);
      fetchLists();
    });
  }, [selectedList, addPersonToList, clearSelect, fetchLists]);

  return (
    <Transition.Root appear="true" show={selectedDialog} as={Fragment}>
      <Dialog as="div" className="relative z-[1500]" onClose={closeSelectedDialog}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll pt-6 pb-2">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div>
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Add { Object.keys(selectedPeople).length > 1?
                              (Object.keys(selectedPeople).length + " people")
                              : "one person" } to a list
                            </Dialog.Title>
                            <Dialog.Description className="mt-2">
                              Select a list below or create a new list.
                            </Dialog.Description>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={closeSelectedDialog}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-4 flex-1 px-4 sm:px-6">
                        {/* Search bar */}
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                          <div className="pointer-events-none absolute p-2 inset-y-0 left-0 flex items-center">
                            <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          </div>
                          <input
                            type="text"
                            name="list"
                            id="list-search"
                            value={search}
                            onChange={onSearchChange}
                            className="block w-full rounded-full border-0 pl-8 pr-12 pr-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Search lists"
                          />
                        </div>
                        {/* List options */}
                        <div className="mt-4">
                          <table className="mt-4 min-w-full table-fixed divide-y divide-gray-300">
                            <thead className="bg-[#fafafa] z-5">
                              <tr>
                                <th scope="col" className="relative w-[12px] px-5 sm:px-6">
                                  <input
                                    name="list-all"
                                    type="radio"
                                    disabled
                                    className="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 invisible"
                                  />
                                </th>
                                <th scope="col" className="py-3.5 pr-0 sm:pr-3 text-left text-sm font-semibold text-gray-900">
                                  List Name
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              { addingNewList &&
                                <tr>
                                  <td colSpan={2}>
                                    <div className="flex flex-wrap sm:flex-nowrap gap-y-2 items-center px-2 sm:pl-3 sm:pr-5 py-3">
                                      <input
                                        className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        type="text"
                                        name="new-list-name"
                                        placeholder="New list name"
                                        value={newListName}
                                        onChange={onNewListNameChange}
                                        autoFocus={true}
                                      />
                                      <button className="rounded bg-indigo-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 mx-2 sm:mx-4" disabled={newListSaveDisabled} onClick={onSaveNewList}>Save</button>
                                      <button className="cursor-pointer text-gray-600 disabled:opacity-50" disabled={loading} onClick={onAddNewListCancelClick}>Cancel</button>
                                    </div>
                                  </td>
                                </tr>
                              }
                              {filtered.length > 0 ? filtered.map(list => (
                                <tr key={list.id} className={selectedList === list.id ? "bg-gray-50" : undefined} onClick={() => setSelectedList(list.id)}>
                                  <td className="relative w-[12px] px-5 sm:px-6">
                                    {selectedList === list.id && (
                                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                    )}
                                    <input
                                      id={list.id}
                                      name="list-id"
                                      type="radio"
                                      value={list.id}
                                      checked={list.id === selectedList}
                                      onChange={onListOptionChange}
                                      className="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                  </td>
                                  <td
                                    className={classNames(
                                      "py-4 pr-0 sm:pr-3 text-sm",
                                      selectedList === list.id ? "text-indigo-600" : "text-gray-900"
                                    )}
                                  >
                                    {list.name}
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td className='text-center py-5' colSpan={2}>No lists.</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between px-8 py-4">
                      <button
                        type="button"
                        className={classNames(
                          "inline-flex items-center mb-2 mt-2 sm:m-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-indigo-400",
                          !selectedList ? "visible" : "invisible"
                        )}
                        onClick={onAddNewListClick}
                        disabled={addingNewList}
                      >
                        <PlusIcon className="w-3 h-3 text-white mr-1" /> New List
                      </button>
                      <button
                        type="submit"
                        className={classNames(
                          "justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500",
                          selectedList ? "visible" : "invisible"
                        )}
                        onClick={onAddToListClick}
                      >
                        Add to list
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default React.memo(ListDialog)
