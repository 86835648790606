import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  img: {
    // cursor: 'pointer',
    height: theme.spacing(3)
  },
  avatar: {
    // backgroundColor: theme.palette.primary.main + ' !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'normal',
    textAlign: 'start',
    cursor: 'pointer'
  },
  root: {
    alignItems: 'flex-start'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(8)
  },
  userMenu: {
    textAlign: 'right'
  }
}))

export default useStyles
