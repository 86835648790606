import React, { useCallback, forwardRef, useContext } from "react";
import { SnackbarContent, useSnackbar } from 'notistack';
import { navigate } from "hookrouter";
import { userInitials } from "utils/formatter";
import UserContext from "contexts/UserContext";

const MessageNotification = forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const {
    // notistack props
    id,
    message,
    // custom props
    firstName,
    lastName,
    customerId
  } = props;

  const { eventId } = useContext(UserContext);

  const onReply = useCallback(() => {
    closeSnackbar(id);
    navigate(`/events/${eventId}/person/${customerId}`, false, { anchor: 'send-message' });
  }, [closeSnackbar, id, customerId, eventId]);

  const fullName = `${firstName} ${lastName}`;

  return (
    <SnackbarContent ref={ref} role="alert">
      <div onClick={onReply} className="hover:cursor-pointer sm:min-w-[400px] notification-item pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <div className="uppercase font-bold flex items-center justify-center h-8 w-8 rounded-full border border-indigo-900 bg-indigo-900 text-white">
                {userInitials(firstName, lastName)}
              </div>
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{fullName}</p>
              <div className="notification-text mt-1 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            type="button"
            className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Reply
          </button>
        </div>
      </div>
    </SnackbarContent>
  )
});

export default MessageNotification;
