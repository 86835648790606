import React, { useEffect, useContext, useState } from "react";
import UserContext from "contexts/UserContext"
import MainLayout from "components/MainLayout";
import useRouter from "hooks/useRouter"
import { classNames } from "utils/misc";
import { formatPhone, getAbbreviation } from 'utils/formatter'
import ReportsContext from "contexts/ReportsContext";

const DeliveryIssuesReport = ({ eventId, tabId }) => {
  const { navigate } = useRouter()

  const { setEventId, currentEventName } = useContext(UserContext)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [pages, setPages] = useState([])

  const { loading, tabs, activeTab, customers, fetchCustomersReport } = useContext(ReportsContext);

  useEffect(() => {
    if (!eventId) return;

    const getCustomerReport = async () => {
      fetchCustomersReport(eventId, tabId);
    };
    getCustomerReport();
  }, [tabId]);

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(
    () => setPages([{ name: currentEventName, href: `/events/${eventId}/people`, current: false }]),
    [currentEventName]
  )

  useEffect(() => {
    setEventId(eventId)
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
        { name: "Reports", href: `/events/${eventId}/reports`, current: true }
      ])
    } else if (scrollPosition < 120) {
      setPages([{ name: currentEventName, href: `/events/${eventId}/people`, current: false }])
    }
  }, [scrollPosition])

  const onTabChange = (newTabId) => {
    if (tabId === newTabId) return;
  
    const routes = [
      'no_phone',
      'no_phone',
      'invalid',
      'landline',
      'not_yet_subscribed',
      'unsubscribed'
    ];
  
    const newRoute = routes[newTabId] || 'no_phone';
    navigate(`/events/${eventId}/reports/${newRoute}`);
  };  

  const navigateToProfile = (id) => {
    navigate(`/events/${eventId}/person/edit/${id}`);
  };

  return (
    <MainLayout pages={pages}>
      <div className="w-full max-w-700">
        <div className="flex flex-wrap items-center justify-between mb-3">
          <h1 id="primary-heading" className="font-bold text-3xl">
            Reports
          </h1>
        </div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={tabId}
            onChange={(e) => onTabChange(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.id} value={tab.id}>
                {`${tab.title} ${tab.count ? `(${tab.count})` : ''}`}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.id}
                  href="#"
                  className={classNames(
                    tabId == tab.id
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                  )}
                  aria-current={tabId == tab.id ? 'page' : undefined}
                  onClick={() => onTabChange(tab.id)}
                >
                  {tab.title}
                  {tab.count ? (
                    <span
                      className={classNames(
                        tabId == tab.id ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                        'ml-2 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </a>
              ))}
            </nav>
          </div>
        </div>
        {!loading && (
          <div className="mt-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">{activeTab.title}</h1>
                <p className="mt-2 text-sm text-gray-700" dangerouslySetInnerHTML={{__html: activeTab.description}}>
                  {/* {{ activeTab.description }} */}
                </p>
              </div>
            </div>
            <div className="mt-6 flow-root shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <div className="">
                <div className="inline-block min-w-full align-middle">
                  <table className="min-w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-16 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="sticky top-16 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className="sticky top-16 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customers.length > 0 ? customers.map((person, personIdx) => (
                        <tr key={person.id}>
                          <td
                            className={classNames(
                              personIdx !== customers.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                            )}
                          >
                            {getAbbreviation(`${person.firstname} ${person.lastname}`, 20)}
                          </td>
                          <td
                            className={classNames(
                              personIdx !== customers.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                            )}
                          >
                            {person.phone}
                            {/* {formatPhone(person.phone)} */}
                          </td>
                          <td
                            className={classNames(
                              personIdx !== customers.length - 1 ? 'border-b border-gray-200' : '',
                              'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                            )}
                          >
                            <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={() => navigateToProfile(person.id)}>
                              Edit<span className="sr-only">, {person.name}</span>
                            </a>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td className='text-center py-4' colSpan={3}>Nobody here.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default DeliveryIssuesReport;
