import React, {
  useMemo,
  useCallback,
  forwardRef,
  useContext,
  useState
} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomersContext from 'contexts/CustomersContext'
import InputBase from '@material-ui/core/InputBase'
import useStyles from 'pages/customers/_styles/createSegmentDialog'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CreateSegmentDialog = () => {
  const {
    newSegmentDialog,
    createSegment,
    closeNewSegmentDialog
  } = useContext(CustomersContext)
  const classes = useStyles()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)

  const onChangeName = useCallback(e => setName(e.target.value), [])
  const onChangeDescription = useCallback(e => setDescription(e.target.value), [])

  const onSave = useCallback(async () => {
    setLoading(true)
    await createSegment(name, description)
    setName('')
    setDescription('')
    setLoading(false)
    closeNewSegmentDialog()
  }, [createSegment, name, description, closeNewSegmentDialog])

  return useMemo(() => (
    <Dialog
      open={newSegmentDialog}
      TransitionComponent={Transition}
      fullWidth
      onClose={closeNewSegmentDialog}
      classes={{
        paper: classes.paper
      }}
      maxWidth='xs'
    >
      <DialogTitle>Save Segment</DialogTitle>
      <div className={classes.inputContainer}>
        <InputBase
          autoFocus
          placeholder='Name'
          onChange={onChangeName}
          value={name}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
        />
      </div>
      <div className={classes.inputContainer}>
        <InputBase
          placeholder='Description'
          multiline
          rows={3}
          onChange={onChangeDescription}
          value={description}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
        />
      </div>
      <DialogActions className={classes.actions}>
        <Button onClick={closeNewSegmentDialog}>
            Cancel
        </Button>
        <Button onClick={onSave} variant='contained' color='primary'>
          {loading ? (
            <Grid container justifyContent='center'>
              <CircularProgress size={30} />
            </Grid>
          ) : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  ), [classes.actions, classes.inputContainer, classes.inputInput, classes.inputRoot, classes.paper, closeNewSegmentDialog, description, loading, name, newSegmentDialog, onChangeDescription, onChangeName, onSave])
}

export default CreateSegmentDialog
