import React, { Fragment } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { classNames } from 'utils/misc'
import { formatDateWithTimezone } from "utils/formatter";
import useRouter from "hooks/useRouter";

const ScheduledMessagesDialog = ({ open, closeScheduleMessagesDialog, broadcasts, firstname, eventId }) => {
  const { navigate } = useRouter();

  const formatMessageBody = (bodyText) => {
    if (!bodyText) return '';

    // const urlRegex = /(?:https:\/\/|www\.)\S+(?=\s|$)/g;
    const urlRegex = /(?:https:\/\/|http:\/\/|www\.)[^\s<]+/g;
    
    return bodyText.replace(urlRegex, (match) => {
      return `<a href="${match}" target="_blank" class="text-blue-500 underline">${match}</a>`;
    });
  }

  const navigateToBroadcast = (id) => {
    navigate(`/events/${eventId}/broadcasts/${id}`);
  }

  return (
    <Transition.Root appear="true" show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1500]" onClose={closeScheduleMessagesDialog}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Scheduled Messages (Broadcasts)
                          </Dialog.Title>
                          <Dialog.Description className="mt-2">
                            The following scheduled messages include {firstname} as a recipient.
                          </Dialog.Description>
                        </div>
                        
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={closeScheduleMessagesDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 sm:px-6 pt-6'>
                      <ul role="list" className="space-y-6">
                        {broadcasts.length && broadcasts.map((broadcast, index) => (
                          <li key={broadcast.id} className="relative flex gap-x-2">
                            <div
                              className={classNames(
                                index === broadcast.length - 1 ? 'h-6' : '-bottom-6',
                                'absolute left-0 top-0 flex w-6 justify-center',
                              )}
                            >
                              <div className="w-px bg-gray-200" />
                            </div>
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            </div>
                            <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                              <div className="flex justify-between gap-x-3">
                                <div className="py-0.5 leading-5 text-gray-500">
                                  <span className="font-medium text-gray-900 cursor-pointer" onClick={() => navigateToBroadcast(broadcast.id)}>{broadcast.name}</span>
                                </div>
                                <p className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                  {formatDateWithTimezone(broadcast.sendDatetimeUtc)}
                                </p>
                              </div>
                              {broadcast.template?.body && <p className="text-sm leading-6 text-gray-500 mt-1" dangerouslySetInnerHTML={{__html: formatMessageBody(broadcast.template.body) }} />}
                              <p className="mt-2 text-right">{broadcast.list?.name}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ScheduledMessagesDialog;