import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 0),
    border: `1px solid ${theme.palette.lightGray.main}`
  },
  summaryTitle: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  rowTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  subject: {
    color: theme.palette.midGray.main
  },
  timezone: {
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
    fontSize: 14
  },
  item: {
    padding: theme.spacing(2, 2, 0, 2),
    borderBottom: `1px solid ${theme.palette.lightGray.main}`
  },
  hidden: {
    display: 'none'
  }
}))
