import React, { useMemo, useCallback, useEffect, useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import SelectInput from 'pages/customers/filters/inputs/SelectInput'
import TextInput from 'pages/customers/filters/inputs/TextInput'
import useStyles from 'pages/customers/filters/_styles/customerProperties'
import { underlineToCapital } from 'utils/formatter'

const CustomerProperties = () => {
  const classes = useStyles()
  const {
    state: {
      customerProperties: {
        options,
        chips,
        changed: ignore,
        ...selected
      }
    },
    dispatch,
    getCustomerPropertiesOptions
  } = useContext(CustomersContext)

  const [optionsList, setOptionsList] = useState(null)
  const [curCustomerProperties, setCurCustomerProperties] = useState('')
  const [firstLoad, setFirstLoad] = useState(false)

  const hasOptions = useCallback(v => (v && Object.keys(v).length > 0), [])

  const createChips = useCallback(() => {
    const query = {}
    Object.keys(selected).forEach(s => {
      if (selected[s]) query[s] = `${underlineToCapital(s)}: ${selected[s]}`
    })
    dispatch({ type: 'CUSTOMER_PROPERTIES_UPDATE', params: { key: 'chips', value: query } })
  }, [dispatch, selected])

  const onChangeSelect = useCallback((key, values) => {
    values.forEach(v => {
      if (!selected[v]) dispatch({ type: 'CUSTOMER_PROPERTIES_UPDATE', params: { key: v, value: '' } })
    })

    Object.keys(selected).forEach(cp => {
      if (!values.includes(cp)) dispatch({ type: 'DELETE', key: `customerProperties:${cp}` })
    })
  }, [dispatch, selected])

  const onLoadOptions = useCallback(async () => {
    const res = await getCustomerPropertiesOptions()

    const options = {}
    res.forEach(r => {
      options[r.name] = {
        id: r.id,
        name: r.name,
        prefix: `properties.${r.parent ? `${r.parent.name}.` : ''}`
      }
    })

    if (res) {
      setOptionsList(res)
      dispatch({ type: 'CUSTOMER_PROPERTIES_UPDATE', params: { key: 'options', value: options } })
    }
  }, [dispatch, getCustomerPropertiesOptions])

  const selectedKeys = useMemo(() => Object.keys(selected), [selected])

  const onChangeText = useCallback((key, value) => {
    dispatch({ type: 'CUSTOMER_PROPERTIES_UPDATE', params: { key, value } })
  }, [dispatch])

  const renderTextInput = useCallback(id => {
    if (options) {
      const { name } = options[id]
      const value = selected[id]
      const label = underlineToCapital(name)
      return <TextInput key={id} label={label} textValue={value} keyValue={id} onChange={onChangeText} />
    }
  }, [onChangeText, options, selected])

  useEffect(() => {
    const updateOptions = async () => {
      if (hasOptions(selected) && !firstLoad) {
        setFirstLoad(true)
        await onLoadOptions()
      }
      const selectedString = JSON.stringify(selected)
      if (JSON.stringify(selected) !== curCustomerProperties && options) {
        setCurCustomerProperties(selectedString)
        createChips()
      }
    }

    updateOptions()
  }, [selected, options, hasOptions, firstLoad, curCustomerProperties, onLoadOptions, createChips])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <SelectInput
        label='Choose Properties'
        textValue={selectedKeys}
        multiple
        keyValue='selected'
        onChange={onChangeSelect}
        onLoadOptions={onLoadOptions}
        options={optionsList}
      />
      <Grid
        container
        spacing={1}
        className={classes.inputs}
        direction='row'
      >
        {selectedKeys.map(s => renderTextInput(s))}
      </Grid>
    </Grid>
  ), [classes.inputs, onChangeSelect, onLoadOptions, optionsList, renderTextInput, selectedKeys])
}

export default CustomerProperties
