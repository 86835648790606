import React, { useMemo, useCallback, useEffect, useContext } from 'react'
import { currency } from 'utils/formatter'
import CustomersContext from 'contexts/CustomersContext'
import RangeNumber from 'pages/customers/filters/inputs/RangeNumber'

const LifetimeSpend = () => {
  const {
    state: {
      lifetimeSpend: {
        to,
        from
      }
    },
    dispatch
  } = useContext(CustomersContext)

  const onChange = useCallback((newFrom, newTo) => {
    if (newFrom !== from) dispatch({ type: 'LIFETIME_SPEND_UPDATE', params: { key: 'from', value: newFrom } })
    if (newTo !== to) dispatch({ type: 'LIFETIME_SPEND_UPDATE', params: { key: 'to', value: newTo } })
  }, [dispatch, from, to])

  const updateChip = useCallback(() => {
    let chip = ''
    if (from !== '' || to !== '') {
      const fromLabel = `From ${currency(from)}`
      const toLabel = to ? `To ${currency(to)}` : ''
      chip = `Lifetime Spend: ${fromLabel} ${toLabel} `

      dispatch({
        type: 'LIFETIME_SPEND_UPDATE',
        params: { key: 'chip', value: chip }
      })
    }
  }, [dispatch, from, to])

  useEffect(() => {
    updateChip()
  }, [from, onChange, to, updateChip])

  return useMemo(() => (
    <RangeNumber
      filterKey='lifetimeSpend'
      onChange={onChange}
      from={from}
      to={to}
      isCurrency
      fromLabel='From'
      toLabel='To'
    />
  ), [from, onChange, to])
}

export default LifetimeSpend
