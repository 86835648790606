import React, { useMemo, useContext } from "react";
import CustomersContext from "contexts/CustomersContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "pages/customers/_styles/bottomtray";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ConfirmDismissDialog from "pages/customers/ConfirmDismissDialog";
import UserContext from "contexts/UserContext";
import { margin } from "tailwindcss/defaultTheme";

export default function BottomAppBar() {
  const classes = useStyles();
  const {
    selectedCustomers,
    exportCustomers,
    loadingExport,
    state: {
      activeList: { id: listId },
    },
    openConfirmDismissDialog,
    openBroadcastDialog,
    openSelectedDialog,
    openReassignDialog,
  } = useContext(CustomersContext);
  const { broadcastFeatureFlag } = useContext(UserContext);

  const selectedLabel = useMemo(
    () => `${Object.keys(selectedCustomers).length} selected`,
    [selectedCustomers]
  );

  const isDisabled = Object.keys(selectedCustomers).length === 0;
  return (
    <AppBar position="fixed" color="inherit" className={classes.appBar} style={{ left: '0' }}>
      <ConfirmDismissDialog />
      <Toolbar>
        <Button
          onClick={openSelectedDialog}
          variant="contained"
          color="primary"
          disabled={isDisabled}
        >
          Add to List
        </Button>
        {listId && (
          <Button
            classes={{ root: classes.grayButton }}
            onClick={openConfirmDismissDialog}
            variant="contained"
            disabled={isDisabled}
          >
            Dismiss From List
          </Button>
        )}
        {broadcastFeatureFlag && (
          <Button
            className={classes.messageButton}
            onClick={openBroadcastDialog}
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Message
          </Button>
        )}
        {/* <Button
          classes={{ root: classes.grayButton }}
          onClick={exportCustomers}
          variant="contained"
          disabled={isDisabled}
        >
          {loadingExport ? (
            <CircularProgress size={28} color="inherit" />
          ) : (
            "Export"
          )}
        </Button> */}
        <Button
          classes={{ root: classes.reassignButton }}
          onClick={openReassignDialog}
          variant="contained"
          disabled={isDisabled}
        >
          Reassign
        </Button>
        <Typography className={classes.selectedLabel}>
          {selectedLabel}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
