import React, { useContext, useEffect } from 'react'
import SmsBroadcastForm from './broadcasts/SmsBroadcastForm'
import MainLayout from 'components/MainLayout'
import UserContext from "contexts/UserContext";

const EditSmsBroadcast = ({ eventId, id }) => {
  const { setEventId, currentEventName } = useContext(UserContext);
  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const pages = [
    { name: currentEventName, href: `/events/${eventId}/broadcasts`, current: false },
    { name: 'Edit Broadcast', href: `/events/${eventId}/broadcasts/edit/${id}`, current: true }
  ];

  return (
    <MainLayout pages={pages}>
      <div className='w-full max-w-700'>
        <h1 id="primary-heading" className="font-bold text-3xl mb-10">Edit Broadcast</h1>
        <SmsBroadcastForm id={id} />
      </div>
    </MainLayout>
  )
}

export default EditSmsBroadcast
