export const blueprint = {
  "name": "Sheet1",
  "fields": [
    {
      "key": "firstName",
      "label": "First Name",
      "type": "string"
    },
    {
      "key": "lastName",
      "label": "Last Name",
      "type": "string"
    },
    {
      "key": "phone",
      "label": "Phone",
      "type": "number",
      "constraints" : [
        {
          "type": "required"
        },
        {
          "type": "unique"
        }
      ]
    },
    {
      "key": "listName",
      "label": "List Name",
      "type": "string"
    }
  ]
}