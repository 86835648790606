import React, { useCallback, useContext, useEffect, useState } from 'react'
import MainLayout from 'components/MainLayout'
import Dropzone from "components/Dropzone";
import useApi from "hooks/useApi";
import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { IMPORT_CUSTOMERS_FROM_CSV } from "utils/mutations";
import { enqueueSnackbar } from "notistack";
import useRollbar from "hooks/useRollbar";
import UserContext from "contexts/UserContext";

const PeopleImport = ({ eventId }) => {
  const { ecommerceExperienceFeatureFlag, setEventId, currentEventName } = useContext(UserContext);

  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const pages = [
    ecommerceExperienceFeatureFlag ? { name: currentEventName, href: '/customers', current: false } : { name: currentEventName, href: `/events/${eventId}/people`, current: false },
    { name: 'Import Subscribers from CSV', href: `/events/${eventId}/people/import`, current: true }
  ];

  const { apolloClient } = useApi();
  const { captureError } = useRollbar();
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onUpload = useCallback(async () => {
    if (!csvFile) return;

    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: IMPORT_CUSTOMERS_FROM_CSV,
        variables: {
          eventId: parseInt(eventId),
          file: csvFile
        }
      });
      enqueueSnackbar("CSV file uploaded successfully! We'll email you when your import is complete.", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setCsvFile(null);
      setLoading(false);
    } catch (e) {
      enqueueSnackbar("Failed to upload csv file:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  }, [eventId, csvFile, apolloClient, captureError]);

  return (
    <MainLayout pages={pages}>
      <h1 id="primary-heading" className="font-bold text-3xl mb-10">Import Subscribers from CSV</h1>

      <p className="mb-6">Use <a className="font-medium text-indigo-600 dark:text-indigo-500 hover:underline" rel="noreferrer" target="_blank" href="https://docs.google.com/spreadsheets/d/1G3mdef4mncFU1OOBwVEMzUI1s640rzwRFA4z87RwzW0/edit?usp=sharing">this template</a> to add up to 1000 people. All columns are optional.</p>

      <Dropzone
        handleFile={setCsvFile}
        attachments={csvFile ? [csvFile] : []}
        handleDelete={setCsvFile}
        acceptedFormats = "text/csv"
        maxFiles={1}
        maxSize={500 * 1000}
      />

      <p className="mt-10">
        Ensure you have permission to contact these people in accordance with <a className="font-medium text-indigo-600 dark:text-indigo-500 hover:underline" rel="noreferrer" target="_blank" href="https://www.conciergeteam.co/terms-conditions">Concierge Terms and Conditions</a>.
      </p>

      <div>
        <button
          type="button"
          onClick={onUpload}
          disabled={!csvFile || loading}
          className="inline-flex items-center mb-2 mt-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-25"
        >
          <ArrowUpTrayIcon className="w-3 h-3 text-white mr-1" /> Upload
        </button>
      </div>
    </MainLayout>
  )
}

export default PeopleImport;
