import React, { useMemo, useCallback, useContext, useState, useRef } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import useSpinner from "hooks/useSpinner";
import PeopleContext from "contexts/PeopleContext";

const Searchbar = () => {
  const searchField = useRef();
  const {
    nameSearch,
    setNameSearch,
    loading
  } = useContext(PeopleContext);
  const [closeIconVisible, setCloseIconVisible] = useState(nameSearch && nameSearch !== "" ? true : false);

  const { renderSpinner } = useSpinner(loading);

  const onChange = useCallback(e => {
    const search = e.target.value;
    setCloseIconVisible(search && search !== "" ? true : false);
    setNameSearch(search);
  }, [setNameSearch]);

  const clearSearch = useCallback(() => {
    setNameSearch("");
    setCloseIconVisible(false);
    searchField.current.value = "";
  }, [setNameSearch]);

  return useMemo(() => (
    <div className="flex md:ml-0">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
        <div className="pointer-events-none absolute p-2 inset-y-0 left-0 flex items-center">
          <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
        </div>
        <input
          ref={searchField}
          type="text"
          name="name"
          id="name"
          value={nameSearch || ""}
          onChange={onChange}
          className="block w-full rounded-full border-0 pl-8 pr-12 pr-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search by name"
        />
        { closeIconVisible &&
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <XMarkIcon onClick={clearSearch} className="cursor-pointer h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        }
        <div className="absolute top-[8px] right-[10px]">
          { renderSpinner }
        </div>
      </div>
    </div>
  ), [nameSearch, onChange, clearSearch, closeIconVisible, renderSpinner]);
};

export default Searchbar;
