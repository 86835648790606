import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const ImagePreviewModal = ({ name, url, open, onClose }) => {
  return (
    <Transition.Root appear="true" show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="relative flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              appear="true"
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg pb-0 px-0 sm:px-16 sm:pb-6 pt-8 sm:pt-12 text-left transition-all sm:my-8 sm:max-w-4xl">
                <div className="absolute sm:block z-10 right-0 sm:right-10 top-0 pr-0 sm:pr-4 sm:pt-4">
                  <button
                    type="button"
                    className="rounded-md text-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-center justify-center relative">
                  <img className="max-w-[100%] max-h-[calc(100vh-55px)] sm:max-h-[800px]" src={url} alt={name} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ImagePreviewModal;
