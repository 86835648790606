import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF'
  },
  pageTitle: theme.typography.pageTitle,
  content: {
    flexGrow: 1,
    height: '100vh'
  },
  backdrop: {
    height: '100vh',
    backgroundColor: '#fff'
  },
  toolbar: theme.mixins.toolbar
}))
