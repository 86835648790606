import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  fullscreen: {
    background: 'white',
    zIndex: 1,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0
  },
  container: {
    height: '100%'
  },
  textField: {
    padding: theme.spacing(1, 2)
  },
  iframe: {
    height: 'calc(100% - 136px)',
    '& div': {
      height: '100%',
      minHeight: '0px !important'
    }
  },
  hidden: {
    display: 'none'
  },
  privateLabel: {
    '& .MuiTypography-body1': {
      lineHeight: 1
    }
  }
}))
