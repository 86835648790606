import { useCallback, useContext, useState } from "react";
import { GET_CUSTOMER, GET_PHONE_VERIFICATION, FIND_CUSTOMER_BY_PHONE } from "utils/queries";
import { useSnackbar } from "notistack";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import useRouter from "hooks/useRouter";
import { CREATE_CUSTOMER, OPT_OUT_CUSTOMER, UPDATE_CUSTOMER, ARCHIVE_CUSTOMER } from "utils/mutations";
import UserContext from "contexts/UserContext";

const useCustomer = () => {
  const { apolloClient, query } = useApi();
  const { navigate } = useRouter();
  const { captureError } = useRollbar();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [smsOptIn, setSmsOptIn] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState({});
  const { eventId } = useContext(UserContext);

  const fetchCustomer = useCallback(async (id) => {
    setLoading(true);

    try {
      if (!id) {
        return;
      }

      if (!eventId) {
        return;
      }

      const {
        data: { customer },
      } = await query(
        GET_CUSTOMER({ eventId: eventId, id: id })
      );

      setCustomer(customer);
      setFirstName(customer.firstname);
      setLastName(customer.lastname);
      setPhone(customer.phone);
      setSmsOptIn(customer.smsOptedIn);
    } catch (e) {
      captureError(e);
      console.log("Failed to load customer", e);
    }

    setLoading(false);
  }, [eventId, captureError, query]);

  const onCreate = async () => {
    if (!firstName) {
      enqueueSnackbar("First name is required!" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    if (smsOptIn && !phone) {
      enqueueSnackbar("Please add a phone number first" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    try {
      setLoading(true);
      const { 
        data : { createCustomer },
      } = await apolloClient.mutate({
        mutation: CREATE_CUSTOMER,
        variables: {
          eventId: parseInt(eventId),
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          smsOptIn: smsOptIn
        }
      });
      enqueueSnackbar("Person has been saved", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/person/${createCustomer.id}`);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onEdit = async (id) => {
    if (!firstName) {
      enqueueSnackbar("First name is required!" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    if (smsOptIn && !phone) {
      enqueueSnackbar("Please add a phone number first" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    try {
      // setLoading(true);
      await apolloClient.mutate({
        mutation: UPDATE_CUSTOMER,
        variables: {
          eventId: parseInt(eventId),
          id: parseInt(id),
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          smsOptIn: smsOptIn
        }
      });
      enqueueSnackbar("Person has been updated", {
        autoHideDuration: 3000,
        variant: "success",
      });
      // setLoading(false);
      navigate(`/events/${eventId}/person/${id}`);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onOptOut = async (id) => {
    try {
      setLoading(true);

      const {
        data: { customerOptOut: customer },
      } = await apolloClient.mutate({
        mutation: OPT_OUT_CUSTOMER,
        variables: {
          eventId: parseInt(eventId),
          id: parseInt(id)
        }
      });
      enqueueSnackbar("Person has been unsubscribed", {
        autoHideDuration: 3000,
        variant: "success",
      });

      // Add the 'active' property to the customer object
      customer.active = true;
      
      setCustomer(customer);
      setFirstName(customer.firstname);
      setLastName(customer.lastname);
      setPhone(customer.phone);
      setSmsOptIn(customer.smsOptedIn);

      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onArchive = async (id) => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: ARCHIVE_CUSTOMER,
        variables: {
          eventId: parseInt(eventId),
          id: parseInt(id)
        }
      });

      enqueueSnackbar("Person has been archived", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/people`);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const fetchPhoneVerification = useCallback(async (eventId, id) => {
    try {
      if (!eventId || !id) return;

      const res = await query(GET_PHONE_VERIFICATION({ eventId: eventId, id: id }));

      setPhoneVerification(res?.data?.phoneVerification ?? {});
    } catch (e) {
      captureError(e);
      console.log("Failed to get phone verification info", e);
    }
  }, [eventId, query]);

  const findCustomerByPhone = async (eventId, phoneNumber, id = 0) => {
    try {
      if (!eventId || !phoneNumber) return;

      const res = await apolloClient.query({
        query: FIND_CUSTOMER_BY_PHONE,
        variables: {
          eventId: parseInt(eventId),
          excludeCustomerId: id,
          phoneNumber: phoneNumber
        }
      });

      return res?.data?.findCustomerByPhone;
    } catch (e) {
      captureError(e);
      console.log("Failed to find customer by phone", e);
    }
  };

  return {
    loading,
    setLoading,
    customer,
    setCustomer,
    fetchCustomer,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    smsOptIn,
    setSmsOptIn,
    onCreate,
    onEdit,
    onOptOut,
    onArchive,
    phoneVerification, 
    setPhoneVerification,
    fetchPhoneVerification,
    findCustomerByPhone
  };
};

export default useCustomer;
