import { useContext, useCallback, useState } from "react";
import useApi from "hooks/useApi";
import {
  LIST_SEARCH,
  ASSOCIATE_OPTIONS,
  SELECT_ALL_CUSTOMERS,
} from "utils/queries";
import {
  EXPORT_CUSTOMERS,
  CREATE_LIST,
  DISMISS_CUSTOMERS_FROM_LIST,
  REASSIGN_CUSTOMERS,
  ADD_CUSTOMER_TO_LIST,
  REMOVE_CUSTOMER_FROM_LIST,
} from "utils/mutations";
import { useSnackbar } from "notistack";
import { sortQuery, getSearchConditions } from "utils/formatter";
import useRollbar from "hooks/useRollbar";
import UserContext from "contexts/UserContext";

const useCustomersSelect = (state) => {
  const { eventId } = useContext(UserContext);

  const {
    activeList: { id: listId },
  } = state;
  const { query, mutate } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingExport, setLoadingExport] = useState(false);
  const { captureError } = useRollbar();

  const [contactLists, setContactLists] = useState(null);
  const [selectedDialog, setSelectedDialog] = useState(false);
  const openSelectedDialog = useCallback(() => setSelectedDialog(true), []);
  const closeSelectedDialog = useCallback(() => setSelectedDialog(false), []);

  const [selectedReassignDialog, setReassignSelectedDialog] = useState(false);
  const openReassignDialog = useCallback(() => {
    setReassignSelectedDialog(true);
    setNotifyAssociate(false);
  }, []);
  const closeReassignDialog = useCallback(
    () => setReassignSelectedDialog(false),
    []
  );
  const [selectedAssociate, setSelectedAssociate] = useState(null);
  const [selectedAssociateName, setSelectedAssociateName] = useState(null);
  const [selectedAssociateList, setSelectedAssociateList] = useState(null);
  const [notifyAssociate, setNotifyAssociate] = useState(null);

  const [newListDialog, setNewListDialog] = useState(false);
  const openNewListDialog = useCallback(() => setNewListDialog(true), []);
  const closeNewListDialog = useCallback(() => setNewListDialog(false), []);
  const [selectedList, setSelectedList] = useState(null);

  const [selectedCustomers, setSelectedCustomers] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const clearSelect = useCallback(() => {
    setSelectAll(false);
    setSelectedList(null);
    setSelectedCustomers({});
  }, []);

  const [confirmDismissDialog, setConfirmDismissDialog] = useState(false);
  const openConfirmDismissDialog = useCallback(
    () => setConfirmDismissDialog(true),
    []
  );
  const closeConfirmDismissDialog = useCallback(
    () => setConfirmDismissDialog(false),
    []
  );

  const dismissFromList = useCallback(async () => {
    try {
      const customerIds = Object.keys(selectedCustomers);
      const params = `eventId: ${parseInt(eventId)}, listId: ${listId}, customerIds: [${customerIds}]`;
      const res = await mutate(DISMISS_CUSTOMERS_FROM_LIST(params));
      enqueueSnackbar("Customers dismissed from list", {
        autoHideDuration: 3000,
        variant: "success",
      });
      return res;
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to dismiss from list", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  }, [captureError, enqueueSnackbar, eventId, listId, mutate, selectedCustomers]);

  const fetchContactLists = useCallback(
    async (searchTerm = "") => {
      if (!eventId) return;

      if (typeof searchTerm !== "string") searchTerm = "";
      try {
        const res = await query(LIST_SEARCH(searchTerm, eventId));
        setContactLists(res.data.listSearch.lists);
      } catch (e) {
        captureError(e);
        console.log("Failed to load lists", e);
      }
    },
    [eventId, query, captureError]
  );

  const fetchEventAssociates = useCallback(async () => {
    try {
      const res = await query(ASSOCIATE_OPTIONS(eventId));
      setSelectedAssociateList(res.data.event.associates);
    } catch (e) {
      captureError(e);
      console.log("Failed to load associate list", e);
    }
  }, [query, eventId, captureError]);

  const createList = useCallback(
    async (name, shouldCloseNewListDialog = true) => {
      try {
        const res = await mutate(CREATE_LIST(eventId, name));
        if (JSON.stringify(res).includes("Name has already been taken")) {
          enqueueSnackbar("List name has already been taken", {
            autoHideDuration: 3000,
            variant: "error",
          });
        } else {
          await fetchContactLists();
          setSelectedList(res.data.createList.id);
          if (shouldCloseNewListDialog) closeNewListDialog();
        }
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed to create list", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
    },
    [
      eventId,
      mutate,
      enqueueSnackbar,
      fetchContactLists,
      closeNewListDialog,
      captureError,
    ]
  );

  const selectSingleCustomer = (id) => {
    if (selectAll) setSelectAll(false);
    if (selectedCustomers[id]) {
      const { [id]: removed, ...ids } = selectedCustomers;
      setSelectedCustomers(ids);
    } else {
      const ids = { ...selectedCustomers, [id]: true };
      setSelectedCustomers(ids);
    }
  };

  const selectAllCustomers = useCallback(
    async (setLoading, debounceState, total) => {
      try {
        setLoading(true);

        if (selectAll) {
          setSelectAll(false);
          setSelectedCustomers({});
        } else {
          setSelectAll(true);
          const search = getSearchConditions(debounceState);
          const sort = sortQuery(
            debounceState.sort.id,
            debounceState.sort.desc
          );
          const res = await query(SELECT_ALL_CUSTOMERS(eventId, search, total, sort));
          const ids = {};
          res.data.advancedCustomerSearch.customers.forEach((c) => {
            ids[c.id] = true;
          });
          setSelectedCustomers(ids);
        }
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed select all customers", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
      setLoading(false);
    },
    [captureError, enqueueSnackbar, query, selectAll, eventId]
  );

  const exportCustomers = useCallback(async () => {
    const params = `eventId: ${eventId}, customerIds: [${Object.keys(selectedCustomers)}]`;
    setLoadingExport(true);
    try {
      const res = await mutate(EXPORT_CUSTOMERS(params));
      window.open(res.data.exportCustomers.fileUrl, "_blank");
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to export", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
    setLoadingExport(false);
  }, [captureError, enqueueSnackbar, mutate, eventId, selectedCustomers]);

  const reassignCustomers = useCallback(async () => {
    if (!selectedAssociate) {
      enqueueSnackbar("Please select an Associate", {
        autoHideDuration: 3000,
        variant: "info",
      });
      return;
    }
    const params = `eventId: ${parseInt(eventId)}, customerIds: [${Object.keys(
      selectedCustomers
    )}], associateId: ${selectedAssociate}, notifyAssociate: ${notifyAssociate}`;
    setReassignSelectedDialog(true);
    try {
      await mutate(REASSIGN_CUSTOMERS(params));
      closeReassignDialog();
      enqueueSnackbar(`Customer(s) reassigned to ${selectedAssociateName}`, {
        autoHideDuration: 3000,
        variant: "success",
      });
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to reassign customers", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  }, [
    eventId,
    captureError,
    closeReassignDialog,
    enqueueSnackbar,
    mutate,
    notifyAssociate,
    selectedAssociate,
    selectedAssociateName,
    selectedCustomers,
  ]);

  const addCustomerToList = useCallback(async (afterAddCallback) => {
    const ids = Object.keys(selectedCustomers).map((s) => Number(s));
    const params = `eventId: ${Number(eventId)}, listId: ${Number(selectedList)}, customerIds: [${ids}]`;

    if (selectedList) {
      try {
        const res = await mutate(ADD_CUSTOMER_TO_LIST(params));
        if (res.data.addCustomersToList) {
          afterAddCallback && afterAddCallback();
          closeSelectedDialog();
          enqueueSnackbar("Customer added to list", {
            autoHideDuration: 3000,
            variant: "success",
          });
        }
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed to add to lists", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Please select a list", {
        autoHideDuration: 3000,
        variant: "info",
      });
    }
  }, [
    eventId,
    selectedCustomers,
    selectedList,
    mutate,
    closeSelectedDialog,
    enqueueSnackbar,
    captureError,
  ]);

  const removeCustomerFromList = useCallback(async (afterRemoveCallback) => {
    const ids = Object.keys(selectedCustomers).map((s) => Number(s));
    const params = `eventId: ${Number(eventId)}, listId: ${Number(listId)}, customerIds: [${ids}]`;

    if (listId) {
      try {
        const res = await mutate(REMOVE_CUSTOMER_FROM_LIST(params));
        if (res.data.removeCustomersFromList) {
          afterRemoveCallback && afterRemoveCallback();
          enqueueSnackbar("Customer(s) removed from list", {
            autoHideDuration: 3000,
            variant: "success",
          });
        }
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed to remove from list", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Please select a list", {
        autoHideDuration: 3000,
        variant: "info",
      });
    }
  }, [
    eventId,
    selectedCustomers,
    listId,
    mutate,
    enqueueSnackbar,
    captureError
  ]);

  return {
    selectAllCustomers,
    selectedCustomers,
    setSelectedCustomers,
    addCustomerToList,
    removeCustomerFromList,
    selectedDialog,
    openSelectedDialog,
    closeSelectedDialog,
    selectedList,
    setSelectedList,
    fetchContactLists,
    contactLists,
    selectSingleCustomer,
    loadingExport,
    selectAll,
    clearSelect,
    exportCustomers,
    selectedReassignDialog,
    openReassignDialog,
    closeReassignDialog,
    setReassignSelectedDialog,
    setNotifyAssociate,
    setSelectedAssociate,
    selectedAssociate,
    setSelectedAssociateName,
    selectedAssociateName,
    selectedAssociateList,
    fetchEventAssociates,
    reassignCustomers,
    newListDialog,
    openNewListDialog,
    createList,
    closeNewListDialog,
    confirmDismissDialog,
    openConfirmDismissDialog,
    dismissFromList,
    closeConfirmDismissDialog,
  };
};

export default useCustomersSelect;
