import { alpha, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  recentContainer: {
    margin: theme.spacing(0, 2, 1, 2)
  },
  emptySearch: {
    paddingTop: theme.spacing()
  },
  listContainer: {
    height: '100%'
  },
  list: {
    width: '100%',
    height: 260,
    overflow: 'auto'
  },
  title: {
    fontWeight: 'bold'
  },
  recent: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.midGray.main,
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  search: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    border: `1px solid ${theme.palette.lightGray.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    width: '100%'
  },
  searchIcon: {
    width: theme.spacing(5),
    color: theme.palette.lightGray.main,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  closeButton: {
    right: 0,
    position: 'absolute'
  },
  inputContainer: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3, 0, 3),
      width: 'auto'
    }
  },
  leftGrid: {
    paddingTop: theme.spacing(2),
    flexGrow: 1,
    minHeight: 200,
    border: `1px solid ${theme.palette.lightGray.main}`
  },
  rightGrid: {
    padding: theme.spacing(1, 4, 0, 4),
    overflow: 'auto',
    height: 360,
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.lightGray.main}`,
    borderBottom: `1px solid ${theme.palette.lightGray.main}`
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0
  },
  hidden: {
    display: 'none'
  }
}))
