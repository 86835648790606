import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { currency } from 'utils/formatter'
import CustomersContext from 'contexts/CustomersContext'
import TextInput from 'pages/customers/filters/inputs/TextInput'
import RangeNumber from 'pages/customers/filters/inputs/RangeNumber'

const PurchaseHistory = () => {
  const {
    state: {
      purchaseHistory
    },
    dispatch
  } = useContext(CustomersContext)

  const {
    category,
    brand,
    description,
    from,
    to
  } = purchaseHistory

  const [curPurchaseHistory, setCurPurchaseHistory] = useState('')

  const createChips = useCallback(() => {
    const chips = {}
    if (category) chips.category = `Category: ${category}`
    if (brand) chips.brand = `Brand: ${brand}`
    if (description) chips.description = `Description: ${description}`
    if (from !== '' || to !== '') {
      const fromLabel = `From ${currency(from)}`
      const toLabel = to ? `To ${currency(to)}` : ''
      chips.range = `Purchase History: ${fromLabel} ${toLabel} `
    }

    dispatch({ type: 'PURCHASE_HISTORY_UPDATE', params: { key: 'chips', value: chips } })
  }, [brand, category, description, dispatch, from, to])

  const onChange = useCallback((key, value) => {
    dispatch({ type: 'PURCHASE_HISTORY_UPDATE', params: { key, value } })
  }, [dispatch])

  const onChangeRange = useCallback((curFrom, curTo) => {
    if (curFrom !== from) dispatch({ type: 'PURCHASE_HISTORY_UPDATE', params: { key: 'from', value: curFrom } })
    if (curTo !== to) dispatch({ type: 'PURCHASE_HISTORY_UPDATE', params: { key: 'to', value: curTo } })
  }, [dispatch, from, to])

  useEffect(() => {
    const purchaseHistoryString = JSON.stringify(purchaseHistory)
    if (JSON.stringify(purchaseHistory) !== curPurchaseHistory) {
      setCurPurchaseHistory(purchaseHistoryString)
      createChips()
    }
  }, [createChips, curPurchaseHistory, purchaseHistory])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <TextInput label='Category' textValue={category} keyValue='category' onChange={onChange} />
      <TextInput label='Brand' textValue={brand} keyValue='brand' onChange={onChange} />
      <TextInput label='Description' textValue={description} keyValue='description' onChange={onChange} />
      <RangeNumber
        filterKey='purchaseHistory'
        onChange={onChangeRange}
        from={from}
        to={to}
        isCurrency
        fromLabel='From Price'
        toLabel='To Price'
      />
    </Grid>
  ), [brand, category, description, from, onChange, onChangeRange, to])
}

export default PurchaseHistory
