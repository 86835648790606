import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import useSpinner from "hooks/useSpinner";

export const AuthenticationGuard = ({ component, ...props }) => {
  const { renderSpinner } = useSpinner(true);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        { renderSpinner }
      </div>
    )
  });

  return <Component {...props} />;
};
