import { useCallback, useContext, useState } from "react";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { GET_ASSOCIATES, GET_MESSAGE, GET_MESSAGES } from "utils/queries";
import { ARCHIVE_MESSAGE, ASSIGN_MESSAGE_TO_ASSOCIATE, UNASSIGN_MESSAGE_FROM_ASSOCIATE } from "utils/mutations";
import { enqueueSnackbar } from "notistack";
import UserContext from "contexts/UserContext";

const useInbox = () => {
  const MESSAGES_PER_PAGE = 10;

  const { eventId } = useContext(UserContext);
  const { captureError } = useRollbar();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const { query, apolloClient } = useApi();
  const [page, setPage] = useState(1);
  const [messagesTotal, setMessagesTotal] = useState(0);

  const [associates, setAssociates] = useState([]);

  const fetchMessage = useCallback(async (id) => {
    if (!eventId) {
      return;
    }

    try {
      setLoading(true);
      const {
        data: { message },
      } = await query(
        GET_MESSAGE({ eventId, id })
      );

      if (message) {
        message.highlight = true;

        setMessages((currentMessages) => {
          // Find the index of the existing message with the same customer.id
          const index = currentMessages.findIndex(
            (msg) => msg.customer.id === message.customer.id
          );
      
          if (index !== -1) {
            // Remove the old message and add the new one at the top
            const updatedMessages = [
              message,
              ...currentMessages.slice(0, index),
              ...currentMessages.slice(index + 1),
            ];
            return updatedMessages;
          } else {
            // Add the new message to the top if it doesn't exist already
            return [message, ...currentMessages];
          }
        });

        // setMessages((currentMessages) => [message, ...currentMessages]);
      }
      setLoading(false);

    } catch (e) {
      setLoading(false);
    }
  }, [eventId, query]);

  const archiveMessage = useCallback(async (messageId, onSuccess) => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: ARCHIVE_MESSAGE,
        variables: {
          eventId: parseInt(eventId),
          messageId: parseInt(messageId)
        },
      });
      onSuccess && onSuccess();
      enqueueSnackbar("Message was archived!", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar("Failed to archive message:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  }, [eventId, apolloClient, captureError]);

  const fetchAssociates = useCallback(async (id) => {
    if (!eventId) {
      return;
    }

    try {
      const { data: { teamMembers } } = await query(GET_ASSOCIATES());
      setAssociates(teamMembers);
    } catch (e) {
      captureError(e);
      console.log("Failed to load associates", e);
    }
  }, [eventId, query]);

  const assignMessageToAssociate = useCallback(async (messageId, assignedToAssociate) => {
    try {
      await apolloClient.mutate({
        mutation: ASSIGN_MESSAGE_TO_ASSOCIATE,
        variables: {
          eventId: parseInt(eventId),
          messageId: parseInt(messageId),
          assignedToAssociateId: parseInt(assignedToAssociate.id)
        },
      });

      setMessages((prev) =>
        prev.map((item) =>
          item.id === messageId ? { ...item, assignedToAssociate } : item
        )
      );
    } catch (e) {
      enqueueSnackbar("Failed to assigned message:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
    }
  }, [eventId, apolloClient, captureError]);

  const unassignMessageFromAssociate = useCallback(async (messageId, replyStatus = 'inbound') => {
    try {
      await apolloClient.mutate({
        mutation: UNASSIGN_MESSAGE_FROM_ASSOCIATE,
        variables: {
          eventId: parseInt(eventId),
          messageId: parseInt(messageId),
        },
      });

      setMessages((prev) =>
        replyStatus === 'assigned'
          ? prev.filter((item) => item.id !== messageId)
          : prev.map((item) => (item.id === messageId ? { ...item, assignedToAssociate: null } : item))
      );      
    } catch (e) {
      enqueueSnackbar("Failed to unassigned message:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
    }
  }, [eventId, apolloClient, captureError]);

  const fetchInboxMessages = useCallback(async (append = false, assignedToAssociateId = 0, searchString = '', folderFilter = 'inbox') => {
    if (!eventId) return;

    try {
      setLoading(true);
      const {
        data: { eventMessages },
      } = await query(
        GET_MESSAGES({
          eventId: eventId,
          limit: MESSAGES_PER_PAGE,
          page: page,
          order: "desc",
          assignedToAssociateId: parseInt(assignedToAssociateId),
          searchString: searchString,
          folderFilter: folderFilter,
        })
      );

      if (append) {
        setMessages((prevMessages) => [...prevMessages, ...eventMessages.collection]);

        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      } else {
        setMessages(eventMessages.collection);
      }

      setMessagesTotal(eventMessages.totalCount);
      setLoading(false);
    } catch (e) {
      captureError(e);
      console.log("Failed to load messages", e);
      setLoading(false);
    }
  }, [eventId, MESSAGES_PER_PAGE, page, captureError, query]);

  return {
    MESSAGES_PER_PAGE,
    loading,
    setLoading,
    messages,
    setMessages,
    page,
    setPage,
    messagesTotal,
    setMessagesTotal,
    fetchMessage,
    archiveMessage,
    associates,
    fetchAssociates,
    assignMessageToAssociate,
    unassignMessageFromAssociate,
    fetchInboxMessages
  };
};

export default useInbox;
