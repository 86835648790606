import React from "react";
import { Tooltip } from "react-tooltip";

const NoPhoneEventTooltip = ({ elementId, eventPhone=true, phone=true, subscribed=true, customerPhone=true, editPerson }) => {
  return (
    <Tooltip anchorSelect={elementId} clickable>
      <React.Fragment>
        { !eventPhone && <p>This event doesn't have a phone number.<br/> Complete <a className="text-blue-500 underline" target="_blank" href="https://forms.gle/KJVsZi2dKa8zWorW8" rel="noreferrer">this form</a> to request one.</p> }
        { !phone && <p>Add your phone number in <a className="text-blue-500 underline" href="/settings" rel="noreferrer">Settings</a></p> }
        { !subscribed && <p>This person has been unsubscribed.</p> }
        { !customerPhone && <p>Please <a className="text-blue-500 underline" href="javascript:;" onClick={editPerson} rel="noreferrer">add a phone number</a> first.</p> }
      </React.Fragment>
    </Tooltip>
  )
};

export default NoPhoneEventTooltip;
