import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import TextInput from 'pages/customers/filters/inputs/TextInput'

const Address = () => {
  const {
    state: {
      address
    },
    dispatch
  } = useContext(CustomersContext)

  const {
    address1,
    address2,
    city,
    state,
    zip
  } = address

  const [curAddress, setCurAddress] = useState('')

  const createChips = useCallback(() => {
    const chips = {}
    if (address1) chips.address1 = `Address1: ${address1}`
    if (address2) chips.address2 = `Address2: ${address2}`
    if (city) chips.city = `City: ${city}`
    if (state) chips.state = `State: ${state}`
    if (zip) chips.zip = `Zip: ${zip}`

    dispatch({ type: 'ADDRESS_UPDATE', params: { key: 'chips', value: chips } })
  }, [address1, address2, city, dispatch, state, zip])

  const onChange = useCallback((key, value) => {
    dispatch({ type: 'ADDRESS_UPDATE', params: { key, value } })
  }, [dispatch])

  useEffect(() => {
    const addressString = JSON.stringify(address)
    if (JSON.stringify(address) !== curAddress) {
      setCurAddress(addressString)
      createChips()
    }
  }, [address, createChips, curAddress, setCurAddress])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <TextInput label='Address 1' textValue={address1} keyValue='address1' onChange={onChange} />
      <TextInput label='Address 2' textValue={address2} keyValue='address2' onChange={onChange} />
      <TextInput label='City' textValue={city} keyValue='city' onChange={onChange} />
      <TextInput label='State' textValue={state} keyValue='state' onChange={onChange} />
      <TextInput label='Zip' textValue={zip} keyValue='zip' onChange={onChange} />
    </Grid>
  ), [address1, address2, city, onChange, state, zip])
}

export default Address
