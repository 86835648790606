import React, { useCallback, useMemo, useContext, useEffect } from 'react'
import useStyles from 'pages/customers/_styles/table'
import { useRowSelect, useTable, useSortBy } from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useCustomerColumns from 'hooks/useCustomerColumns'
import CustomersContext from 'contexts/CustomersContext'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import IconButton from '@material-ui/core/IconButton'
// import Tooltip from '@material-ui/core/Tooltip'

const Table = () => {
  const {
    center,
    checkbox,
    overflow,
    overflowSelected,
    paper,
    head,
    body,
    pointer,
    root,
    selected,
    currency
  } = useStyles()

  const { columns } = useCustomerColumns()

  const {
    customers: data,
    hasNext,
    loading,
    nextPage,
    selectedCustomers,
    selectAllCustomers,
    selectSingleCustomer,
    selectAll,
    setOpenFilters,
    setClickedCustomer,
    setOpenRightSideBar,
    clickedCustomer,
    dispatch,
    state: {
      sort: {
        id,
        desc
      }
    }
  } = useContext(CustomersContext)

  const hasSelected = useMemo(() => Object.keys(selectedCustomers).length > 0, [selectedCustomers])

  const {
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    allColumns
  } = useTable({
    columns,
    data,
    disableSortRemove: true,
    initialState: { sortBy: [{ id, desc }] },
    manualSortBy: true
  },
  useSortBy,
  useRowSelect
  )

  useEffect(() => {
    if (!id) {
      allColumns.forEach(ac => {
        if (ac.clearSortBy) ac.clearSortBy()
      })
    }
  }, [allColumns, id])

  useEffect(() => {
    if (sortBy.length > 0) {
      const [{ id, desc }] = sortBy
      dispatch({ type: 'SORT_UPDATE', params: { id, desc } })
    } else dispatch({ type: 'SORT_UPDATE', params: { id: '', desc: false } })
  }, [sortBy, dispatch])

  const tableStyle = useMemo(() => ({
    tableLayout: 'fixed',
    textAlign: 'center',
    width: '100%',
    fontSize: 20,
    color: 'black',
    margin: 25
  }), [])

  const sortIcon = useCallback(sort => {
    const { className } = sort
    if (className.includes('Asc')) return <ArrowDropUpIcon />
    return <ArrowDropDownIcon />
  }, [])

  const onClickRow = useCallback(cell => {
    setOpenFilters(false)
    setOpenRightSideBar(true)
    setClickedCustomer(cell.row)
  }, [setClickedCustomer, setOpenFilters, setOpenRightSideBar])

  const cellStyle = useCallback(id => {
    if (id === 'lifetimeSpend') return currency
    return pointer
  }, [currency, pointer])

  const renderCheckbox = useCallback((key, checked, onChange) => (
    <TableCell
      key={key}
      className={checkbox}
    >
      <Checkbox
        color='primary'
        checked={checked}
        onChange={onChange}
      />
    </TableCell>
  ), [checkbox])

  const header = useMemo(() => (
    headerGroups.map((headerGroup, k) => (
      <TableRow
        key={`header-${k}`}
        classes={{
          head
        }}
      >
        {headerGroup.headers.map(column => {
          const {
            onClick,
            style,
            key
          } = column.getHeaderProps(column.getSortByToggleProps())
          const { id: rowId, isSorted, canSort } = column
          if (rowId === 'checkbox') return renderCheckbox(key, selectAll, selectAllCustomers)

          const sortObj = {}
          if (sortBy.length > 0) {
            const [{ id, desc }] = sortBy
            sortObj.id = id
            sortObj.desc = desc
          }

          return (
            <TableCell
              key={key}
              style={canSort ? style : null}
              onClick={onClick}
              classes={{
                root
              }}
            >
              <TableSortLabel
                hideSortIcon={!canSort || !isSorted}
                active={sortObj.id === column.id}
                direction={sortObj.desc ? 'desc' : 'asc'}
                IconComponent={sortIcon}
              >
                {column.render('Header')}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    ))
  ), [headerGroups, head, renderCheckbox, selectAll, selectAllCustomers, sortBy, root, sortIcon])

  const bodyRender = useMemo(() => (
    rows.map(
      (row, k) => {
        prepareRow(row)
        const { original: { id } } = row

        return (
          <TableRow
            hover key={k}
            classes={{ root: root, selected: selected }}
            selected={row.id === clickedCustomer.id}
          >
            {row.cells.map((cell, k) => {
              const { column: { id: rowId } } = cell
              const select = () => selectSingleCustomer(id)
              if (rowId === 'checkbox') return renderCheckbox(k, Boolean(selectedCustomers[id]), select)
              return (
                <TableCell
                  onClick={rowId !== 'id' ? () => onClickRow(cell) : null}
                  key={`row-${k}`}
                  className={cellStyle(rowId)}
                  classes={{
                    root,
                    body
                  }}
                >
                  {cell.render('Cell')}
                </TableCell>
              )
            })}
          </TableRow>
        )
      }
    )
  ), [body, cellStyle, clickedCustomer.id, onClickRow, prepareRow, renderCheckbox, root, rows, selectSingleCustomer, selected, selectedCustomers])

  return useMemo(() => (
    <div className={paper}>
      <MaUTable style={tableStyle}>
        <TableHead>
          {header}
        </TableHead>
      </MaUTable>
      <div className={!hasSelected ? overflow : overflowSelected} id='scrollRef'>
        <InfiniteScroll
          dataLength={data.length}
          next={nextPage}
          style={{ overflow: 'none' }}
          scrollableTarget='scrollRef'
          hasMore={hasNext}
          endMessage={
            <p className={center}>
              <b>All customers loaded</b>
            </p>
          }
        >
          <MaUTable style={{ ...tableStyle }}>
            <TableBody>
              {bodyRender}
            </TableBody>
          </MaUTable>
        </InfiniteScroll>
        <Grid container justifyContent='center'>
          {loading &&
            <Grid container justifyContent='center'>
              <CircularProgress size={30} />
            </Grid>}
          {hasNext && !loading && <Button onClick={nextPage}>Load More</Button>}
        </Grid>
      </div>
    </div>
  ), [bodyRender, center, data.length, hasNext, hasSelected, header, loading, nextPage, overflow, overflowSelected, paper, tableStyle])
}

export default Table
