import React, { useCallback, useContext, useMemo, useState } from 'react'
import ConfirmationModal from "components/ConfirmationModal";
import { pluralize } from 'utils/misc'
import PeopleContext from "contexts/PeopleContext";
import ListDialog from "./ListDialog";

const ActionsBar = () => {
  const {
    activeList,
    setActiveList,
    selectedPeople,
    selectedDialog,
    openSelectedDialog,
    removePersonFromList,
    selectedListName,
    getSearch
  } = useContext(PeopleContext);

  const selectedPeopleCount = useMemo(() => Object.keys(selectedPeople).length, [selectedPeople]);
  const removeFromListDisabled = selectedPeopleCount === 0 || !activeList || selectedListName == 'All';

  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);

  const openRemoveConfirmation = useCallback(() => {
    setRemoveConfirmationOpen(true);
  }, []);

  const onRemoveCancel = useCallback(() => {
    setRemoveConfirmationOpen(false);
  }, []);

  const onRemoveConfirm = useCallback(() => {
    removePersonFromList(activeList, getSearch);
    setRemoveConfirmationOpen(false);
  }, [removePersonFromList, activeList, setActiveList]);

  return (
    <div>
      {selectedDialog && <ListDialog />}

      {removeConfirmationOpen &&
        <ConfirmationModal
          title={`Remove ${selectedPeopleCount} ${pluralize("person", selectedPeopleCount, "people")} from list`}
          description="Are you sure?"
          confirmButtonMessage="Remove"
          open={removeConfirmationOpen}
          onCancel={onRemoveCancel}
          onConfirm={onRemoveConfirm} />
      }

      <button
        className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        onClick={openSelectedDialog}
      >
        Add <span className="hidden sm:block sm:ml-1">to list</span>
      </button>
      { !removeFromListDisabled &&
          <button
            className="inline-flex items-center ml-2 px-3 py-1 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            disabled={removeFromListDisabled}
            onClick={openRemoveConfirmation}
          >
            Remove <span className="hidden sm:block sm:ml-1">from list</span>
          </button>
      }
    </div>
  );
}

export default ActionsBar;
