import React, { Fragment, useEffect, useContext, useState, useCallback, useMemo } from 'react'
import Fuse from 'fuse.js'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import CustomersContext from 'contexts/CustomersContext'
import useSpinner from 'hooks/useSpinner'
import { pluralize } from 'utils/misc'

const ContactListDialog = () => {
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [addingNewList, setAddingNewList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    selectedDialog,
    closeSelectedDialog,
    selectedCustomers,
    fetchContactLists,
    selectedList,
    setSelectedList,
    addCustomerToList,
    dispatch,
    createList,
    contactLists
  } = useContext(CustomersContext)

  useEffect(() => {
    if (!contactLists) fetchContactLists()
  }, [fetchContactLists, contactLists]);

  useEffect(() => {
    if (!contactLists) return;
    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'name'
      ]
    };
    const fuse = new Fuse(contactLists, options);
    if (search) {
      const results = fuse.search(search);
      setFiltered(results.map(i => ({
        id: i.item.id,
        name: i.item.name
      })));
    } else setFiltered(contactLists);
  }, [search, contactLists]);

  const onSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onListOptionChange = useCallback((e) => {
    setSelectedList(e.target.value);
  }, [setSelectedList]);

  const { renderSpinner } = useSpinner(loading);
  const newListSaveDisabled = useMemo(() => newListName === "" || loading, [newListName, loading]);

  const onAddNewListClick = useCallback((e) => setAddingNewList(true), []);

  const onAddNewListCancelClick = useCallback((e) => {
    setAddingNewList(false);
    setNewListName("");
  }, []);

  const onNewListNameChange = useCallback((e) => setNewListName(e.target.value), []);

  const onSaveNewList = useCallback(async () => {
    setLoading(true);
    await createList(newListName, false);
    setNewListName("");
    setAddingNewList(false);
    setLoading(false);
  }, [newListName, createList]);

  const onAddToListClick = useCallback(() => {
    addCustomerToList(() => { dispatch({ type: "CLEAR", key: "activeList" }) });
  }, [addCustomerToList, dispatch]);

  return (
    <Transition.Root appear="true" show={selectedDialog} as={Fragment}>
      <Dialog as="div" className="relative z-[1500]" onClose={closeSelectedDialog}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Add { Object.keys(selectedCustomers).length > 1?
                           (Object.keys(selectedCustomers).length + " people")
                           : "one person" } to
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={closeSelectedDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Search bar */}
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="pointer-events-none absolute p-2 inset-y-0 left-0 flex items-center">
                          <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        </div>
                        <input
                          type="text"
                          name="list"
                          id="list-search"
                          value={search}
                          onChange={onSearchChange}
                          className="block w-full rounded-full border-0 pl-8 pr-12 pr-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Search lists"
                        />
                      </div>
                      {/* List options */}
                      <div className="mt-6 space-y-4">
                        <div>
                          { !addingNewList &&
                            <button className="cursor-pointer" onClick={onAddNewListClick}>Add new...</button>
                          }
                          { addingNewList &&
                            <div className="flex items-center">
                              <input
                                className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                                name="new-list-name"
                                placeholder="New list name..."
                                value={newListName}
                                onChange={onNewListNameChange}
                                autoFocus={true}
                              />
                              <button className="ml-2 cursor-pointer text-indigo-600 disabled:opacity-50" disabled={newListSaveDisabled} onClick={onSaveNewList}>Save</button>
                              <button className="ml-2 cursor-pointer text-red-600 disabled:opacity-50" disabled={loading} onClick={onAddNewListCancelClick}>Cancel</button>

                              <div className="ml-2">
                                { renderSpinner }
                              </div>
                            </div>
                          }
                        </div>

                        {filtered.map((list) => (
                          <div key={list.id} className="flex items-center">
                            <input
                              id={list.id}
                              name="list-id"
                              type="radio"
                              value={list.id}
                              checked={list.id === selectedList}
                              onChange={onListOptionChange}
                              className="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor={list.id} className="cursor-pointer ml-3 block text-sm font-medium leading-6 text-gray-900">
                              {list.name}
                            </label>
                          </div>
                        ))}
                      </div>
                      {/* Actions */}
                      <div className="mt-6 flex justify-end">
                        <button
                          type="button"
                          className="mr-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                          onClick={closeSelectedDialog}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                          onClick={onAddToListClick}
                        >
                          Add to List
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default React.memo(ContactListDialog)
