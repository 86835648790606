import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CircularProgress } from '@material-ui/core'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import useRollbar from '../../hooks/useRollbar'
import { useSnackbar } from 'notistack'
import useStyles from 'pages/billing/_styles/paymentMethod.js'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { useConfirm } from 'material-ui-confirm'
import { ADD_ORG_PAYMENT_METHOD, DELETE_ORG_PAYMENT_METHOD } from '../../utils/mutations'
const PaymentMethod = ({ paymentMethodLabel }) => {
  const [addCard] = useMutation(ADD_ORG_PAYMENT_METHOD)
  const [deleteCard] = useMutation(DELETE_ORG_PAYMENT_METHOD)
  const [formError, setFormError] = useState(null)
  const [inProgress, setInProgress] = useState(false)
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false)
  const [methodLabel, setMethodLabel] = useState(null)
  const { captureError } = useRollbar()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const CARD_OPTIONS = {
    style: {
      base: {
        iconColor: '#38bcb2',
        fontWeight: 500,
        fontFamily: 'Karla, Roboto,sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#2c323e' },
        '::placeholder': { color: '#2c323e' }
      },
      invalid: {
        iconColor: '#e07072',
        color: '#e07072'
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const card = elements.getElement(CardElement)
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: { name: 'concierge' }
      })
      if (!result.error) {
        setInProgress(true)
        const res = await addCard({ variables: { card: JSON.stringify(result) } })
        setInProgress(false)
        if (res.data) {
          setMethodLabel(buildLabel(result))
          setHasPaymentMethod(true)
          enqueueSnackbar('Your credit card has been saved', { variant: 'success', autoHideDuration: 3000 })
        } else {
          enqueueSnackbar(res.errors[0], { variant: 'error', autoHideDuration: 3000 })
          setInProgress(false)
          setFormError(res.errors[0])
          captureError('Add card: There was an error while submitting')
        }
      } else {
        setFormError(result.error.message)
      }
    } catch (error) {
      setInProgress(false)
      setFormError(error.message)
      captureError(error)
    }
  }

  const buildLabel = (result) => {
    return `${result.paymentMethod.card.brand} ending in ${result.paymentMethod.card.last4} (Expires  ${result.paymentMethod.card.exp_month}/${result.paymentMethod.card.exp_year})`
  }

  const handleChange = () => {
    setFormError(null)
  }

  useEffect(() => {
    setHasPaymentMethod(paymentMethodLabel !== null)
  }, [paymentMethodLabel])

  const deletePaymentMethod = () => {
    confirm({ title: 'Confirm', description: 'Are you sure you want to remove your payment method?' })
      .then(() => {
        setInProgress(true)
        deleteCard().then(() => {
          setMethodLabel(null)
          setHasPaymentMethod(false)
          enqueueSnackbar('Your credit card has been removed', { variant: 'success', autoHideDuration: 3000 })
          setInProgress(false)
        }).catch((error) => {
          enqueueSnackbar('Add card: There was an error while removing your card', { variant: 'error', autoHideDuration: 3000 })
          setInProgress(false)
          captureError(error)
        })
      })
      .catch(() => { })
  }

  const paymentMethods = () => {
    return (
      <Card className={classes.card}>
        <CardHeader
          title='PAYMENT METHOD'
          subheader={paymentMethodLabel || methodLabel}
        />
        <CardActions>
          {!inProgress
            ? <Button color='primary' className={classes.submitButton} variant='contained' onClick={deletePaymentMethod}>Remove </Button>
            : <CircularProgress className={classes.centered} />}
        </CardActions>
      </Card>
    )
  }

  const addPaymentMethod = () => {
    return (
      <div>
        <Card className={classes.card}>
          <CardHeader
            title='PAYMENT METHOD'
            subheader='Please add a credit card to your account'
          />
          <CardActionArea>
            <CardContent>
              <CardElement id='card-element' options={CARD_OPTIONS} onChange={handleChange} />
            </CardContent>
          </CardActionArea>
          <CardActions>
            {!inProgress
              ? <Button type='submit' color='primary' className={classes.submitButton} variant='contained' onClick={handleSubmit}> Add Card </Button>
              : <CircularProgress className={classes.centered} />}
          </CardActions>
          <div role='alert' className={classes.formError}>
            {formError && <ErrorIcon size='small' className={classes.errorIcon} />} {formError}
          </div>
        </Card>
      </div>
    )
  }

  return (
    hasPaymentMethod ? paymentMethods() : addPaymentMethod()
  )
}

export default PaymentMethod
