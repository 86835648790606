import { useMemo, useCallback, useEffect, useState, useContext } from 'react'
import useApi from 'hooks/useApi'
import {
  CUSTOMERS, LIST_SEARCH
} from 'utils/queries'
import useRollbar from 'hooks/useRollbar'
import UserContext from "contexts/UserContext"
import { ADD_CUSTOMER_TO_LIST, CREATE_LIST, DISMISS_CUSTOMERS_FROM_LIST, REMOVE_CUSTOMER_FROM_LIST } from "utils/mutations"
import { enqueueSnackbar } from "notistack"

const PAGE_SIZE = 50;

const usePeople = () => {
  const { eventId } = useContext(UserContext);
  const { query, mutate } = useApi();

  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const { captureError } = useRollbar();

  const [nameSearch, setNameSearch] = useState(null);
  const [clickedCustomer, setClickedCustomer] = useState({});

  const [lists, setLists] = useState(null);

  const [selectedListName, setSelectedListName] = useState("");
  const [activeList, setActiveList] = useState(new URLSearchParams(window.location.search).get("alid") ?? "");

  const [selectedPeople, setSelectedPeople] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const hasPrevious = useMemo(() => page > 1, [page]);
  const hasNext = useMemo(() => total > people.length, [people, total]);
  const hasNextTraditional = useMemo(() => total > PAGE_SIZE * page, [page, total]);

  const [selectedDialog, setSelectedDialog] = useState(false);
  const openSelectedDialog = useCallback(() => setSelectedDialog(true), []);
  const closeSelectedDialog = useCallback(() => setSelectedDialog(false), []);

  const [sortBy, setSortBy] = useState(new URLSearchParams(window.location.search).get("sort") || "firstname");
  const [sortByOrder, setSortByOrder] = useState(new URLSearchParams(window.location.search).get("desc") === "true" ? "desc" : "asc");  

  const clearSelect = useCallback(() => {
    setSelectAll(false);
    setSelectedPeople({});
  }, []);

  const getSearch = async () => {
    try {
      clearSelect()
      setLoading(true);
      const queryString = CUSTOMERS(eventId, nameSearch, activeList, PAGE_SIZE, 1, sortBy, sortByOrder);
      const res = await query(queryString);

      setLoading(false);
      const { data: { customers: { collection: customers, totalCount } } } = res;
      setPeople(customers);
      setTotal(totalCount);
      setPage(1);
    } catch (e) {
      captureError(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!eventId) return;

    getSearch();
  }, [query, activeList, nameSearch, sortBy, sortByOrder, captureError, eventId]);

  const nextPage = useCallback(async () => {
    setLoading(true);
    const res = await query(CUSTOMERS(eventId, nameSearch, activeList, PAGE_SIZE, page + 1, sortBy, sortByOrder));
    const { data: { customers: { collection: nc } } } = res;
    setPeople([...people, ...nc]);
    setPage(page + 1);
    setLoading(false);
  }, [people, page, nameSearch, activeList, sortBy, sortByOrder, query, eventId]);

  const fetchLists = useCallback(
    async (searchTerm = "") => {
      if (!eventId) return;

      if (typeof searchTerm !== "string") searchTerm = "";
      try {
        const res = await query(LIST_SEARCH(searchTerm, eventId));
        setLists(res.data.listSearch.lists);
      } catch (e) {
        captureError(e);
        console.log("Failed to load lists", e);
      }
    },
    [eventId, query, captureError]
  );

  const dismissFromList = useCallback(async () => {
    try {
      const customerIds = Object.keys(selectedPeople);
      const params = `eventId: ${parseInt(eventId)}, listId: ${activeList}, customerIds: [${customerIds}]`;
      const res = await mutate(DISMISS_CUSTOMERS_FROM_LIST(params));
      enqueueSnackbar("People dismissed from list", {
        autoHideDuration: 3000,
        variant: "success",
      });
      return res;
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to dismiss from list", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  }, [captureError, eventId, activeList, mutate, selectedPeople]);

  const selectSinglePerson = (id) => {
    if (selectAll) setSelectAll(false);
    if (selectedPeople[id]) {
      const { [id]: removed, ...ids } = selectedPeople;
      setSelectedPeople(ids);
    } else {
      const ids = { ...selectedPeople, [id]: true };
      setSelectedPeople(ids);
    }
  };

  const createList = useCallback(
    async (name, shouldCloseNewListDialog = true) => {
      try {
        const res = await mutate(CREATE_LIST(eventId, name));
        if (JSON.stringify(res).includes("Name has already been taken")) {
          enqueueSnackbar("List name has already been taken", {
            autoHideDuration: 3000,
            variant: "error",
          });
        } else {
          await fetchLists();
          if (shouldCloseNewListDialog) closeSelectedDialog();
        }

        return res?.data?.createList?.id ?? "";
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed to create list", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
    },
    [
      eventId,
      mutate,
      fetchLists,
      closeSelectedDialog,
      captureError
    ]
  );

  const addPersonToList = useCallback(async (listId, afterAddCallback) => {
    const ids = Object.keys(selectedPeople).map((s) => Number(s));
    const params = `eventId: ${Number(eventId)}, listId: ${Number(listId)}, customerIds: [${ids}]`;

    if (listId) {
      try {
        const res = await mutate(ADD_CUSTOMER_TO_LIST(params));
        if (res.data.addCustomersToList) {
          afterAddCallback && afterAddCallback();
          closeSelectedDialog();
          enqueueSnackbar("Added to list", {
            autoHideDuration: 3000,
            variant: "success",
          });
        }
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed to add to lists", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Please select a list", {
        autoHideDuration: 3000,
        variant: "info",
      });
    }
  }, [
    eventId,
    selectedPeople,
    mutate,
    closeSelectedDialog,
    captureError
  ]);

  const removePersonFromList = useCallback(async (listId, afterRemoveCallback) => {
    const ids = Object.keys(selectedPeople).map((s) => Number(s));
    const params = `eventId: ${Number(eventId)}, listId: ${Number(listId)}, customerIds: [${ids}]`;

    if (listId) {
      try {
        const res = await mutate(REMOVE_CUSTOMER_FROM_LIST(params));
        if (res.data.removeCustomersFromList) {
          afterRemoveCallback && afterRemoveCallback();
          enqueueSnackbar("Customer(s) removed from list", {
            autoHideDuration: 3000,
            variant: "success",
          });
        }
      } catch (e) {
        captureError(e);
        enqueueSnackbar("Failed to remove from list", {
          autoHideDuration: 3000,
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Please select a list", {
        autoHideDuration: 3000,
        variant: "info",
      });
    }
  }, [
    eventId,
    selectedPeople,
    mutate,
    captureError
  ]);

  return {
    people,
    loading,
    total,
    nameSearch,
    setNameSearch,
    activeList,
    setActiveList,
    page,
    setPage,
    hasPrevious,
    hasNext,
    hasNextTraditional,
    nextPage,
    clickedCustomer,
    setClickedCustomer,
    selectedListName,
    setSelectedListName,
    fetchLists,
    dismissFromList,
    lists,
    setSelectedPeople,
    selectedPeople,
    selectSinglePerson,
    selectedDialog,
    openSelectedDialog,
    closeSelectedDialog,
    createList,
    addPersonToList,
    removePersonFromList,
    sortBy,
    setSortBy,
    sortByOrder,
    setSortByOrder,
    getSearch,
    clearSelect
  }
}

export default usePeople;
