import moment from "moment";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function pluralize(singularString, count, pluralString = null) {
  if (count > 1) {
    return pluralString ? pluralString : `${singularString}s`
  } else {
    return singularString;
  }
}

export function defaultNearest30minutesDate() {
  const start = moment();
  let remainder = 30 - (start.minute() % 30);

  // If the remainder is less than 5 minutes we add 30 minutes more
  if (remainder <= 5) {
    remainder = remainder + 30;
  }

  return moment(start).add(remainder, "minutes").format("YYYY-MM-DDTHH:mm");
};

export function defaultNearest15minutesDate() {
  const start = moment();
  let remainder = 15 - (start.minute() % 15);

  // If the remainder is less than 2 minutes we add 15 minutes more
  if (remainder <= 2) {
    remainder = remainder + 15;
  }

  return moment(start).add(remainder, "minutes").set("second", 0);
};
