import { makeStyles } from '@material-ui/core/styles'
export default makeStyles(theme => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginTop: '24px'
  },
  infoContainer: {
    marginTop: '30px',
    legend: { margin: '10px' }
  }
}))
