import { useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import InboxContext from 'contexts/InboxContext';
import SimpleCombo from './SimpleCombo';

const AssignToMememberDialog = ({ open, setOpen, messageId, associateId }) => {
  const { associates, assignMessageToAssociate } = useContext(InboxContext);

  const [selectedPerson, setSelectedPerson] = useState(() => {
    return associates.length && associateId != 0 ? associates.find((item) => item.id === associateId) : null
  })
  const [isDisabled, setIsDisabled] = useState(true);
  
  const handleOnChange = (person) => {
    setSelectedPerson(person);
    isDisabled && setIsDisabled(false);
  }

  const assignMember = async () => {
    if (!selectedPerson) return;
  
    setIsDisabled(true);
    await assignMessageToAssociate(messageId, selectedPerson);
    setOpen(false);
    setIsDisabled(false);
  };  

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-5 pt-5">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Assign to a team member
                </Dialog.Title>
                
                <div className='mt-8'>
                  <SimpleCombo 
                    options={associates}
                    selectedValue={selectedPerson}
                    handleChange={handleOnChange}
                    canBeCleared={false}
                    inputPlaceholder=""
                    labelText="Choose a person from the dropdown below."
                  />
                </div>
                
                <div className="mt-6 flex justify-end">
                  <button
                    type="button"
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setOpen(false)}
                    data-autofocus
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none ml-3 disabled:opacity-25"
                    onClick={assignMember}
                    disabled={isDisabled}
                  >
                    Assign
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

export default AssignToMememberDialog;