import React, { Fragment, useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Combobox, Transition } from '@headlessui/react'
import { classNames } from 'utils/misc'

const SimpleCombo = ({ className = "", options, selectedValue, handleChange, canBeCleared = true, inputPlaceholder = "", labelText = "" }) => {
  const [query, setQuery] = useState("");

  const filteredPeople = useMemo(() =>
    query === '' ? options : options.filter(person => `${person.firstname} ${person.lastname}`.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))), 
    [options, query]
  );

  return (
    <Combobox className={className} as="div" value={selectedValue} onChange={handleChange} nullable={canBeCleared}>
      {labelText !== "" && <Combobox.Label className="block text-sm text-gray-500">{labelText}</Combobox.Label>}
      <div className="relative">
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-gray-400"
            displayValue={(selectedValue) => selectedValue ? `${selectedValue.firstname} ${selectedValue.lastname}` : null}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={inputPlaceholder}
          />
          { selectedValue !== null && canBeCleared &&
            <Combobox.Button onClick={() => handleChange(null)} className="absolute inset-y-0 right-0 flex items-center mr-8 focus:outline-none">
              <XMarkIcon className="h-5 w-5 text-gray-400" />
            </Combobox.Button>
          }
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredPeople.map((person) => (
                <Combobox.Option
                  key={person.id}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    )
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{person.firstname} {person.lastname}</span>
                      {selected ? (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
};

export default React.memo(SimpleCombo);
