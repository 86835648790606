import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 110

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  reassignButton: {
    marginLeft: theme.spacing()
  },
  grayButton: {
    marginLeft: theme.spacing(),
    backgroundColor: theme.palette.lightGray.main,
    '&:hover': {
      backgroundColor: theme.palette.midGray.main
    }
  },
  selectedLabel: {
    color: theme.palette.midGray.main,
    marginLeft: theme.spacing(2)
  },
  messageButton: {
    marginLeft: theme.spacing()
  }
}))

export default useStyles
