import React from "react";
import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

const NoPhoneEventAlert = () => {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <div className="text-sm text-yellow-700">
            <p>
              This event doesn't have a phone number. Complete <a className="text-blue-500 underline" target="_blank" href="https://forms.gle/KJVsZi2dKa8zWorW8" rel="noreferrer">this form</a> to request one.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPhoneEventAlert;
