import queryParams from 'utils/queryParams'
import clsx from 'clsx'

const {
  fullname,
  lsfrom,
  lsto,
  lpfrom,
  lpto,
  lpop,
  pcop,
  pcfrom,
  pcto,
  address1,
  address2,
  city,
  adstate,
  zip,
  phcat,
  phbrand,
  phdesc,
  phfrom,
  phto,
  aaid,
  alid,
  ehfrom,
  ehto,
  ehsubject,
  ehbody,
  ehdfrom,
  ehdto,
  ehopened,
  evid,
  evop,
  evfrom,
  evto,
  lcop,
  lcfrom,
  lcto,
  emid,
  phid,
  sort,
  desc,
  aldismissed,
  sph,
  sem,
  ...other
} = queryParams.get()

const cp = {}
Object.keys(other).forEach(o => {
  if (o.includes('cp')) {
    cp[o.slice(2)] = other[o]
  }
})

export const initialState = {
  sort: {
    id: sort || 'firstname',
    desc: desc === 'true' || false
  },
  fullname: {
    id: fullname || ''
  },
  lifetimeSpend: {
    changed: Boolean(clsx(lsfrom, lsto)),
    from: lsfrom || '',
    to: lsto || '',
    chip: ''
  },
  lastPurchase: {
    changed: Boolean(clsx(lpop, lpfrom, lpto)),
    option: lpop || 'all',
    from: lpfrom || '',
    to: lpto || '',
    chip: ''
  },
  purchaseCount: {
    changed: Boolean(clsx(pcop, pcfrom, pcto)),
    option: pcop || 'all',
    from: pcfrom || '',
    to: pcto || '',
    chip: ''
  },
  address: {
    changed: Boolean(clsx(address1, address2, city, adstate, zip)),
    address1: address1 || '',
    address2: address2 || '',
    city: city || '',
    state: adstate || '',
    zip: zip || '',
    chips: {}
  },
  purchaseHistory: {
    changed: Boolean(clsx(phcat, phbrand, phdesc, phfrom, phto)),
    category: phcat || '',
    brand: phbrand || '',
    description: phdesc || '',
    from: phfrom || '',
    to: phto || '',
    chips: {}
  },
  assignedAssociate: {
    changed: Boolean(clsx(aaid)),
    id: aaid || '',
    chip: ''
  },
  customerProperties: {
    ...cp,
    changed: false,
    chips: {}
  },
  activeList: {
    changed: Boolean(clsx(alid)),
    id: alid || '',
    dismissed: aldismissed || false,
    chip: ''
  },
  emailHistory: {
    changed: Boolean(clsx(ehfrom, ehto, ehsubject, ehbody, ehdfrom, ehdto, ehopened)),
    efrom: ehfrom || '',
    eto: ehto || '',
    subject: ehsubject || '',
    body: ehbody || '',
    from: ehdfrom || null,
    to: ehdto || null,
    opened: ehopened || '',
    chips: {}
  },
  eventHistory: {
    changed: Boolean(clsx(evid, evop, evfrom, evto)),
    id: evid || '',
    option: evop || 'all',
    from: evfrom || '',
    to: evto || '',
    chip: ''
  },
  lastContacted: {
    changed: Boolean(clsx(lcop, lcfrom, lcto)),
    option: lcop || 'all',
    from: lcfrom || '',
    to: lcto || '',
    chip: ''
  },
  contactInformation: {
    changed: true,
    phone: phid || '',
    email: emid || '',
    chips: {}
  },
  subscriptions: {
    changed: Boolean(clsx(sph, sem)),
    phone: sph || '',
    email: sem || '',
    chips: ''
  }
}

const clearKey = (state, action) => {
  if (action.key && state[action.key]) {
    const clear = {}
    Object.keys(state[action.key]).forEach(v => { if (v !== 'chips') clear[v] = '' })
    clear.changed = false
    return { ...state, [action.key]: clear }
  }
  return state
}

const getChanged = (filter, key) => {
  const { changed: c, chips: cp, [key]: rm, ...other } = filter
  const keys = Object.keys(other)
  let changed = false
  keys.forEach(k => {
    if (other[k]) changed = true
  })
  return changed
}

const objectChanged = (curChips, newChips) => JSON.stringify(curChips) !== JSON.stringify(newChips)

export const customerSearchReducer = (state, action) => {
  const changed = action.params && !['chip', 'chips'].includes(action.params.key) ? { changed: true } : {}

  switch (action.type) {
    case 'BULK_UPDATE':
      return { ...state, ...action.params.bulk }
    case 'SORT_UPDATE':
      if (!objectChanged(state.sort, action.params)) return state
      return { ...state, sort: action.params }
    case 'FULLNAME_UPDATE':
      return { ...state, fullname: { ...state.fullname, [action.params.key]: action.params.value } }
    case 'LIFETIME_SPEND_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.lifetimeSpend.chip, action.params.value)
      ) return state
      return { ...state, lifetimeSpend: { ...state.lifetimeSpend, [action.params.key]: action.params.value, ...changed } }
    case 'LAST_PURCHASE_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.lastPurchase.chip, action.params.value)
      ) return state
      return { ...state, lastPurchase: { ...state.lastPurchase, [action.params.key]: action.params.value, ...changed } }
    case 'PURCHASE_COUNT_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.purchaseCount.chip, action.params.value)
      ) return state
      return { ...state, purchaseCount: { ...state.purchaseCount, [action.params.key]: action.params.value, ...changed } }
    case 'ADDRESS_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chips' &&
        !objectChanged(state.address.chips, action.params.value)
      ) return state
      return {
        ...state,
        address: {
          ...state.address,
          [action.params.key]: action.params.value,
          ...changed
        }
      }
    case 'PURCHASE_HISTORY_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chips' &&
        !objectChanged(state.purchaseHistory.chips, action.params.value)
      ) return state
      return { ...state, purchaseHistory: { ...state.purchaseHistory, [action.params.key]: action.params.value, ...changed } }
    case 'ASSIGNED_ASSOCIATE_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.assignedAssociate.chip, action.params.value)
      ) return state
      return { ...state, assignedAssociate: { ...state.assignedAssociate, [action.params.key]: action.params.value, ...changed } }
    case 'ACTIVE_LIST_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.activeList.chip, action.params.value)
      ) return state
      return { ...state, activeList: { ...state.activeList, [action.params.key]: action.params.value, ...changed } }
    case 'EMAIL_HISTORY_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chips' &&
        !objectChanged(state.emailHistory.chips, action.params.value)
      ) return state
      return { ...state, emailHistory: { ...state.emailHistory, [action.params.key]: action.params.value, ...changed } }
    case 'EVENT_HISTORY_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.eventHistory.chip, action.params.value)
      ) return state
      return { ...state, eventHistory: { ...state.eventHistory, [action.params.key]: action.params.value, ...changed } }
    case 'CUSTOMER_PROPERTIES_UPDATE':
      if (
        !changed?.changed &&
       action.params.key === 'chips' &&
      !objectChanged(state.customerProperties.chips, action.params.value)
      ) return state
      return { ...state, customerProperties: { ...state.customerProperties, [action.params.key]: action.params.value, ...changed } }
    case 'LAST_CONTACTED_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chip' &&
        !objectChanged(state.lastContacted.chip, action.params.value)
      ) return state
      return { ...state, lastContacted: { ...state.lastContacted, [action.params.key]: action.params.value, ...changed } }
    case 'CONTACT_INFORMATION_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chips' &&
        !objectChanged(state.contactInformation.chips, action.params.value)
      ) return state
      return { ...state, contactInformation: { ...state.contactInformation, [action.params.key]: action.params.value, ...changed } }
    case 'SUBSCRIPTIONS_UPDATE':
      if (
        !changed?.changed &&
        action.params.key === 'chips' &&
        !objectChanged(state.subscriptions.chips, action.params.value)
      ) return state
      return { ...state, subscriptions: { ...state.subscriptions, [action.params.key]: action.params.value, ...changed } }
    case 'DELETE':
      if (action.key.includes(':')) {
        const [parent, child] = action.key.split(':')

        const { [child]: deleted, ...filtered } = state[parent]
        return { ...state, [parent]: { ...filtered, changed: Object.keys(filtered).length !== 3 } }
      }
      return state
    case 'CLEAR':
      if (action.key.includes(':')) {
        const [parent, child] = action.key.split(':')

        if (child === 'range') return { ...state, [parent]: { ...state[parent], from: '', to: '', changed: getChanged(state[parent], child) } }
        return { ...state, [parent]: { ...state[parent], [child]: '', changed: getChanged(state[parent], child) } }
      }

      return clearKey(state, action)
    default:
      return state
  }
}
