
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { classNames } from 'utils/misc';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import useRouter from 'hooks/useRouter';
import UserContext from "contexts/UserContext";
import ActionsBar from './ActionsBar';
import { getAbbreviation } from 'utils/formatter'
import PeopleContext from "contexts/PeopleContext";
import { formatDateWithTimezone } from "utils/formatter";

const Table = () => {
  const {
    people,
    total,
    hasNextTraditional,
    nextPage,
    loading,
    selectedPeople,
    setSelectedPeople,
    selectSinglePerson,
    sortBy,
    setSortBy,
    sortByOrder,
    setSortByOrder,
    activeList
  } = useContext(PeopleContext);

  const { eventId, displayListAddedDateFeatureFlag } = useContext(UserContext);

  const { navigate } = useRouter();

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const selectedPeopleArray = useMemo(() => Object.keys(selectedPeople), [selectedPeople]);

  const sortIcon = useCallback(sort => {
    if (!sort) return;
    if (sortByOrder === "desc") return <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />;

    return <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />;
  }, [sortByOrder]);

  const sortForField = useCallback(field => {
    return sortBy === field;
  }, [sortBy]);

  const onClickFieldHeader = useCallback(field => {
    if (sortForField(field)) {
      sortByOrder === "asc" ? setSortByOrder("desc") : setSortByOrder("asc");
    } else {
      setSortBy(field);
    }  

    window.history.pushState({}, '', window.location.href.split("?")[0] + `?alid=${activeList}&desc=${sortByOrder === "asc" ? true : false}&sort=${sortBy}`);
  }, [sortForField, sortByOrder, setSortByOrder, setSortBy]);

  const onClickFirstName = useCallback(() => {
    onClickFieldHeader("firstname");
  }, [onClickFieldHeader]);

  const onClickLastName = useCallback(() => {
    onClickFieldHeader("lastname");
  }, [onClickFieldHeader]);

  const onClickCreatedAt = useCallback(() => {
    onClickFieldHeader("created_at");
  }, [onClickFieldHeader]);


  useLayoutEffect(() => {
    const isIndeterminate = selectedPeopleArray.length > 0 && selectedPeopleArray.length < people.length;
    setChecked(selectedPeopleArray.length === people.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeopleArray, people.length]);

  const toggleAll = useCallback(() => {
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);

    const ids = {};
    people.forEach((c) => {
      ids[c.id] = true;
    });
    setSelectedPeople(checked || indeterminate ? {} : ids);
  }, [checked, indeterminate, people, setSelectedPeople]);

  const viewPerson = useCallback((id) => navigate(`/events/${eventId}/person/${id}`), [eventId, navigate]);

  const showInfo = (current, total, selected) => {
    let info = [];

    // show selected info and action buttons
    if (selected > 0) {
      if (selected >1)
        info.push(<span key="selected"><b>{selected}</b> people selected of <b>{total > 1? total : "one"}</b></span>);
      else
        info.push(<span key="selected"><b>One</b> person selected of <b>{total > 1? total : "one"}</b></span>);
      info.push(<div className='flex-1'></div>)
      info.push(<ActionsBar/>)
      return info;
    }

    // show page info
    if (current === 0)
      return info;

    if (current === 1) {
      info.push(<span key={current}>Showing <b>one</b> person</span>)
    }
    else if (current === total) {
      info.push(<span key={current}>Showing all <b>{total}</b> people</span>)
    }
    else {
      info.push(<span key={current}>Showing <b>1</b>-<b>{current}</b> of <b>{total}</b> people</span>)
    }

    return info;
  }

  const handleNextPage = async () => {
    await nextPage();
   
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: 'smooth'
    // });
  }

  return (
    <div className="mt-4 flow-root">
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full pt-2 align-middle sm:px-6 lg:px-8 -mb-6">
          <div>
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead className="sticky top-[64px] bg-[#fafafa] z-5">
                <tr>
                  <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                    <input
                      type="checkbox"
                      className="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      ref={checkbox}
                      checked={checked}
                      onChange={toggleAll}
                    />
                  </th>
                  <th scope="col" className="min-w-[4rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    <button className="group inline-flex" onClick={onClickFirstName}>
                      First Name
                      <span className={`ml-2 flex-none rounded ${sortForField("firstname") ? "bg-gray-100 text-gray-900 group-hover:bg-gray-200" : "invisible text-gray-400 group-hover:visible group-focus:visible"}`}>
                        {sortIcon(sortForField("firstname"))}
                      </span>
                    </button>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <button  className="group inline-flex" onClick={onClickLastName}>
                      Last Name
                      <span className={`ml-2 flex-none rounded ${sortForField("lastname") ? "bg-gray-100 text-gray-900 group-hover:bg-gray-200" : "invisible text-gray-400 group-hover:visible group-focus:visible"}`}>
                        {sortIcon(sortForField("lastname"))}
                      </span>
                    </button>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    { displayListAddedDateFeatureFlag && activeList ? (
                      <button className="group inline-flex" onClick={onClickCreatedAt}>
                        Added
                        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                          {sortIcon(sortForField("created_at"))}
                        </span>
                      </button>
                    ) : '' }
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {people.length > 0 ?
                  people.map((person) => (
                    <tr key={person.id} className={selectedPeople[person.id] ? 'bg-gray-50' : undefined}>
                      <td className="relative px-7 sm:w-12 sm:px-6">
                        {selectedPeople[person.id] && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={person.id}
                          checked={selectedPeople[person.id] || false}
                          onChange={(e) => selectSinglePerson(person.id)}
                        />
                      </td>
                      <td
                        className={classNames(
                          'whitespace-nowrap py-4 pr-3 text-sm',
                          selectedPeople[person.id] ? 'text-indigo-600' : 'text-gray-900'
                        )}
                      >
                        <button onClick={() => viewPerson(person.id)}>
                          { person.firstname && person.firstname !== "" &&
                            <span>
                              {getAbbreviation(person.firstname, 15)}
                            </span>
                          }
                          { (!person.firstname || person.firstname === "") &&
                            <span className="text-gray-300">(empty)</span>
                          }
                        </button>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        <button onClick={() => viewPerson(person.id)}>
                          {getAbbreviation(person.lastname, 15)}
                        </button>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        { displayListAddedDateFeatureFlag && activeList ? formatDateWithTimezone(person.listMemberCreatedAt) : '' }
                      </td>
                    </tr>
                  ))
                :
                !loading &&
                <tr>
                  <td className='text-center py-5' colSpan={4}>No people.</td>
                </tr>
                }
                { hasNextTraditional &&
                  <tr>
                    <td className="text-center py-4 cursor-pointer font-semibold" colSpan={4} onClick={handleNextPage}>Show {Math.min(50, total-people.length)} more</td>
                  </tr>
                }
              </tbody>
            </table>
            <nav
              className="sticky bottom-[0px] bg-[#fafafa] z-5 py-3 flex items-center justify-end px-4 sm:px-0"
              aria-label="Pagination"
            >
              <div className="flex flex-1 flex-wrap justify-between items-center gap-y-2 py-2 sm:justify-between">
                { showInfo(people.length, total, selectedPeopleArray.length) }
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
