import React from 'react'
import Rollbar from 'rollbar'
import Proptypes from 'prop-types'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    const rollbar = process.env.NODE_ENV === 'development'
      ? null
      : new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.REACT_APP_ENV,
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: process.env.REACT_APP_GIT_SHA
            }
          }
        }
      })

    this.state = {
      error: null,
      errorInfo: null,
      rollbar
    }
  }

  componentDidCatch (error, errorInfo) {
    if (String(error).includes('The node to be removed is not a child of this node.')) return null
    if (this.state.rollbar) this.state.rollbar.error(error)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render () {
    if (this.state.errorInfo) {
      return (
        <div>Sorry, something went wrong.</div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: Proptypes.object
}
