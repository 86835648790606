import React, { useCallback, useMemo, useContext } from 'react'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import useStyles from 'pages/customers/_styles/filterChips'

type Props = {
  k: string,
  label: string,
  onDelete: Function
}

const FilterChip = React.memo(({ k, label, onDelete }: Props) => (
  <Grid key={k} item>
    <Chip
      id={k}
      label={label}
      onDelete={() => onDelete(k)}
      color='primary'
    />
  </Grid>
))

const FilterChips = () => {
  const { dispatch, filterChips } = useContext(CustomersContext)
  const classes = useStyles()

  const onClear = useCallback(key => {
    dispatch({ type: 'CLEAR', key })
  }, [dispatch])

  const onDelete = useCallback(key => {
    dispatch({ type: 'DELETE', key })
  }, [dispatch])

  return useMemo(() => (
    <Grid spacing={1} container className={classes.container}>
      {Object.keys(filterChips).map(k => {
        if (typeof (filterChips[k]) === 'object') {
          return Object.keys(filterChips[k]).map(a => {
            if (!filterChips[k][a]) return null

            const onRemove = k === 'customerProperties' ? onDelete : onClear
            return <FilterChip key={a} onDelete={onRemove} label={filterChips[k][a]} k={`${k}:${a}`} />
          })
        }
        if (!filterChips[k]) return null
        return <FilterChip key={k} onDelete={onClear} label={filterChips[k]} k={k} />
      })}
    </Grid>
  ), [classes.container, filterChips, onClear, onDelete])
}

export default React.memo(FilterChips)
