import React, { useMemo, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

type Props = {
  onChange: Function,
  textValue: string,
  label: string,
  keyValue: string,
}

const CheckboxInput = ({ keyValue, label, onChange, textValue }: Props) => {
  const checked = useMemo(() => textValue === 'true', [textValue])
  const handleChange = useCallback(() => onChange(keyValue, String(!checked)), [checked, keyValue, onChange])

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            value='true'
            color='primary'
          />
        }
        label={label}
      />
    </Grid>
  )
}

export default React.memo(CheckboxInput)
