import { createConsumer } from '@rails/actioncable';
import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
const { REACT_APP_API, REACT_APP_WEBSOCKETS_URL } = process.env;

const useCable = () => {
  const { getAccessTokenSilently } = useAuth0();

  const actionCableConsumer = useMemo(async () => {
    const authToken = await getAccessTokenSilently();
    const apiUrl = REACT_APP_WEBSOCKETS_URL || `${REACT_APP_API.replace(/^http/, "ws").replace(/\/$/, "")}/cable`;
    const URL = `${apiUrl}?token=${authToken}`;
    console.log("Listening action cable on: ", URL);
    return createConsumer(URL);
  }, [getAccessTokenSilently]);

  return {
    actionCableConsumer
  };
};

export default useCable;
