import React, { useCallback, useRef, useContext, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import get from 'lodash/get'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.lightGray.main}`
  },
  chip: {
    height: 24,
    marginLeft: theme.spacing(),
    '& .MuiChip-label': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontSize: 14
    }
  },
  subject: {
    margin: theme.spacing(1, 0),
    width: '100%',
    padding: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  iconButton: {
    padding: theme.spacing(1 / 2)
  },
  attachmentContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const TemplateView = () => {
  const { handleEditTemplate, selectedTemplate, uploadTemplateFiles, handleDeleteAttachment } = useContext(CustomersContext)
  const parsed = useMemo(() => parse(selectedTemplate?.body), [selectedTemplate])
  const fileRef = useRef(null)

  const classes = useStyles()

  const addFile = useCallback(() => fileRef.current.click(), [])

  const attachments = useMemo(() => {
    return get(selectedTemplate, 'attachments', []).map(a => (
      <Grid item key={a.id} className={classes.attachmentContainer}>
        <Typography>{a.filename}</Typography>
        <IconButton aria-label='edit' onClick={handleDeleteAttachment(a.id)} className={classes.iconButton}>
          <CloseIcon />
        </IconButton>
      </Grid>
    ))
  }, [classes.attachmentContainer, classes.iconButton, handleDeleteAttachment, selectedTemplate])

  return (
    <Grid className={classes.root} container>
      <Grid item xs={10}>
        <Typography className={classes.subject}><b>{selectedTemplate?.subject || '(no subject)'}</b></Typography>
      </Grid>
      <Grid item xs={2} container alignItems='center' justifyContent='flex-end'>
        <input type='file' required multiple onChange={uploadTemplateFiles} className={classes.input} ref={fileRef} />
        <Tooltip title='Add Attachment' placement='top'>
          <IconButton aria-label='attachment' className={classes.iconButton} onClick={addFile}>
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Edit' placement='top'>
          <IconButton aria-label='edit' onClick={handleEditTemplate} className={classes.iconButton}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container>
        {attachments}
      </Grid>
      <Grid container>
        {parsed}
      </Grid>
    </Grid>
  )
}

export default TemplateView
