import React, { useMemo, useCallback, useContext } from "react";
import { INBOX_REPLY_STATUSES } from "constants/inboxReplyStatuses";
import useRouter from "hooks/useRouter";
import InboxContext from "contexts/InboxContext";
import { classNames } from "utils/misc";
import UserContext from "contexts/UserContext";

const MessageTypeFilter = ({ replyStatus }) => {
  const { navigate } = useRouter();

  const { setPage } = useContext(InboxContext);
  const { eventId } = useContext(UserContext);

  const onTabChange = useCallback((status) => {
    if (!status) return;

    setPage(1);
    navigate(status === "inbound" ? `/events/${eventId}/inbox` : `/events/${eventId}/messages/${status}`);
  }, [eventId, setPage, navigate]);

  return useMemo(
    () => (
      <div className="w-full">
        <div className="border-b border-gray-200 pb-0">
          <div className="mt-4">
            <nav className="-mb-px flex space-x-8">
              {INBOX_REPLY_STATUSES.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => onTabChange(tab.id)}
                  className={classNames(
                    tab.id === replyStatus
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                  )}
                  aria-current={tab.id === replyStatus ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    ),
    [
      onTabChange,
      replyStatus
    ]
  );
};

export default MessageTypeFilter;
