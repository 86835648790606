import React, { useMemo, useCallback, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RangeDate from 'pages/customers/filters/inputs/RangeDate'
import { formatDateDisplay, formatDateQuery } from 'utils/formatter'
import { lastPurchase } from 'constants/options'

const LastPurchase = () => {
  const {
    state: {
      lastPurchase: {
        option,
        from,
        to
      }
    },
    dispatch
  } = useContext(CustomersContext)

  const onChange = useCallback(e => {
    const v = e.target ? e.target.value : e
    if (!v) return
    dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'option', value: v } })
  }, [dispatch])

  const onChangeCustom = useCallback((newFrom, newTo) => {
    if (newFrom !== from) dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'from', value: formatDateQuery(newFrom) } })
    if (newTo !== to) dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'to', value: formatDateQuery(newTo) } })
  }, [dispatch, from, to])

  const renderOptions = useMemo(() => (
    Object.keys(lastPurchase).map(o => (
      <Grid
        key={o}
        container
      >
        <FormControlLabel
          value={o}
          control={<Radio />}
          label={lastPurchase[o].label}
        />
        {o === 'custom' && option === 'custom' && <RangeDate from={from} to={to} onChange={onChangeCustom} />}
      </Grid>
    ))
  ), [from, to, onChangeCustom, option])

  const selectedOption = useMemo(() => (option || 'all'), [option])

  const createChips = useCallback(() => {
    if (!option) return
    if (option !== 'custom') {
      const { chip } = lastPurchase[option]
      dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'chip', value: chip } })
    } else {
      const clause = from && to ? 'Between' : (from ? 'After' : (to ? 'Before' : ''))
      const division = from && to ? ' - ' : ''

      const chip = from || to ? `Most Recent Order Date: ${clause} ${formatDateDisplay(from) || ''}${division}${formatDateDisplay(to) || ''}` : ''
      dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'chip', value: chip } })
    }
  }, [dispatch, from, option, to])

  const getOptionRange = useCallback(() => {
    if (option && !['all', 'custom'].includes(option)) {
      const { from, to } = lastPurchase[option]
      dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'from', value: from } })
      dispatch({ type: 'LAST_PURCHASE_UPDATE', params: { key: 'to', value: to } })
    }
  }, [dispatch, option])

  useEffect(() => {
    createChips()
    getOptionRange()
  }, [createChips, getOptionRange])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <Grid item xs={12}>
        <RadioGroup value={selectedOption} onChange={onChange}>
          {renderOptions}
        </RadioGroup>
      </Grid>
    </Grid>
  ), [onChange, renderOptions, selectedOption])
}

export default LastPurchase
