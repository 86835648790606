import React, {
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useContext
} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import CustomersContext from 'contexts/CustomersContext'
import useStyles from 'pages/customers/_styles/broadcastDialog'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import FirstStep from 'pages/customers/broadcastDialog/FirstStep'
import SecondStep from 'pages/customers/broadcastDialog/SecondStep'
import ThirdStep from 'pages/customers/broadcastDialog/ThirdStep'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveDraft from 'pages/customers/broadcastDialog/SaveDraft'
import CreateTemplate from 'pages/customers/broadcastDialog/CreateTemplate'
import EditTemplate from 'pages/customers/broadcastDialog/EditTemplate'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const steps = [
  'Select a template',
  'Pick a date and time',
  'Confirm and send'
]

const BroadcastDialog = () => {
  const {
    broadcastDialog,
    broadcastLoading,
    sendTestEmail,
    closeBroadcastDialog,
    nextBroadcastStep,
    selectedTemplate,
    previousBroadcastStep,
    selectedCustomers,
    sendBroadcast,
    openBroadcastDraftDialog,
    newTemplate,
    broadcastStep,
    editTemplate
  } = useContext(CustomersContext)
  const classes = useStyles()
  const createTemplateRef = useRef(null)
  const editTemplateRef = useRef(null)

  const handleSave = useCallback(() => editTemplate ? editTemplateRef.current() : createTemplateRef.current(), [editTemplate])

  const customerCount = useMemo(() => Object.keys(selectedCustomers).length, [selectedCustomers])
  return useMemo(() => (
    <Dialog
      open={broadcastDialog}
      TransitionComponent={Transition}
      disableEscapeKeyDown={newTemplate || editTemplate}
      fullWidth
      onClose={closeBroadcastDialog}
      classes={{
        paper: classes.paper
      }}
      maxWidth='md'
    >
      <SaveDraft />
      <Grid container justifyContent='center'>
        <Stepper activeStep={broadcastStep} alternativeLabel className={classes.stepper}>
          {steps.map((label, index) => (
            <Step key={label} completed={broadcastStep > index}>
              <StepLabel className={classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container className={classes.main}>
        <CreateTemplate ref={createTemplateRef} />
        <EditTemplate ref={editTemplateRef} />
        <FirstStep />
        <SecondStep />
        <ThirdStep />
      </Grid>
      <DialogActions className={classes.actions}>
        <Grid>
          <Button
            onClick={closeBroadcastDialog}
            className={classes.previous}
            variant='outlined'
          >
            Cancel
          </Button>
          {selectedTemplate && !newTemplate && !editTemplate &&
            <Button
              onClick={openBroadcastDraftDialog}
              variant='outlined'
              color='primary'
            >
              Save Draft
            </Button>}
        </Grid>
        <Grid>
          {broadcastStep === 0 && selectedTemplate && !newTemplate && !editTemplate &&
            <Button
              className={classes.buttonTest}
              onClick={sendTestEmail}
              variant='outlined'
              color='primary'
            >
            Send test email
            </Button>}
          {broadcastStep > 0 &&
            <Button className={classes.previous} onClick={previousBroadcastStep} variant='outlined' color='primary'>
              Previous
            </Button>}
          {broadcastStep !== 2 && !newTemplate && !editTemplate &&
            <Button
              variant='contained'
              color='primary'
              disabled={!selectedTemplate}
              onClick={nextBroadcastStep}
            >
              Next
            </Button>}
          {(newTemplate || editTemplate) &&
            <Button
              variant='contained'
              color='primary'
              onClick={handleSave}
            >
              Save
            </Button>}
          {broadcastStep === 2 &&
            <Button onClick={sendBroadcast} variant='contained' color='primary' disabled={broadcastLoading || !selectedTemplate}>
              {broadcastLoading ? (
                <Grid container justifyContent='center'>
                  <CircularProgress size={30} />
                </Grid>
              ) : `Queue ${customerCount} email${customerCount === 1 ? '' : 's'}`}
            </Button>}
        </Grid>
      </DialogActions>
    </Dialog>
  ), [broadcastDialog, newTemplate, editTemplate, closeBroadcastDialog, classes.paper, classes.stepper, classes.main, classes.actions, classes.previous, classes.buttonTest, classes.stepLabel, broadcastStep, selectedTemplate, openBroadcastDraftDialog, sendTestEmail, previousBroadcastStep, nextBroadcastStep, handleSave, sendBroadcast, broadcastLoading, customerCount])
}

export default BroadcastDialog
