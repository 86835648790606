import React from "react";
import {
  Bars3BottomLeftIcon,
} from "@heroicons/react/24/outline";
import Breadcrumb from "components/Breadcrumb";
import useAuth from "hooks/useAuth";

const NewTopMenu = ({ pages, setMobileMenuOpen }) => {

  // We need to reference the useAuth hook here so that we make sure the fetchMe useEffect runs on
  // every page.
  useAuth();

  return (
    <header className="w-full sticky top-0 z-10">
      <div className="relative flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
        <button
          type="button"
          className="md:hidden border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between px-4 sm:px-6">
          <Breadcrumb pages={pages} />
          {/* TODO: Search Bar */}
          {/* <form className="flex w-full md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search all files
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              </div>
              <input
                name="search-field"
                id="search-field"
                className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                placeholder="Search"
                type="search"
              />
            </div>
          </form> */}
        </div>
      </div>
    </header>
  );
};

export default NewTopMenu;
