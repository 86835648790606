import queryString from 'query-string'

const { history, location } = window

function get () {
  return queryString.parse(location.search)
}

function set (paramsToSet, reload = false) {
  const paramsStr = queryString.stringify(paramsToSet)

  if (reload) {
    location.search = paramsStr
  } else {
    const { protocol, host, pathname } = location
    const newUrl = `${protocol}//${host}${pathname}?${paramsStr}`
    history.replaceState({ path: newUrl }, '', newUrl)
  }
}

export default {
  get,
  set
}
