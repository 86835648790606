import React, { Fragment, useEffect, useContext, useState, useCallback, useMemo, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import PeopleContext from "contexts/PeopleContext"
import { CUSTOMERS_NOT_IN_LIST } from "utils/queries"
import { ADD_CUSTOMER_TO_LIST } from "utils/mutations"
import useApi from "hooks/useApi"
import useRollbar from "hooks/useRollbar"
import { enqueueSnackbar } from "notistack"
import { getAbbreviation } from "utils/formatter"
import { classNames } from "utils/misc";

const AddPeopleToListDialog = ({ eventId, activeList, selectedListName, listsDialog, closeListsDialog }) => {
  const { getSearch } = useContext(PeopleContext)

  const { query, mutate } = useApi()
  const { captureError } = useRollbar()

  const checkbox = useRef()
  const [checked, setChecked] = useState(false)

  const [selectedPeople, setSelectedPeople] = useState({})
  const [selectAll, setSelectAll] = useState(false)

  const selectedPeopleArray = useMemo(() => Object.keys(selectedPeople), [selectedPeople])

  const [customerLists, setCustomerLists] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [search, setSearch] = useState("")

  useEffect(() => {
    fetchLists()
  }, [])

  const fetchLists = async () => {
    try {
      const queryString = CUSTOMERS_NOT_IN_LIST(activeList)
      const res = await query(queryString)

      const data = res?.data?.listCustomers?.customersNotInList ?? []

      setCustomerLists(data)
    } catch (e) {
      captureError(e)
    }
  }

  const escapeRegExp = value => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
  }

  const handleSearch = searchValue => {
    setSearch(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i")

    if (searchValue.length) {
      const filteredRows = customerLists.filter(row => {
        return searchRegex.test(row["fullName"].toString())
      })

      setFilteredData(filteredRows)
    } else {
      setFilteredData([])
    }
  }

  const toggleAll = useCallback(() => {
    setChecked(!checked)

    const ids = {}
    customerLists.forEach(c => {
      ids[c.id] = true
    })
    setSelectedPeople(checked ? {} : ids)
  }, [checked, customerLists, setSelectedPeople])

  const selectSinglePerson = id => {
    if (selectAll) setSelectAll(false)
    if (selectedPeople[id]) {
      const { [id]: removed, ...ids } = selectedPeople
      setSelectedPeople(ids)
    } else {
      const ids = { ...selectedPeople, [id]: true }
      setSelectedPeople(ids)
    }
  }

  const handleAddToListClick = async () => {
    setIsLoading(true)

    const params = `eventId: ${Number(eventId)}, listId: ${Number(activeList)}, customerIds: [${selectedPeopleArray}]`

    try {
      const res = await mutate(ADD_CUSTOMER_TO_LIST(params))
      if (res.data.addCustomersToList) {
        setIsLoading(false)

        getSearch()
        closeListsDialog()

        enqueueSnackbar("Customer added to list", {
          autoHideDuration: 3000,
          variant: "success"
        })
      }
    } catch (e) {
      setIsLoading(false)

      captureError(e)
      enqueueSnackbar("Failed to add to lists", {
        autoHideDuration: 3000,
        variant: "error"
      })
    }
  }

  return (
    <Transition.Root appear="true" show={listsDialog} as={Fragment}>
      <Dialog as="div" className="relative z-[1500]" onClose={closeListsDialog}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6 px-3 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Add people to {selectedListName}
                          </Dialog.Title>
                          <Dialog.Description className="mt-2">
                            The following people are not yet on this list.
                          </Dialog.Description>
                        </div>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={closeListsDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="my-4">
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                          <div className="pointer-events-none absolute p-2 inset-y-0 left-0 flex items-center">
                            <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          </div>
                          <input
                            type="text"
                            name="list"
                            id="list-search"
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                            className="block w-full rounded-full border-0 pl-8 pr-12 pr-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Search by first or last name"
                          />
                        </div>
                        <table className="mt-4 min-w-full table-fixed divide-y divide-gray-300">
                          <thead className="bg-[#fafafa] z-5">
                            <tr>
                              <th scope="col" className="relative sm:w-12 px-5 sm:px-6">
                                <input
                                  type="checkbox"
                                  className="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  ref={checkbox}
                                  checked={checked}
                                  onChange={toggleAll}
                                />
                              </th>
                              <th scope="col" className="py-3.5 pr-0 sm:pr-3 text-left text-sm font-semibold text-gray-900">
                                First Name
                              </th>
                              <th scope="col" className="px-0 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Last Name
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            { customerLists.length > 0 ? 
                              (search?.length ? filteredData : customerLists).map(person => (
                                <tr key={person.id} className={selectedPeople[person.id] ? "bg-gray-50" : undefined}>
                                  <td className="relative sm:w-12 px-5 sm:px-6">
                                    {selectedPeople[person.id] && (
                                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                    )}
                                    <input
                                      type="checkbox"
                                      className="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      value={person.id}
                                      checked={selectedPeople[person.id] || false}
                                      onChange={e => selectSinglePerson(person.id)}
                                    />
                                  </td>
                                  <td
                                    className={classNames(
                                      "whitespace-nowrap py-4 pr-0 sm:pr-3 text-sm",
                                      selectedPeople[person.id] ? "text-indigo-600" : "text-gray-900"
                                    )}
                                  >
                                    {person.firstname && person.firstname !== "" && (
                                      <span>{getAbbreviation(person.firstname, 15)}</span>
                                    )}
                                    {(!person.firstname || person.firstname === "") && (
                                      <span className="text-gray-300">(empty)</span>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-0 sm:px-3 py-4 text-sm text-gray-900 sm:p-0">
                                    {getAbbreviation(person.lastname, 15)}
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td className='text-center py-5' colSpan={3}>No people.</td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {selectedPeopleArray.length > 0 ? (
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="submit"
                          className={classNames(
                            "justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500",
                            isLoading ? "cursor-not-allowed" : ""
                          )}
                          onClick={handleAddToListClick}
                          disabled={isLoading}
                        >
                          Add to list
                        </button>
                      </div>
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default React.memo(AddPeopleToListDialog)
