import React, { useMemo, useCallback, useEffect, useContext } from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Subscriptions = () => {
  const {
    state: {
      subscriptions: {
        email,
        phone
      }
    },
    dispatch
  } = useContext(CustomersContext)

  const onChange = useCallback((key, value) => {
    dispatch({ type: 'SUBSCRIPTIONS_UPDATE', params: { key, value } })
  }, [dispatch])

  const createChip = useCallback(() => {
    const chips = {}
    if (email) {
      chips.email = email === 'true' ? 'Email: Subscribed' : 'Email: Unsubscribed'
    }
    if (phone) {
      chips.phone = phone === 'true' ? 'SMS: Subscribed' : 'SMS: Unsubscribed'
    }
    dispatch({ type: 'SUBSCRIPTIONS_UPDATE', params: { key: 'chips', value: chips } })
  }, [dispatch, email, phone])

  useEffect(() => {
    createChip()
  }, [createChip])

  const onChangeEmail = useCallback(e => {
    onChange('email', e.target.value)
  }, [onChange])

  const onChangePhone = useCallback(e => {
    onChange('phone', e.target.value)
  }, [onChange])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <Grid item xs={12}>
        <FormLabel component='legend'>Email</FormLabel>
        <RadioGroup id='emailSelect' value={email} onChange={onChangeEmail}>
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='Currently Subscribed'
          />
          <FormControlLabel
            value='false'
            control={<Radio />}
            label='Currently Unsubscribed'
          />
          <FormControlLabel
            value=''
            control={<Radio />}
            label='Either'
          />
        </RadioGroup>
        <FormLabel component='legend'>SMS</FormLabel>
        <RadioGroup id='phoneSelect' value={phone} onChange={onChangePhone}>
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='Currently Subscribed'
          />
          <FormControlLabel
            value='false'
            control={<Radio />}
            label='Currently Unsubscribed'
          />
          <FormControlLabel
            value=''
            control={<Radio />}
            label='Either'
          />
        </RadioGroup>
      </Grid>
    </Grid>
  ), [email, onChangeEmail, onChangePhone, phone])
}

export default Subscriptions
