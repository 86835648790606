import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { PEOPLE_LISTS } from 'utils/queries'
import useApi from 'hooks/useApi'
import useRollbar from 'hooks/useRollbar'
import { ADD_CUSTOMER_TO_LIST, REMOVE_CUSTOMER_FROM_LIST } from "utils/mutations"
import { enqueueSnackbar } from "notistack"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ListDialog = ({ eventId, customerId, peopleListsDialog, closePeopleListsDialog }) => {
  const { query, mutate } = useApi();
  const { captureError } = useRollbar();

  const [activeTab, setActiveTab] = useState('on');
  const [onLists, setOnLists] = useState([]);
  const [notLists, setNotLists] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchLists();
  }, [customerId]);

  const fetchLists = async () => {
    try {
      const queryString = PEOPLE_LISTS(customerId);
      const res = await query(queryString);

      const customerLists = res.data.customerLists;

      setOnLists(customerLists.listsWhereCustomerIsMember);
      setNotLists(customerLists.listsWhereNotCustomerIsMember);
    } catch (e) {
      captureError(e);
    }
  }

  const escapeRegExp = value => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  const handleSearch = (searchValue, tab) => {
    setSearch(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')

    if (searchValue.length) {
      const filteredRows = (tab === 'on' ? onLists : notLists).filter(row => {
        return searchRegex.test(row['name'].toString())
      })

      setFilteredData(filteredRows)
    } else {
      setFilteredData([])
    }
  }

  const removePersonFromList = async (listId) => {
    const params = `eventId: ${Number(eventId)}, listId: ${Number(listId)}, customerIds: [${customerId}]`;

    try {
      const res = await mutate(REMOVE_CUSTOMER_FROM_LIST(params));
      if (res.data.removeCustomersFromList) {
        // fetchLists();

        enqueueSnackbar("Customer removed from list", {
          autoHideDuration: 3000,
          variant: "success",
        });
      }
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to remove from list", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  }

  const addPersonToList = async (listId) => {
    const params = `eventId: ${Number(eventId)}, listId: ${Number(listId)}, customerIds: [${customerId}]`;

    try {
      const res = await mutate(ADD_CUSTOMER_TO_LIST(params));
      if (res.data.addCustomersToList) {
        // fetchLists();
        
        enqueueSnackbar("Customer added to list", {
          autoHideDuration: 3000,
          variant: "success",
        });
      }
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to add to lists", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  }

  const handleListsAction = (listId, action) => {
    if (action == 'remove') {
      removePersonFromList(listId);

      setOnLists(prevOnLists => prevOnLists.filter(item => item.id !== listId));
      setNotLists(prevNotLists => [...prevNotLists, onLists.find(item => item.id === listId)]);
    } else {
      addPersonToList(listId);
      
      setNotLists(prevNotLists => prevNotLists.filter(item => item.id !== listId));
      setOnLists(prevOnLists => [...prevOnLists, notLists.find(item => item.id === listId)]);
    }
  }  

  return (
    <Transition.Root appear="true" show={peopleListsDialog} as={Fragment}>
      <Dialog as="div" className="relative z-[1500]" onClose={closePeopleListsDialog}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Lists
                          </Dialog.Title>
                          <Dialog.Description className="mt-2">
                            Select from the tabs below to see which lists this person is on and not on.
                          </Dialog.Description>
                        </div>
                        
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={closePeopleListsDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200 mt-4">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          <a
                            key='on'
                            href='#'
                            onClick={() => setActiveTab('on')}
                            className={classNames(
                              activeTab == 'on'
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium'
                            )}
                          >
                            On
                          </a>
                          <a
                            key='not'
                            href='#'
                            onClick={() => setActiveTab('not')}
                            className={classNames(
                              activeTab == 'not'
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium'
                            )}
                          >
                            Not On
                          </a>
                        </nav>
                      </div>
                    </div>
                    <div className='mx-6 my-4'>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="pointer-events-none absolute p-2 inset-y-0 left-0 flex items-center">
                          <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        </div>
                        <input
                          type="text"
                          name="list"
                          id="list-search"
                          value={search}
                          onChange={e => handleSearch(e.target.value, activeTab)}
                          className="block w-full rounded-full border-0 pl-8 pr-12 pr-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Search lists"
                        />
                      </div>
                    </div>
                    <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {(activeTab === 'on' ? (search?.length ? filteredData : onLists) : (search?.length ? filteredData : notLists)).map(list => (
                        <li key={list.id}>
                          <div className="group relative flex items-center px-5 py-4">
                            <a href="#" className="-m-1 block flex-1 p-1">
                              <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
                              <div className="relative flex min-w-0 flex-1 items-center ml-3">
                                <p className="text-sm font-bold text-gray-900">{list.name}</p>
                              </div>
                            </a>
                            {list.name !== "All" && (
                              <Menu as="div" className="relative ml-2 inline-block flex-shrink-0 text-left">
                                <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                  <span className="absolute -inset-1.5" />
                                  <span className="sr-only">Open options menu</span>
                                  <span className="flex h-full w-full items-center justify-center rounded-full">
                                    <EllipsisVerticalIcon
                                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-9 top-0 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                              'block px-4 py-2 text-sm'
                                            )}
                                            onClick={() => handleListsAction(list.id, activeTab === 'on' ? 'remove' : 'add')}
                                          >
                                            {activeTab === 'on' ? 'Remove from list' : 'Add to list'}
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default React.memo(ListDialog)