import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import TextInput from 'pages/customers/filters/inputs/TextInput'
import DateInput from 'pages/customers/filters/inputs/DateInput'
import CheckboxInput from 'pages/customers/filters/inputs/CheckboxInput'
import { formatDateDisplay, formatDateQuery } from 'utils/formatter'

const EmailHistory = () => {
  const {
    state: {
      emailHistory
    },
    dispatch
  } = useContext(CustomersContext)

  const {
    efrom,
    eto,
    subject,
    body,
    from,
    to,
    opened
  } = emailHistory

  const [curEmailHistory, setCurEmailHistory] = useState('')

  const createChips = useCallback(() => {
    const chips = {}
    if (efrom) chips.efrom = `Email From: ${efrom}`
    if (eto) chips.eto = `Email To: ${eto}`
    if (subject) chips.subject = `Email Subject: ${subject}`
    if (body) chips.body = `Email Body: ${body}`
    if (from) chips.from = `Email Sent Date From: ${formatDateDisplay(from)}`
    if (to) chips.to = `Email Sent Date To: ${formatDateDisplay(to)}`
    if (opened) chips.opened = 'Email Opened'

    dispatch({ type: 'EMAIL_HISTORY_UPDATE', params: { key: 'chips', value: chips } })
  }, [body, dispatch, efrom, eto, from, opened, subject, to])

  const onChange = useCallback((key, value) => {
    if (['from', 'to'].includes(key)) return dispatch({ type: 'EMAIL_HISTORY_UPDATE', params: { key, value: formatDateQuery(value) } })
    if (key === 'opened') return dispatch({ type: 'EMAIL_HISTORY_UPDATE', params: { key, value: value === 'true' ? 'true' : '' } })
    dispatch({ type: 'EMAIL_HISTORY_UPDATE', params: { key, value } })
  }, [dispatch])

  useEffect(() => {
    const emailHistoryString = JSON.stringify(emailHistory)
    if (JSON.stringify(emailHistory) !== curEmailHistory) {
      setCurEmailHistory(emailHistoryString)
      createChips()
    }
  }, [createChips, curEmailHistory, emailHistory])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <TextInput label='From' textValue={efrom} keyValue='efrom' onChange={onChange} />
      <TextInput label='To' textValue={eto} keyValue='eto' onChange={onChange} />
      <TextInput label='Subject' textValue={subject} keyValue='subject' onChange={onChange} />
      <TextInput label='Body' textValue={body} keyValue='body' onChange={onChange} />
      <DateInput label='Sent Date From' date={from} keyValue='from' onChange={onChange} />
      <DateInput label='Sent Date To' date={to} keyValue='to' onChange={onChange} />
      <CheckboxInput label='was opened' keyValue='opened' textValue={opened} onChange={onChange} />
    </Grid>
  ), [body, efrom, eto, from, onChange, opened, subject, to])
}

export default EmailHistory
