import React from 'react'
import MessageTypeFilter from "components/inbox/MessageTypeFilter";

const Filters = ({ replyStatus }) => {

  return (
    <div className="mt-6">
      <MessageTypeFilter replyStatus={replyStatus} />
    </div>
  );
}

export default Filters;
