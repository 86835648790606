import { useMemo } from 'react'
import moment from 'moment'
import { currency } from 'utils/formatter'

const useCustomerColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Checkbox',
        id: 'checkbox'
      },
      {
        Header: 'Name',
        accessor: 'fullName'
      },
      // {
      //   Header: 'First Name',
      //   accessor: 'firstname'
      // },
      // {
      //   Header: 'Last Name',
      //   accessor: 'lastname'
      // },
      {
        Header: 'Lifetime Spend',
        id: 'lifetime_spend',
        accessor: d => (currency(d.lifetimeSpend))
      },
      {
        Header: 'Last Contacted',
        id: 'last_contacted',
        defaultCanSort: true,
        accessor: d => {
          const date = moment(d.lastContacted)
          if (!date._isValid) return ''
          return date
            .local()
            .format('M/D/YY')
        }
      },
      {
        Header: 'No. of orders',
        id: 'orders_count',
        accessor: d => d.ordersCount
      },
      {
        Header: 'Recent Order',
        id: 'last_order_created_at',
        accessor: d => {
          const date = moment(d.lastOrderCreatedAt)
          if (!date._isValid) return ''
          return date
            .local()
            .format('M/D/YY')
        }
      }
    ],
    []
  )

  return {
    columns
  }
}

export default useCustomerColumns
