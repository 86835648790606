import React, { Fragment, useCallback, useContext, useMemo, useState, useEffect, useRef } from "react";
import { formatDateWithTimezone } from "utils/formatter";
import ImagePreviewModal from "components/ImagePreviewModal";
import { Menu } from "@headlessui/react";
import { ChevronUpIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { classNames } from "utils/misc";
import useRouter from "hooks/useRouter";
import UserContext from "contexts/UserContext";
import PersonViewDialog from "./person/PersonViewDialog";
import { Tooltip } from "react-tooltip";

const MessageHistory = ({ messages, fetchMessages, page, perPage, totalCount, onNextPage, customerLinkEnabled = false, loading, invertOrder = false, menuActions = [], replyStatus = 'inbound', unassignMessageFromAssociate }) => {  
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imagePreviewName, setImagePreviewName] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const { eventId } = useContext(UserContext);
  const [personViewOpen, setPersonViewOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);

  const [showGoToTop, setShowGoToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { innerHeight } = window;
      const { scrollHeight, scrollTop } = document.documentElement;
      
      const isPastThreshold = scrollTop > (invertOrder ? 120 : 360);
      
      setShowGoToTop(scrollHeight > innerHeight && isPastThreshold);
    };    

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const { navigate } = useRouter();

  const sortedMessages = useMemo(() => {
    if (!invertOrder) return messages;

    return messages.reduce((acc, message) => {
      if (!acc.some(m => m.id === message.id)) {
          acc.push(message);
      }
      return acc;
    }, []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  }, [messages, invertOrder]);

  const onPreviewOpen = useCallback((attachment)=> {
    setImagePreviewName(attachment.filename);
    setImagePreviewUrl(attachment.fileUrl);
    setPreviewOpen(true);
  }, []);
  const onPreviewClose = useCallback(()=> setPreviewOpen(false), []);

  const canGoToNextPage = totalCount > perPage * page;
  const hasMenuActions = useMemo(() => menuActions && menuActions.length > 0, [menuActions]);

  const onCardClicked = useCallback((e, message) => {
    // We don't want the card click to navigate to the person page when clicked in the menu actions area.
    // We want to open the menu actions dropdown instead.
    if (e.target.closest(".menu-actions") || e.target.closest(".associate-badge")) return;

    if (customerLinkEnabled && message.customer?.id) {
      setCustomerId(message.customer.id);
      setPersonViewOpen(true);
      // navigate(`/events/${eventId}/person/${message.customer.id}`);
    }
  }, [customerLinkEnabled, navigate, eventId]);

  const handleNextPage = async () => {
    await onNextPage();

    if (invertOrder) scrollToTop()
  }

  const formatMessageBody = (bodyText) => {
    if (!bodyText) return '';

    // const urlRegex = /(?:https:\/\/|www\.)\S+(?=\s|$)/g;
    const urlRegex = /(?:https:\/\/|http:\/\/|www\.)[^\s<]+/g;
    
    return bodyText.replace(urlRegex, (match) => {
      return `<a href="${match}" target="_blank" class="text-blue-500 underline">${match}</a>`;
    });
  }

  const handleRemoveAssociate = async (msgId) => {
    await unassignMessageFromAssociate(msgId, replyStatus)
  }

  return (
    <Fragment>
      <ImagePreviewModal
        name={imagePreviewName}
        url={imagePreviewUrl}
        open={previewOpen}
        onClose={onPreviewClose} />

      <div className="mt-6 flow-root">
        <div className="sm:rounded-lg">
          <div className="border-b border-gray-200 bg-[#fafafa] sticky top-[64px] z-5">
            {/* <div className="px-4 py-5 sm:px-6">
              <h2 className="text-lg font-medium leading-6 text-gray-900 w-auto">Messages</h2>
            </div> */}
            {showGoToTop && <div className="absolute top-12 w-full justify-center flex">
            <button className="rounded-full border p-2 bg-white border-indigo-600" onClick={scrollToTop}><ChevronUpIcon className="w-4 h-4 text-indigo-600"/></button>
            </div>}
          </div>
          <ul className="divide-y bg-white divide-gray-200 border-box sm:border-x sm:border-b">
            { canGoToNextPage && invertOrder &&
              <li className="py-5 text-center font-semibold cursor-pointer" onClick={handleNextPage}>Show earlier</li>
            }
            { !loading && sortedMessages.length == 0 &&
              <li className="py-5 text-center">No messages.</li>
            }
            {sortedMessages.map((message) => (
              <li className={`message-card ${message.highlight ? "animate-highlight" : ""} ${customerLinkEnabled && message.customer?.id ? "hover:cursor-pointer" : ""}`} key={message.id} onClick={(e) => onCardClicked(e, message)}>
                <div className="px-4 pt-4 pb-2 sm:px-6">
                  <div className="flex space-x-3">
                    <span className="text-sm flex-1 font-semibold text-gray-900">
                      {replyStatus === "sent" ? message.toName : message.fromName}
                    </span>
                    <p className="text-sm text-gray-500">
                      {formatDateWithTimezone(message.createdAt)}
                    </p>
                    {/* Menu Actions */}
                    { hasMenuActions &&
                      <div className="menu-actions flex flex-shrink-0 self-center">
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              { menuActions.map((menuAction) => (
                                <Menu.Item key={menuAction.label}>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        menuAction.action(e, message.id, message.assignedToAssociate?.id);
                                        if(menuAction.id === 1) fetchMessages();
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'flex px-4 py-2 text-sm'
                                      )}
                                    >
                                      <span>{menuAction.label}</span>
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Menu>
                      </div>
                    }
                  </div>
                </div>
                <div className="px-4 pb-4 sm:p-4 sm:px-6 sm:pt-0">
                  <div className="flex flex-wrap">
                    { (message.attachments.concat(message.templateAttachments)).map((attachment) => (
                      <div key={attachment.id} className="mr-2 mb-2">
                        <img
                          className="max-w-[200px] max-h-[200px] hover:cursor-pointer"
                          src={attachment.fileUrl}
                          alt={attachment.filename}
                          onClick={() => onPreviewOpen(attachment)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="sm:flex">
                    <div className="text-sm break-normal">
                      { message.subject &&
                        <div className="mb-6">
                          <span dangerouslySetInnerHTML={{__html: message.subject}} />
                        </div>
                      }
                      {/* {message.body && <span dangerouslySetInnerHTML={{__html: message.formattedBody}} />} */}
                      {message.body && <span dangerouslySetInnerHTML={{__html: formatMessageBody(message.formattedBody) }} />}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    { message.state === "c" &&
                      <span className={classNames(message.status === "undelivered" ? 'text-red-600' : 'text-gray-600', 'text-sm')}>
                        { message.status === "undelivered" ? "not delivered" : message.status }
                      </span>
                    }
                  </div>
                  { message.assignedToAssociate && (
                    <>
                      <Tooltip anchorSelect="#associate-badge-tooltip">Remove assignment</Tooltip>
                      <div className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 associate-badge mt-2">
                        {message.assignedToAssociate.firstname} {message.assignedToAssociate.lastname}
                        <button type="button" className="group relative ml-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20" onClick={() => handleRemoveAssociate(message.id)} id="associate-badge-tooltip">
                          <span className="sr-only">Remove</span>
                          <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                            <path d="M4 4l6 6m0-6l-6 6" />
                          </svg>
                          <span className="absolute -inset-1" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </li>
            ))}
            { canGoToNextPage && !invertOrder &&
              <li className="py-5 text-center font-semibold cursor-pointer" onClick={handleNextPage}>Show earlier</li>
            }
          </ul>
        </div>
      </div>

      { personViewOpen && <PersonViewDialog open={personViewOpen} setOpen={setPersonViewOpen} eventId={eventId} id={customerId} /> }
    </Fragment>
  );
};

export default MessageHistory;
