import useStyles from 'pages/customers/_styles/customerProfile'
import React, { useMemo, useContext, useCallback, useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import CustomersContext from 'contexts/CustomersContext'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import InPlaceEdit from '../../../components/InPlaceEdit'
import useRollbar from '../../../hooks/useRollbar'
import useApi from '../../../hooks/useApi'
import { useSnackbar } from 'notistack'
import { UPDATE_CUSTOMER_EMAIL, UPDATE_CUSTOMER_PHONE } from '../../../utils/mutations'
import useRouter from 'hooks/useRouter';
import UserContext from "contexts/UserContext"

const CustomerProfile = () => {
  const { navigate } = useRouter();
  const classes = useStyles()
  const { clickedCustomer, setClickedCustomer, setOpenFilters } = useContext(CustomersContext)
  const { eventId } = useContext(UserContext)
  const original = clickedCustomer.original || {}
  const { id, initials, fullName } = original

  const [formattedPhone, setFormattedPhone] = useState(original.formattedPhone || '')
  const [phone, setPhone] = useState(original.phone || '')
  const [email, setEmail] = useState(original.email || '')
  const { captureError } = useRollbar()
  const { enqueueSnackbar } = useSnackbar()
  const { mutate } = useApi()

  useEffect(() => {
    if (!clickedCustomer.original) { return }
    setPhone(clickedCustomer.original.phone || '')
    if (clickedCustomer.original.phone !== phone) {
      setEmail(clickedCustomer.original.email ? clickedCustomer.original.email.toLowerCase() : '')
    }
    setFormattedPhone(clickedCustomer.original.formattedPhone)
  }, [clickedCustomer, email, phone])

  const savePhoneInPlace = useCallback(async (phone) => {
    if (phone === clickedCustomer.original.phone) { return }
    if (isValidPhoneNumber(phone)) {
      try {
        const cust = await mutate(UPDATE_CUSTOMER_PHONE, { variables: { eventId: Number(eventId), id: Number(id), phone: phone } })
        if (cust.message) { throw (cust.message) }
        setFormattedPhone(cust.data.updateCustomer.formattedPhone)
        clickedCustomer.original.formattedPhone = cust.data.updateCustomer.formattedPhone
        clickedCustomer.original.phone = cust.data.updateCustomer.phone
        enqueueSnackbar(
          'Updated Phone', {
            autoHideDuration: 3000,
            variant: 'success'
          })
      } catch (error) {
        enqueueSnackbar(cleanError(error), {
          autoHideDuration: 3000,
          variant: 'error'
        })
        captureError(error)
      }
    } else {
      setPhone(clickedCustomer.original.phone)
      enqueueSnackbar(
        'Invalid Phone number', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [eventId, captureError, clickedCustomer, enqueueSnackbar, id, mutate])

  const cleanError = (error) => {
    console.log(error)
    return error.replace('GraphQL error:', '')
  }

  const saveMailInPlace = useCallback(async (email) => {
    if (email === clickedCustomer.original.email) { return }
    if (isValidEmail(email)) {
      try {
        const cust = await mutate(UPDATE_CUSTOMER_EMAIL, { variables: { eventId: Number(eventId), id: Number(id), email: email } })
        if (cust.message) {
          throw (cust.message)
        }
        setEmail(cust.data.updateCustomer.email)
        clickedCustomer.original.email = cust.data.updateCustomer.email
        enqueueSnackbar(
          'Updated Mail', {
            autoHideDuration: 3000,
            variant: 'success'
          })
      } catch (error) {
        enqueueSnackbar(cleanError(error)
          , {
            autoHideDuration: 3000,
            variant: 'error'
          })
        captureError(error)
      }
    } else {
      setEmail(clickedCustomer.original.email)
      enqueueSnackbar(
        'Invalid Mail', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [captureError, clickedCustomer, enqueueSnackbar, id, mutate])

  const isValidEmail = (email) => {
    if (email === '') { return true }
    if (!email.match(/@/)) { return false }
    return true
  }

  const isValidPhoneNumber = (phone) => {
    if (phone.match(/[a-z,A-Z]/)) { return false }
    if (phone === '') { return true }
    if (phone.match(/\d/)) { return true }
    return false
  }

  const handleClick = useCallback(() => {
    navigate(`/events/${eventId}/person/${id}`);
  }, [navigate, id, eventId])

  const onClose = useCallback(() => {
    setClickedCustomer({})
    setOpenFilters(true)
  }, [setClickedCustomer, setOpenFilters])

  return useMemo(() => (
    <div>
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
      >
        <CloseIcon style={{ marginRight: '20px' }} onClick={onClose} />
      </Grid>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
      >
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          direction='column'
        >
          <Avatar className={classes.avatar}>{initials}</Avatar>
          <br />
          <Typography className={classes.name}>{fullName}</Typography>
          <div className={classes.infoContainer}>
            <FormLabel component='legend'>Email</FormLabel>
            <InPlaceEdit text={email} onFocusOut={saveMailInPlace} />
            <br />
            <FormLabel component='legend'>Phone</FormLabel>
            <InPlaceEdit text={phone} formattedLabel={formattedPhone} onFocusOut={savePhoneInPlace} />
            <br />
          </div>
          <Button onClick={handleClick}>
            View full profile
          </Button>
        </Grid>
      </Grid>
    </div>
  ), [classes.avatar, classes.infoContainer, classes.name, email, formattedPhone, fullName, handleClick, initials, onClose, phone, saveMailInPlace, savePhoneInPlace])
}

export default CustomerProfile
