import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  overflow: {
    height: 'calc(100vh - 280px)',
    overflow: 'auto'
  },
  overflowSelected: {
    height: 'calc(100vh - 330px)',
    overflow: 'auto'
  },
  head: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.white,
    fontSize: 18
  },
  root: {
    '&:nth-of-ty/pe(odd)': {
      backgroundColor: theme.palette.background.default
    },
    '&$selected': {
      backgroundColor: '#cccccc38 !important',
      border: '2px !important'
    }
  },
  selected: {},
  body: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal'
  },
  checkbox: {
    padding: '0 !important',
    textAlign: 'center !important'
  },
  toggleMenu: {
    display: 'flex'
  },
  pointer: {
    cursor: 'pointer'
  },
  currency: {
    textAlign: 'right !important'
  },
  center: {
    textAlign: 'center'
  }
}))

export default useStyles
