import React, { forwardRef, useEffect, useContext, useState, useMemo, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomersContext from 'contexts/CustomersContext'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import useStyles from 'pages/customers/_styles/contactListDialog'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Fuse from 'fuse.js'
import Slide from '@material-ui/core/Slide'
import Checkbox from '@material-ui/core/Checkbox'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ReassignListDialog = ({ eventId }) => {
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState([])
  const {
    selectedReassignDialog,
    closeReassignDialog,
    selectedCustomers,
    fetchEventAssociates,
    selectedAssociate,
    selectedAssociateList,
    setSelectedAssociate,
    setSelectedAssociateName,
    reassignCustomers,
    setNotifyAssociate,
    notifyAssociate
  } = useContext(CustomersContext)
  const classes = useStyles()

  useEffect(() => {
    if (!selectedAssociateList) fetchEventAssociates(eventId)
  }, [fetchEventAssociates, selectedAssociateList])

  useEffect(() => {
    if (!selectedAssociateList) return
    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'fullname'
      ]
    }
    const fuse = new Fuse(selectedAssociateList, options)
    if (search) {
      const results = fuse.search(search)
      setFiltered(results.map(i => ({
        id: i.item.id,
        fullname: i.item.fullname
      })))
    } else setFiltered(selectedAssociateList)
  }, [search, selectedAssociateList])

  const selectAssociate = useCallback((associate) => {
    setSelectedAssociate(associate.target.value)
    setSelectedAssociateName(associate.target.labels[0].textContent)
  }, [setSelectedAssociate, setSelectedAssociateName])

  return useMemo(() => (
    <Dialog
      open={selectedReassignDialog}
      TransitionComponent={Transition}
      fullWidth
      onClose={closeReassignDialog}
      classes={{
        paper: classes.paper
      }}
      maxWidth='sm'
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography component='h2' variant='h6'>
            Reassign {Object.keys(selectedCustomers).length} customers to
        </Typography>
      </DialogTitle>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder='Search Associate'
          onChange={e => setSearch(e.target.value)}
          value={search}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
        />
      </div>
      <DialogContent>
        <RadioGroup value={selectedAssociate} onChange={e => selectAssociate(e)}>
          {filtered.map(cl =>
            <FormControlLabel
              value={cl.id}
              key={cl.id}
              control={<Radio color='primary' />}
              label={cl.fullname}
            />)}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={notifyAssociate}
              onChange={e => setNotifyAssociate(e.target.checked)}
              color='primary'
            />
          }
          label='Notify Associate'
        />
        <Button onClick={closeReassignDialog}>
            Cancel
        </Button>
        <Button onClick={reassignCustomers} variant='contained' color='primary'>
            Reassign
        </Button>
      </DialogActions>
    </Dialog>
  ), [classes.dialogTitle, classes.inputInput, classes.inputRoot, classes.paper, classes.search, classes.searchIcon, closeReassignDialog, filtered, notifyAssociate, reassignCustomers, search, selectAssociate, selectedAssociate, selectedCustomers, selectedReassignDialog, setNotifyAssociate])
}

export default ReassignListDialog
