import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF'
  },
  pageTitle: {
    ...theme.typography.pageTitle
  },
  titleGrid: {
  },
  header: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}))
