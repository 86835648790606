import React, { useMemo, useCallback, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RangeNumber from 'pages/customers/filters/inputs/RangeNumber'
import { purchaseCount } from 'constants/options'

const PurchaseCount = () => {
  const {
    state: {
      purchaseCount: {
        option,
        from,
        to
      }
    },
    dispatch
  } = useContext(CustomersContext)

  const onChange = useCallback(e => {
    const v = e.target ? e.target.value : e
    if (v) {
      dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'option', value: v } })
    }
  }, [dispatch])

  const onChangeCustom = useCallback((newFrom, newTo) => {
    if (newFrom !== from) dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'from', value: newFrom } })
    if (newTo !== to) dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'to', value: newTo } })
  }, [dispatch, from, to])

  const createChip = useCallback(() => {
    if (!option) return
    if (option === 'custom') {
      const clause = from && to ? 'Between' : (from ? 'More than' : (to ? 'Less than' : ''))
      const division = from && to ? ' - ' : ''
      const chip = from || to ? `Number of Orders: ${clause} ${from || ''}${division}${to || ''}` : ''
      dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'chip', value: chip } })
    } else {
      const { chip } = purchaseCount[option]
      dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'chip', value: chip } })
    }
  }, [dispatch, from, option, to])

  const getOptionRange = useCallback(() => {
    if (option && !['all', 'custom'].includes(option)) {
      const { from, to } = purchaseCount[option]
      dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'from', value: from } })
      dispatch({ type: 'PURCHASE_COUNT_UPDATE', params: { key: 'to', value: to } })
    }
  }, [dispatch, option])

  useEffect(() => {
    createChip()
    getOptionRange()
  }, [createChip, getOptionRange, onChange, option])

  const renderOptions = useMemo(() => (
    Object.keys(purchaseCount).map(o => (
      <Grid
        key={o}
        container
      >
        <FormControlLabel
          value={o}
          control={<Radio />}
          label={purchaseCount[o].label}
        />
        {o === 'custom' && option === 'custom' &&
          <RangeNumber
            filterKey='purchaseCount'
            fromLabel='From'
            toLabel='To'
            from={from}
            to={to}
            onChange={onChangeCustom}
          />}
      </Grid>
    ))
  ), [from, to, onChangeCustom, option])

  const selectedOption = useMemo(() => option || 'all', [option])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <Grid item xs={12}>
        <RadioGroup id='purchaseCountSelect' value={selectedOption} onChange={onChange}>
          {renderOptions}
        </RadioGroup>
      </Grid>
    </Grid>
  ), [onChange, renderOptions, selectedOption])
}

export default PurchaseCount
