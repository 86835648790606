import React from 'react'
import ListFilter from 'components/people/ListFilter';

const Filters = () => {

  return (
    <div className="mt-6">
      <ListFilter />
    </div>
  );
}

export default Filters;
