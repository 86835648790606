import { useCallback, useContext, useState } from "react";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { GET_CUSTOMERS_REPORT } from "utils/queries";

const useReports = () => {
  const { captureError } = useRollbar();
  const { query } = useApi();

  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([
    { id: 1, title: "No phone", filter: "PHONE_NOT_PRESENT", count: 0, route: "no_phone", description: "The following people have no phone number and can't receive text messages. Edit to add a phone number and subscribe them to text messages from the person's profile."},
    { id: 2, title: "Invalid", filter: "INVALID_PHONE_NUMBER", count: 0, route: "invalid", description: "Carriers reported the following phone numbers are invalid. Edit the phone number on the person's profile."},
    { id: 3, title: "Landline", filter: "NON_MOBILE_PHONE", count: 0, route: "landline", description: "Carriers reported the following phone numbers were landline or VoIP phones that may not be capable of receiving text messages. Edit the phone number on the person's profile."},
    { id: 4, title: "Not yet subscribed", filter: "NOT_YET_SUBSCRIBED", count: 0, route: "not_yet_subscribed", description: "The following people sent at least one inbound message but are not subscribed and won't receive scheduled broacast messages. Edit to subscribe them."},
    { id: 5, title: "Unsubscribed", filter: "UNSUBSCRIBED", count: 0, route: "unsubscribed", description: "The following people were unsubscribed from text messages. If you need to re-subscribe someone, please contact <a href='mailto:service@conciergeteam.co'>service@conciergeteam.co</a>."},
  ]);
  const [customers, setCustomers] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const fetchCustomersReport = useCallback(async (eventId, tabId) => {
    try {
      setLoading(true);
      const tab = tabs.find((item) => item.id == tabId);
      
      const res = await query(GET_CUSTOMERS_REPORT({ eventId, filter: tab.filter }));
      const data = res?.data?.customerReport;
      
      setTabs((prev) => 
        prev.map((item) => {
          switch (item.id) {
            case 1:
              return { ...item, count: data.phoneNotPresentCount };
            case 2:
              return { ...item, count: data.invalidPhoneNumberCount };
            case 3:
              return { ...item, count: data.nonMobilePhoneCount };
            case 4:
              return { ...item, count: data.notYetSubscribedCount };
            case 5:
              return { ...item, count: data.unsubscribedCount };
            default:
              return item;
          }
        })
      );  
      
      setCustomers(data.customers);
      setActiveTab(tab);
    } catch (e) {
      captureError(e);
      console.log("Failed to load customer reports", e);
    }

    setLoading(false);
  }, [captureError, query]);

  return {
    loading,
    tabs,
    customers,
    activeTab,
    fetchCustomersReport
  };
};

export default useReports;
