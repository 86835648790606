import React from 'react'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

type Props = {
  onChange: Function,
  date: string,
  keyValue: string,
  label: string,
}

const DateInput = ({ label, onChange, date, keyValue }: Props) => {
  const currentDate = date ? moment(date) : null

  return (
    <Grid item xs={12}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label={label}
          variant='inline'
          format='M/d/yy'
          fullWidth
          value={currentDate}
          onChange={v => onChange(keyValue, v)}
          InputProps={{
            startAdornment: <InputAdornment position='start'><div /></InputAdornment>
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )
}

export default React.memo(DateInput)
