import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.lightGray.main}`
  },
  datePicker: {
    '& .MuiPickersBasePicker-pickerView': {
      minHeight: 280
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: 172
    }
  },
  timeDivisor: {
    margin: theme.spacing(0, 1, 2, 1),
    fontSize: 20,
    fontWeight: 'bold'
  },
  timeInput: {
    margin: theme.spacing(1, 0, 2, 0),
    '& .MuiOutlinedInput-input': {
      fontSize: 20,
      padding: theme.spacing(),
      textAlign: 'center',
      fontWeight: 'bold'
    }
  },
  selectInput: {
    margin: theme.spacing(1, 0, 2, 0),
    '& .MuiOutlinedInput-input': {
      fontSize: 20,
      padding: theme.spacing(1.5, 4, 0.5, 1),
      textAlign: 'center',
      fontWeight: 'bold'
    }
  },
  options: {
    marginLeft: theme.spacing(2)
  },
  shortcuts: {
    margin: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.midGray.main
  },
  button: {
    margin: theme.spacing(),
    justifyContent: 'inherit'
  },
  timezoneContainer: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginBottom: 10,
    flexDirection: 'column'
  },
  timezoneTitle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  timezone: {
    color: theme.palette.midGray.main,
    fontSize: 12
  },
  hidden: {
    display: 'none'
  }
}))
