import React, { useContext, useEffect } from "react";
import MainLayout from "components/MainLayout";
import PersonForm from "components/person/PersonForm";
import UserContext from "contexts/UserContext";

const NewPerson = ({ eventId }) => {
  const { setEventId, currentEventName } = useContext(UserContext);

  const pages = [
    { name: currentEventName, href: `/events/${eventId}/people`, current: false },
    { name: 'New Person', href: `/events/${eventId}/person/new`, current: true }
  ];

  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  return (
    <MainLayout pages={pages}>
      <div className="w-full max-w-700">
        <PersonForm eventId={eventId} />
      </div>
    </MainLayout>
  )
};

export default NewPerson;
