import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from 'pages/customers/filters/inputs/_styles/selectInput'
import CircularProgress from '@material-ui/core/CircularProgress'
import { underlineToCapital } from 'utils/formatter'

type Props = {
  onChange: Function,
  onLoadOptions: Function,
  textValue: string | array,
  label: string,
  keyValue: string,
  options: array,
  multiple: boolean,
}

const SelectInput = ({
  keyValue,
  label,
  onChange,
  textValue,
  options,
  multiple,
  onLoadOptions
}: Props) => {
  const classes = useStyles()

  const renderLoading = useMemo(() => (
    <Grid container justifyContent='center'>
      <CircularProgress size={20} />
    </Grid>
  ), [])

  const renderOptions = useMemo(() => (
    options && options.map(o => <MenuItem key={o.id} value={multiple ? o.name : o.id}>{underlineToCapital(o.name)}</MenuItem>)
  ), [multiple, options])

  if (textValue && textValue.length > 0 && !options) return null

  return (
    <Grid item xs={12}>
      <FormControl className={classes.form}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          multiple={multiple}
          fullWidth
          onOpen={onLoadOptions}
          MenuProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'left' },
            transformOrigin: { vertical: 'bottom', horizontal: 'right' },
            getContentAnchorEl: null
          }}
          value={textValue}
          onChange={e => onChange(keyValue, e.target.value)}
        >
          {
            options
              ? renderOptions
              : renderLoading
          }
        </Select>
      </FormControl>
    </Grid>
  )
}

export default SelectInput
