import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  grid: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  arrowButtonRoot: {
  },
  rangeLabel: {
    marginRight: theme.spacing()
  }
}))

export default useStyles
