import useStyles from 'pages/customers/_styles/filters'
import React, { useMemo, useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Filters from 'pages/customers/Filters'
import CustomerProfile from '../customerProfile/CustomerProfile'
import CustomersContext from 'contexts/CustomersContext'
import clsx from 'clsx'

const RightSidebar = () => {
  const classes = useStyles()
  const { openRightSideBar, openFilters } = useContext(CustomersContext)

  return useMemo(() => (
    <Drawer
      className={clsx({ [classes.drawer]: openRightSideBar })}
      variant='persistent'
      anchor='right'
      open={openRightSideBar}
      classes={{ paper: classes.drawerPaper }}
    > {openFilters ? <Filters /> : <CustomerProfile />}
    </Drawer>

  ), [classes.drawer, classes.drawerPaper, openFilters, openRightSideBar])
}

export default RightSidebar
