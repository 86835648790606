import { NoSymbolIcon } from "@heroicons/react/24/outline";
import useRouter from "hooks/useRouter";

export default function EmptyPersonState({ eventId }) {
  const { navigate } = useRouter();
  
  return (
    <div className="text-center">
      <NoSymbolIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">There's nothing here</h3>
      <div className="mt-4">
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => navigate(`/events/${eventId}/people`)}
        >
          Go back home
        </button>
      </div>
    </div>
  )
}