import { useCallback, useMemo, useState } from "react";
import { ASSOCIATE_OPTIONS, BROADCASTS, GET_CUSTOMER_UPCOMING_BROADCASTS } from "../utils/queries";
import useApi from "hooks/useApi";
import useRollbar from "./useRollbar";

const PAGE_SIZE = 100;

const useBroadcastTable = () => {
  const { captureError } = useRollbar();
  const { query } = useApi();
  const [loading, setLoading] = useState(false);

  const [broadcasts, setBroadcasts] = useState([]);

  const [broadcastsDraftTotalCount, setBroadcastsDraftTotalCount] = useState(0);
  const [broadcastsScheduledTotalCount, setBroadcastsScheduledTotalCount] = useState(0);
  const [broadcastsSendingTotalCount, setBroadcastsSendingTotalCount] = useState(0);
  const [broadcastsSentTotalCount, setBroadcastsSentTotalCount] = useState(0);

  const [broadcastFilter, setBroadcastFilter] = useState(1);
  const [broadcastSelectedStatus, setBroadcastSelectedStatus] = useState(window.location.href.split("/")[6] ?? "draft");  
  const [broadcastSelectedLimit, setBroadcastSelectedLimit] = useState(PAGE_SIZE);
  const [broadcastsResultsTotalCount, setBroadcastsResultsTotalCount] = useState(0);
  const [broadcastsSortBy, setBroadcastsSortBy] = useState("send_datetime_utc");
  const [broadcastsSortByOrder, setBroadcastsSortByOrder] = useState("desc");
  const [broadcastsPage, setBroadcastsPage] = useState(1);
  const [broadcastsNameContains, setBroadcastsNameContains] = useState(null);
  const [selectedBroadcastAssociateList, setSelectedBroadcastAssociateList] = useState([]);
  const [upcomingBroadcasts, setUpcomingBroadcasts] = useState(null)

  const hasPrevious = useMemo(() => broadcastsPage > 1, [broadcastsPage])
  const hasNext = useMemo(() => broadcastsResultsTotalCount > broadcastSelectedLimit * broadcastsPage, [broadcastsPage, broadcastsResultsTotalCount, broadcastSelectedLimit])

  const fetchEventAssociates = useCallback(async (eventId) => {
    if (!eventId) {
      return;
    }
    try {
      setLoading(true);
      const res = await query(ASSOCIATE_OPTIONS(eventId));
      setSelectedBroadcastAssociateList(res.data.event.associates);
      setLoading(false);
    } catch (e) {
      captureError(e);
      console.log("Failed to load associate list", e);
      setLoading(false);
    }
  }, [
    captureError,
    query,
    setSelectedBroadcastAssociateList,
  ]);

  const fetchBroadcasts = useCallback(async (eventId) => {
    try {
      if (!eventId) {
        return;
      }

      setLoading(true);

      const {
        data: { broadcastSearch },
      } = await query(
        BROADCASTS({
          eventId: eventId,
          countOnly: false,
          status: broadcastSelectedStatus,
          limit: broadcastSelectedLimit,
          sortBy: broadcastsSortBy,
          sortByOrder: broadcastsSortByOrder,
          nameContains: broadcastsNameContains,
          page: broadcastsPage,
        })
      );

      setBroadcasts(broadcastSearch.broadcasts);
      setBroadcastsResultsTotalCount(broadcastSearch.totalCount);
      setBroadcastsPage(broadcastSearch.page);

      setBroadcastsDraftTotalCount(broadcastSearch.draftCount);
      setBroadcastsScheduledTotalCount(broadcastSearch.queuedCount);
      setBroadcastsSendingTotalCount(broadcastSearch.sendingCount);
      setBroadcastsSentTotalCount(broadcastSearch.sentCount);

      setLoading(false);
    } catch (e) {
      captureError(e);
      console.log("Failed to load broadcasts search results", e);
      setLoading(false);
    }
  }, [
    broadcastSelectedLimit,
    broadcastSelectedStatus,
    broadcastsNameContains,
    broadcastsPage,
    broadcastsSortBy,
    broadcastsSortByOrder,
    captureError,
    query
  ]);

  const fetchUpcomingBroadcasts = useCallback(async (id, eventId) => {
    if (!eventId) {
      return;
    }

    try {
      const {
        data: { customerUpcomingBroadcasts },
      } = await query(
        GET_CUSTOMER_UPCOMING_BROADCASTS({ id })
      );
      
      setUpcomingBroadcasts(customerUpcomingBroadcasts?.upcomingCount ? customerUpcomingBroadcasts : null);
    } catch (e) {
      captureError(e);
      console.log("Failed to load upcoming broadcasts", e);
    }
  }, [query]);

  return {
    fetchBroadcasts,
    fetchEventAssociates,
    setBroadcasts,
    broadcasts,
    broadcastsDraftTotalCount,
    broadcastsScheduledTotalCount,
    broadcastsSendingTotalCount,
    broadcastsSentTotalCount,
    setBroadcastsNameContains,
    setBroadcastFilter,
    broadcastFilter,
    broadcastSelectedStatus,
    setBroadcastSelectedStatus,
    broadcastSelectedLimit,
    setBroadcastSelectedLimit,
    setBroadcastsSortBy,
    broadcastsSortBy,
    broadcastsPage,
    setBroadcastsPage,
    setBroadcastsSortByOrder,
    broadcastsSortByOrder,
    selectedBroadcastAssociateList,
    setSelectedBroadcastAssociateList,
    broadcastsResultsTotalCount,
    hasPrevious,
    hasNext,
    loading,
    upcomingBroadcasts,
    fetchUpcomingBroadcasts,
  };
};

export default useBroadcastTable;
