import React, { useContext, useMemo } from 'react'
import Topbar from 'components/Topbar'
import Sidebar from 'components/Sidebar'
import useStyles from 'pages/customers/_styles/customers'
import Table from 'pages/customers/Table'
import useCustomers from 'hooks/useCustomers'
import { CustomersProvider } from 'contexts/CustomersContext'
import Pagination from 'pages/customers/Pagination'
import FilterListIcon from '@material-ui/icons/FilterList'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Bottomtray from 'pages/customers/Bottomtray'
import FilterChips from 'pages/customers/FilterChips'
import ContactListDialog from 'pages/customers/ContactListDialog'
import CreateSegmentDialog from 'pages/customers/CreateSegmentDialog'
import SegmentsDialog from 'pages/customers/SegmentsDialog'
import BroadcastDialog from 'pages/customers/BroadcastDialog'
import ReassignListDialog from './customers/ReassignListDialog'
import RightSidebar from './customers/rightSidebar/RightSidebar'
import { navigate } from "hookrouter"
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid"
import UserContext from "contexts/UserContext"

const Customers = () => {
  const classes = useStyles()
  const customersHooks = useCustomers()
  const {
    openFilters,
    toggleFilterDrawer,
    selectedDialog,
    selectedReassignDialog
  } = customersHooks

  const tooltipLabel = useMemo(
    () => openFilters ? 'Hide Filters' : 'Show Filters',
    [openFilters]
  )

  const { excludeImportsFeatureFlag, eventId } = useContext(UserContext);

  const renderMain = useMemo(() => (
    <main className={classes.content + " sm:ml-28"}>
      <div className={classes.toolbar} />
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={classes.header}
      >
        <div className="flex flex-wrap items-center">
          <h2 className="text-2xl font-semibold">Customers</h2>
          { !excludeImportsFeatureFlag &&
            <button
              type="button"
              onClick={() => navigate(`/events/${eventId}/people/import`)}
              className="inline-flex items-center ml-6 mb-2 mt-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <ArrowUpTrayIcon className="w-3 h-3 text-gray mr-1" /> Import...
            </button>
          }
        </div>
        <Grid item>
          <Tooltip title={tooltipLabel} placement='top'>
            <IconButton onClick={toggleFilterDrawer}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        justifyContent='space-between'
        container
        alignItems='center'
        className={classes.titleGrid}
      >
        <Grid xs={10} item>
          <FilterChips />
        </Grid>
      </Grid>
      <Pagination />
      <Table />
      <Bottomtray />
    </main>

  ), [classes.content, classes.header, classes.titleGrid, classes.toolbar, toggleFilterDrawer, tooltipLabel, excludeImportsFeatureFlag])

  const renderPage = useMemo(() => (
    <div className="bg-white flex">
      <Topbar />
      <Sidebar />
      {selectedDialog && <ContactListDialog />}
      {selectedReassignDialog && <ReassignListDialog eventId={eventId} />}
      {renderMain}
      <RightSidebar />
      <SegmentsDialog />
      <CreateSegmentDialog />
      <BroadcastDialog />
    </div>
  ), [renderMain, selectedDialog, selectedReassignDialog])

  return (
    <CustomersProvider value={customersHooks}>
      {renderPage}
    </CustomersProvider>
  )
}

export default Customers
