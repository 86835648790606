import React, { useMemo, useCallback, useEffect, useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import SelectInput from 'pages/customers/filters/inputs/SelectInput'

const AssignedAssociate = () => {
  const {
    state: {
      assignedAssociate
    },
    dispatch,
    getAssociateOptions
  } = useContext(CustomersContext)

  const {
    id
  } = assignedAssociate

  const [curAssignedAssociate, setCurAssignedAssociate] = useState('')
  const [options, setOptions] = useState(null)

  const createChips = useCallback(() => {
    const option = options.find(o => o.id === id)
    if (option && option.name) {
      dispatch({
        type: 'ASSIGNED_ASSOCIATE_UPDATE',
        params: {
          key: 'chip', value: `Assigned Associate: ${option.name}`
        }
      })
    }
  }, [dispatch, id, options])

  const onChange = useCallback((key, value) => {
    dispatch({ type: 'ASSIGNED_ASSOCIATE_UPDATE', params: { key, value } })
  }, [dispatch])

  const onLoadOptions = useCallback(async () => {
    const options = await getAssociateOptions()
    const unassigned = { name: 'Unassigned', id: 'unassigned' }
    if (options) setOptions([unassigned, ...options])
  }, [getAssociateOptions])

  useEffect(() => {
    const updateOptions = async () => {
      if (id && !options) await onLoadOptions()
      const assignedAssociateString = JSON.stringify(assignedAssociate)
      if (JSON.stringify(assignedAssociate) !== curAssignedAssociate && options) {
        setCurAssignedAssociate(assignedAssociateString)
        createChips()
      }
    }

    updateOptions()
  }, [assignedAssociate, createChips, curAssignedAssociate, id, onLoadOptions, options])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <SelectInput
        label='Choose Associate'
        textValue={id}
        keyValue='id'
        onChange={onChange}
        onLoadOptions={onLoadOptions}
        options={options}
      />
    </Grid>
  ), [id, onChange, onLoadOptions, options])
}

export default AssignedAssociate
