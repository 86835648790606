import { useCallback, useEffect, useState } from "react";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { EVENTS, GET_EVENT_SETTINGS } from "utils/queries";
import { CREATE_EVENT } from "utils/mutations";
import { enqueueSnackbar } from "notistack";

const useEvents = () => {
  const { captureError } = useRollbar();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [name, setName] = useState("");
  const { apolloClient, query, mutate } = useApi();

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { events },
      } = await query(
        EVENTS
      );
      
      setEvents(events);
      setLoading(false);
    } catch (e) {
      captureError(e);
      console.log("Failed to load events", e);
    }
  }, [captureError, query]);

  const onCreate = useCallback(async () => {
    try {
      setLoading(true);

      await apolloClient.mutate({
        mutation: CREATE_EVENT,
        variables: {
          name: name
        }
      });

      enqueueSnackbar("Event has been created", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
      return false;
    }

    return true;
  }, [name, apolloClient, captureError]);

  return {
    loading,
    setLoading,
    events,
    setEvents,
    fetchEvents,
    name,
    setName,
    onCreate
  };
};

export default useEvents;
