import { ApolloProvider } from '@apollo/client'
import React from 'react'
import theme from 'theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import { SnackbarProvider } from 'notistack'
import { UserProvider } from 'contexts/UserContext'
import ErrorBoundary from 'components/ErrorBoundary'
import useUser from 'hooks/useUser'
import useRouter from 'hooks/useRouter'
import MessageNotification from "components/MessageNotification"
import useApi from "hooks/useApi"
import { ReportsProvider } from 'contexts/ReportsContext'
import useReports from 'hooks/useReports'
import { CustomersProvider } from 'contexts/CustomersContext'
import useBroadcastTable from 'hooks/useBroadcastTable';

const App = () => {
  const { getRoutes } = useRouter()
  const { apolloClient } = useApi()
  const userHooks = useUser()
  const reportsHooks = useReports()
  const broadcastTableHooks = useBroadcastTable()

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <UserProvider value={userHooks}>
          <CustomersProvider value={broadcastTableHooks}>
            <ReportsProvider value={reportsHooks}>
              <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} Components={{
                  messageNotification: MessageNotification
                }}>
                  <ConfirmProvider>
                    <CssBaseline />
                    {getRoutes()}
                  </ConfirmProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </ReportsProvider>
          </CustomersProvider>
        </UserProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default App
