import { useMemo, useState, useCallback } from 'react'
import useApi from 'hooks/useApi'
import { useSnackbar } from 'notistack'
import { CREATE_SEGMENT } from 'utils/mutations'
import { SEGMENT } from 'utils/queries'
import { getSearchConditions } from 'utils/formatter'
import fieldsmap from 'constants/fieldsmap'
import clsx from 'clsx'
import useRollbar from 'hooks/useRollbar'

const findOption = (options, from, to) => {
  const op = {}
  Object.keys(options).forEach(f => {
    const { from: fromOp, to: toOp } = options[f]
    if (clsx(from) === clsx(fromOp) && clsx(to) === clsx(toOp)) {
      op.option = f
    }
  })

  return op.option || 'custom'
}

const useSegment = (state, dispatch, resetAll) => {
  const { mutate, query } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [newSegmentDialog, setNewSegmentDialog] = useState(false)
  const [segmentsDialog, setSegmentsDialog] = useState(false)
  const [segmentList, setSegmentList] = useState(null)
  const { captureError } = useRollbar()

  const openNewSegmentDialog = useCallback(() => setNewSegmentDialog(true), [])
  const closeNewSegmentDialog = useCallback(() => setNewSegmentDialog(false), [])

  const openSegmentsDialog = useCallback(() => setSegmentsDialog(true), [])
  const closeSegmentsDialog = useCallback(() => setSegmentsDialog(false), [])

  const search = useMemo(() => getSearchConditions(state), [state])
  const hasSearchConditions = useMemo(() => search.replace(/(\r\n|\n|\r)/gm, '').length > 180, [search])

  const createSegment = useCallback(async (eventId, name, description) => {
    try {
      const res = await mutate(CREATE_SEGMENT(eventId, name, description, search))
      return res.data.createSegment.id
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to save segment', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [captureError, enqueueSnackbar, mutate, search])

  const applySegment = useCallback(async (eventId, id) => {
    try {
      const res = await query(SEGMENT(eventId, id))
      const bulk = {}
      resetAll()

      res.data.segment.searchConditions.forEach(s => {
        if (fieldsmap[s.fieldName]) {
          const { key, parent, options, nullValue } = fieldsmap[s.fieldName]
          if (parent) {
            if (key && s.conditionOperator === 'contains') {
              bulk[parent] = {
                ...state[parent],
                ...bulk[parent],
                [key]: s.conditionValue,
                changed: true
              }
            }
            if (s.conditionOperator === 'range') {
              const op = {}
              if (options) {
                op.option = findOption(options, s.conditionFromValue, s.conditionToValue)
              }
              bulk[parent] = {
                ...state[parent],
                ...bulk[parent],
                ...op,
                from: clsx(s.conditionFromValue),
                to: clsx(s.conditionToValue),
                changed: true
              }
            }
            if (key && s.conditionOperator === 'is_null' && nullValue) {
              bulk[parent] = {
                ...state[parent],
                ...bulk[parent],
                [key]: nullValue,
                changed: true
              }
            }
            if (key && s.conditionOperator === 'equals') {
              const op = {}
              if (options) {
                op.option = findOption(options, s.conditionValue, s.conditionValue)
                op.to = s.conditionValue
              }
              bulk[parent] = {
                ...state[parent],
                ...bulk[parent],
                ...op,
                [key]: s.conditionValue,
                changed: true
              }
            }
          }
        } else if (s.conditionOperator === 'contains') {
          const parent = 'customerProperties'
          const key = s.fieldName.split('.').pop()
          bulk[parent] = {
            ...state[parent],
            ...bulk[parent],
            [key]: s.conditionValue,
            changed: true
          }
        }
        if (s.subqueryType === 'Event') {
          const { parent, options } = fieldsmap.event
          const option = findOption(options, s.conditionFromValue, s.conditionToValue)
          bulk[parent] = {
            ...state[parent],
            ...bulk[parent],
            option,
            from: s.conditionFromValue,
            to: s.conditionToValue,
            id: s.fieldName,
            changed: true
          }
        }
      })
      dispatch({ type: 'BULK_UPDATE', params: { bulk } })
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to save segment', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [query, resetAll, dispatch, state, captureError, enqueueSnackbar])

  return {
    createSegment,
    applySegment,
    newSegmentDialog,
    openNewSegmentDialog,
    closeNewSegmentDialog,
    segmentsDialog,
    openSegmentsDialog,
    closeSegmentsDialog,
    segmentList,
    setSegmentList,
    hasSearchConditions
  }
}

export default useSegment
