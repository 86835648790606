import { useContext, useCallback, useState, useEffect } from 'react'
import { ME } from 'utils/queries'
import useApi from 'hooks/useApi'
import useRouter from 'hooks/useRouter'
import UserContext from 'contexts/UserContext'
import { useAuth0 } from "@auth0/auth0-react"

// const setPageName = (res, eventId) => {
//   const eventName = res.data.me.events.find((event) => event.id === eventId)?.name || "";
//   try {
//     document.title = eventName || 'Concierge'
//   } catch (e) {
//     document.title = 'Concierge'
//   }
// };

const NON_AUTHORIZED_PATHS = ['/login'];

const useAuth = (fetchUser = true) => {
  const { query } = useApi();
  const [loading, setLoading] = useState(false);
  const { path, navigateToLogin } = useRouter();
  const { init, user, eventId } = useContext(UserContext);
  const { logout } = useAuth0();

  const fetchMe = useCallback(async () => {
    try {
      setLoading(true);
      const res = await query(ME);
      const user = {
        id: res.data.me.id,
        firstName: res.data.me.firstname,
        fullName: res.data.me.fullname,
        lastName: res.data.me.lastname,
        email: res.data.me.email,
        phone: res.data.me.mobilephone,
        timezone: res.data.me.timeZone,
        inboundMessageOptIn: res.data.me.inboundMessageOptIn,
        eventId: res.data.me.event?.id,
        createdAt: res.data.me.createdAt,
        featureSet: res.data.me.featuresSet,
        events: res.data.me.events
      };

      init(user);

      setLoading(false);
      // setPageName(res, eventId);

      return user;
    } catch (e) {
      // We logout the Auth0 user/token when the graphql query returns an error
      // which usually means we couldn't authorize the associate on our end probably due to it needing an invite.
      // Since we don't have our own login page but instead rely on Auth0 for this purpose we can't show any messages here. But this could
      // be modified in the future.
      await logout();
      navigateToLogin(false);
      return null;
    }
  }, [init, navigateToLogin, query, eventId, logout])

  useEffect(() => {
    if (NON_AUTHORIZED_PATHS.includes(path)) return;
    if (!fetchUser) return;

    if (Object.keys(user).length === 0) fetchMe();
  }, [fetchUser, user, fetchMe, path]);

  return {
    fetchMe,
    loading
  }
}

export default useAuth
