import React, { useCallback, useMemo, useContext, useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Fuse from 'fuse.js'
import EmptyTemplate from 'pages/customers/broadcastDialog/EmptyTemplate'
import TemplateView from 'pages/customers/broadcastDialog/TemplateView'
import cx from 'classnames'
import CustomersContext from 'contexts/CustomersContext'
import Grid from '@material-ui/core/Grid'
import useStyles from 'pages/customers/broadcastDialog/_styles/firstStep'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

const FirstStep = () => {
  const {
    broadcastDialog,
    broadcastStep,
    openCreateTemplate,
    newTemplate,
    editTemplate,
    broadcastTemplates,
    fetchTemplates,
    selectedTemplate,
    broadcastLoading,
    setSelectedTemplate
  } = useContext(CustomersContext)
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const init = async () => {
      await fetchTemplates()
    }

    if (broadcastDialog) init()
  }, [broadcastDialog, fetchTemplates])

  useEffect(() => {
    if (!broadcastTemplates) return
    const options = {
      shouldSort: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'subject',
        'title'
      ]
    }
    const fuse = new Fuse(broadcastTemplates, options)
    if (search) {
      const results = fuse.search(search)
      setFiltered(results.map(i => ({
        id: i.item.id,
        subject: i.item.subject,
        htmlDesign: i.item.htmlDesign,
        body: i.item.body,
        title: i.item.title
      })))
    } else setFiltered(broadcastTemplates)
  }, [search, broadcastTemplates, setSelectedTemplate])

  const onChangeFilter = useCallback(e => setSearch(e.target.value), [])

  const hasTemplates = useMemo(() => broadcastTemplates?.length !== 0, [broadcastTemplates])
  return useMemo(() => (
    <Grid container className={cx((editTemplate || newTemplate || broadcastStep !== 0) && classes.hidden)}>
      <Grid item className={classes.leftGrid} xs={4} container alignItems='flex-start'>
        <Grid container justifyContent='space-between' alignItems='center' className={classes.recentContainer}>
          <Typography className={classes.recent}>RECENT TEMPLATES</Typography>
          <Button onClick={openCreateTemplate} color='primary'>+ New</Button>
        </Grid>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={search}
            onChange={onChangeFilter}
            placeholder='Search by title or subject line'
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
        <Grid container className={cx(broadcastLoading && classes.listContainer)} alignItems='center'>
          {broadcastLoading ? (
            <Grid container justifyContent='center'>
              <CircularProgress size={30} />
            </Grid>
          ) : (
            <List className={classes.list}>
              {filtered?.map(b => (
                <ListItem
                  button
                  key={`template-${b.id}`}
                  selected={b.id === selectedTemplate?.id}
                  onClick={() => setSelectedTemplate(b)}
                >
                  <ListItemText
                    primary={b.title || '(no title)'}
                    secondary={b.subject || '(no subject)'}
                    className={classes.ellipsis}
                  />
                </ListItem>
              ))}
              {filtered?.length === 0 &&
                <Grid container direction='column' alignItems='center' className={classes.emptySearch}>
                  <SearchIcon />
                  <Typography><b>{hasTemplates ? 'No results' : 'No templates'}</b></Typography>
                  {hasTemplates && <Typography>Try adjusting your search</Typography>}
                </Grid>}
            </List>)}
        </Grid>
      </Grid>
      <Grid container item className={classes.rightGrid} xs={8} alignItems={cx(!selectedTemplate ? 'center' : 'flex-start')}>
        {selectedTemplate ? <TemplateView /> : <EmptyTemplate />}
      </Grid>
    </Grid>
  ), [editTemplate, newTemplate, broadcastStep, classes.hidden, classes.leftGrid, classes.recentContainer, classes.recent, classes.search, classes.searchIcon, classes.inputRoot, classes.inputInput, classes.listContainer, classes.list, classes.emptySearch, classes.rightGrid, classes.ellipsis, openCreateTemplate, search, onChangeFilter, broadcastLoading, filtered, hasTemplates, selectedTemplate, setSelectedTemplate])
}

export default FirstStep
