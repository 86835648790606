import { useContext, useCallback, useState } from 'react'
import useRollbar from 'hooks/useRollbar'
import {
  BROADCAST_TEMPLATES,
  BROADCAST_TEMPLATES_ID,
  DEFAULT_EMAIL_TEMPLATE
} from 'utils/queries'
import {
  REMOVE_ATTACHMENT,
  SEND_TEST_EMAIL,
  CREATE_BROADCAST,
  UPDATE_BROADCAST,
  QUEUE_BROADCAST,
  ADD_CUSTOMER_TO_LIST,
  UPDATE_TEMPLATE,
  CREATE_TEMPLATE,
  DELETE_BROADCAST,
  CREATE_LIST,
  UPLOAD_TEMPLATE_FILES
} from 'utils/mutations'
import useApi from 'hooks/useApi'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import UserContext from 'contexts/UserContext'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

const useCustomersBroadcast = (selectedCustomers) => {
  const { captureError } = useRollbar()
  const { enqueueSnackbar } = useSnackbar()
  const { query, mutate, apolloClient } = useApi()
  const { fullName, eventId } = useContext(UserContext)
  const [broadcastId, setBroadcastId] = useState(null)
  const [broadcastTemplates, setBroadcastTemplates] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [broadcastLoading, setBroadcastLoading] = useState(false)
  const [broadcastDialog, setBroadcastDialog] = useState(false)
  const [broadcastStep, setBroadcastStep] = useState(0)
  const [broadcastDate, setBroadcastDate] = useState('')
  const [broadcastDateOption, setBroadcastDateOption] = useState(0)
  const [listId, setListId] = useState(null)
  const [listName, setListName] = useState('')
  const [broadcastDraftDialog, setBroadcastDraftDialog] = useState(false)
  const [closingDialog, setClosingDialog] = useState(false)
  const [newTemplate, setNewTemplate] = useState(false)
  const [editTemplate, setEditTemplate] = useState(false)
  const openCreateTemplate = useCallback(async () => {
    setNewTemplate(true)
    setEditTemplate(false)
    try {
      const res = await query(DEFAULT_EMAIL_TEMPLATE)
      setSelectedTemplate(res.data.me.event.defaultEmailTemplate)
    } catch (e) {
      return null
    }
  }, [query])

  const clearHooks = useCallback(() => {
    setBroadcastId(null)
    setBroadcastTemplates(null)
    setSelectedTemplate(null)
    setBroadcastLoading(false)
    setBroadcastDialog(false)
    setBroadcastStep(0)
    setBroadcastDate('')
    setBroadcastDateOption(0)
    setListId(null)
    setListName('')
    setBroadcastDraftDialog(false)
    setClosingDialog(false)
    setNewTemplate(false)
    setEditTemplate(false)
  }, [])

  const openBroadcastDraftDialog = useCallback(() => setBroadcastDraftDialog(true), [])
  const closeBroadcastDraftDialog = useCallback(() => setBroadcastDraftDialog(false), [])

  const fetchTemplates = useCallback(async () => {
    try {
      setBroadcastLoading(true)
      const res = await query(BROADCAST_TEMPLATES(eventId))
      const orderedTemplates = res.data.templates
        .sort((b, a) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
      setBroadcastTemplates(orderedTemplates)
      setBroadcastLoading(false)
    } catch (e) {
      captureError(e)
      console.log('Failed to load templates', e)
    }
  }, [eventId, captureError, query])

  const updateTemplate = useCallback(async (title, subject, htmlDesign, body, privateTemplate) => {
    try {
      const { id } = selectedTemplate
      const res = await mutate(UPDATE_TEMPLATE(id, title, subject, htmlDesign, body, privateTemplate))
      setSelectedTemplate(res.data.updateTemplate)
      fetchTemplates()
      setEditTemplate(false)
      return res.data.updateTemplate.id
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to update template', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [captureError, enqueueSnackbar, fetchTemplates, mutate, selectedTemplate])

  const createTemplate = useCallback(async (title, subject, htmlDesign, body, privateTemplate) => {
    try {
      const res = await mutate(CREATE_TEMPLATE(eventId, title, subject, htmlDesign, body, privateTemplate))
      setSelectedTemplate(res.data.createTemplate)
      fetchTemplates()
      setNewTemplate(false)
      return res.data.createTemplate.id
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to create template', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [eventId, captureError, enqueueSnackbar, fetchTemplates, mutate])

  const nextBroadcastStep = useCallback(() => {
    if (
      broadcastStep === 1 &&
      moment(broadcastDate).valueOf() - moment().add(1, 'minutes').valueOf() < 0
    ) {
      enqueueSnackbar(
        'Please select a valid time in the future', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    } else {
      setBroadcastStep(s => s + 1)
    }
  }, [broadcastDate, broadcastStep, enqueueSnackbar])
  const previousBroadcastStep = useCallback(() => setBroadcastStep(s => s - 1), [])

  const openBroadcastDialog = useCallback(() => setBroadcastDialog(true), [])
  const closeBroadcastDialog = useCallback(() => {
    if (newTemplate) setSelectedTemplate(null)
    if (newTemplate || editTemplate) {
      setNewTemplate(false)
      setEditTemplate(false)
    } else {
      if (selectedTemplate) {
        setClosingDialog(true)
        setBroadcastDraftDialog(true)
      } else {
        clearHooks()
      }
    }
  }, [clearHooks, editTemplate, newTemplate, selectedTemplate])

  const discardBroadcast = useCallback(async () => {
    try {
      if (broadcastId) await mutate(DELETE_BROADCAST(eventId, broadcastId))
      clearHooks()
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to discard broadcast', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [eventId, broadcastId, captureError, clearHooks, enqueueSnackbar, mutate])

  const addCustomersToList = useCallback(async listId => {
    const ids = Object.keys(selectedCustomers).map(s => Number(s))
    const params = `eventId: ${Number(eventId)}, listId: ${Number(listId)}, customerIds: [${ids}]`
    await mutate(ADD_CUSTOMER_TO_LIST(params))
  }, [mutate, eventId, selectedCustomers])

  const createBroadcast = useCallback(async name => {
    const newListName = name || listName || `${fullName} email list ${moment().utc().format()}`
    setListName(newListName)
    try {
      setBroadcastLoading(true)
      const { id } = selectedTemplate
      const list = await mutate(CREATE_LIST(eventId, newListName))
      if (list?.data?.createList?.id) {
        const res = await mutate(CREATE_BROADCAST(eventId, id, newListName, broadcastDate && broadcastDate.utc().format()))
        const listId = list.data.createList.id
        await addCustomersToList(listId)
        setListId(listId)
        setBroadcastId(res.data.createBroadcast.id)
        setBroadcastLoading(false)
        return { broadcastId: res.data.createBroadcast.id, listId, listName: newListName }
      } else {
        setBroadcastLoading(false)
        enqueueSnackbar(
          list.message.split('Validation failed: ')[1], {
            autoHideDuration: 3000,
            variant: 'error'
          })
      }
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to create broadcast', {
          autoHideDuration: 3000,
          variant: 'error'
        })
      return null
    }
  }, [eventId, addCustomersToList, broadcastDate, captureError, enqueueSnackbar, fullName, listName, mutate, selectedTemplate])

  const updateBroadcast = useCallback(async name => {
    try {
      if (broadcastId) {
        await mutate(UPDATE_BROADCAST({
          eventId,
          broadcastId,
          listId,
          name: name || listName,
          templateId: selectedTemplate?.id,
          sendAt: broadcastDate && broadcastDate.utc().format()
        }))
        return { broadcastId, listId, listName }
      }
      return null
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to send emails', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
  }, [eventId, broadcastDate, broadcastId, captureError, enqueueSnackbar, listId, listName, mutate, selectedTemplate])

  const sendBroadcast = useCallback(async () => {
    setBroadcastLoading(true)

    const { broadcastId: bId, listId, listName } = broadcastId ? await updateBroadcast() : await createBroadcast()
    if (bId) {
      try {
        const { id } = selectedTemplate
        await addCustomersToList(listId)
        await mutate(UPDATE_BROADCAST({
          eventId: eventId,
          broadcastId: bId,
          name: listName,
          templateId: id,
          listId,
          sendAt: broadcastDate.utc().format()
        })
        )
        await mutate(QUEUE_BROADCAST(eventId, bId))

        enqueueSnackbar(
          'Your message(s) have been scheduled.', {
            autoHideDuration: 3000,
            variant: 'success'
          })
        clearHooks()
      } catch (e) {
        captureError(e)
        enqueueSnackbar(
          'Failed to send emails', {
            autoHideDuration: 3000,
            variant: 'error'
          })
      }
      setBroadcastLoading(false)
    }
  }, [eventId, addCustomersToList, broadcastDate, broadcastId, captureError, clearHooks, createBroadcast, enqueueSnackbar, mutate, selectedTemplate, updateBroadcast])

  const sendTestEmail = useCallback(async () => {
    setBroadcastLoading(true)
    const { broadcastId: bId } = broadcastId ? await updateBroadcast() : await createBroadcast()
    try {
      if (bId) {
        const res = await mutate(SEND_TEST_EMAIL(eventId, bId))
        if (res.data.testBroadcast.broadcast.id) {
          enqueueSnackbar(
            res.data.testBroadcast.message, {
              autoHideDuration: 3000,
              variant: 'success'
            })
        }
      } else {
        enqueueSnackbar(
          'Failed to send test email', {
            autoHideDuration: 3000,
            variant: 'error'
          })
      }
    } catch (e) {
      console.error(e)
      captureError(e)
      enqueueSnackbar(
        'Failed to send test email', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
    setBroadcastLoading(false)
  }, [eventId, broadcastId, captureError, createBroadcast, enqueueSnackbar, mutate, updateBroadcast])

  const createDraft = useCallback(async name => {
    try {
      if (broadcastId) await updateBroadcast(name)
      else await createBroadcast(name)
      if (closingDialog) {
        clearHooks()
      }
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to create draft', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
    closeBroadcastDraftDialog()
  }, [broadcastId, captureError, clearHooks, closeBroadcastDraftDialog, closingDialog, createBroadcast, enqueueSnackbar, updateBroadcast])

  const handleEditTemplate = useCallback(() => {
    setNewTemplate(false)
    setEditTemplate(true)
  }, [])

  const reloadTemplate = useCallback(async () => {
    try {
      const { id } = selectedTemplate
      const res = await query(BROADCAST_TEMPLATES_ID(eventId, id))
      const template = get(res, 'data.templates', null)
      if (template?.length > 0) {
        setSelectedTemplate(template[0])
        const index = findIndex(broadcastTemplates, { id })
        setBroadcastTemplates(Object.assign([], broadcastTemplates, { [index]: template[0] }))
      }
    } catch (e) {
      return null
    }
  }, [eventId, broadcastTemplates, query, selectedTemplate])

  const uploadTemplateFiles = useCallback(async ({ target: { validity, files } }) => {
    setBroadcastLoading(true)
    try {
      if (validity.valid) {
        const { id } = selectedTemplate
        const res = await apolloClient.mutate({
          mutation: UPLOAD_TEMPLATE_FILES(id),
          variables: { files }
        })
        if (res?.data?.addAttachments?.length > 0) {
          await reloadTemplate()
        }
      }
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to upload', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
    setBroadcastLoading(false)
  }, [apolloClient, captureError, enqueueSnackbar, reloadTemplate, selectedTemplate])

  const handleDeleteAttachment = useCallback(id => async () => {
    setBroadcastLoading(true)
    try {
      const res = await mutate(REMOVE_ATTACHMENT(id))
      if (res?.data?.removeAttachment?.id) {
        await reloadTemplate()
      }
    } catch (e) {
      captureError(e)
      enqueueSnackbar(
        'Failed to remove attachment', {
          autoHideDuration: 3000,
          variant: 'error'
        })
    }
    setBroadcastLoading(false)
  }, [captureError, enqueueSnackbar, mutate, reloadTemplate])

  return {
    handleDeleteAttachment,
    uploadTemplateFiles,
    broadcastDialog,
    openBroadcastDialog,
    broadcastTemplates,
    fetchTemplates,
    selectedTemplate,
    setSelectedTemplate,
    closeBroadcastDialog,
    sendTestEmail,
    broadcastLoading,
    broadcastStep,
    nextBroadcastStep,
    previousBroadcastStep,
    broadcastDate,
    setBroadcastDate,
    broadcastDateOption,
    setBroadcastDateOption,
    createDraft,
    broadcastDraftDialog,
    openBroadcastDraftDialog,
    closingDialog,
    closeBroadcastDraftDialog,
    discardBroadcast,
    newTemplate,
    listName,
    openCreateTemplate,
    createTemplate,
    handleEditTemplate,
    editTemplate,
    sendBroadcast,
    updateTemplate
  }
}

export default useCustomersBroadcast
