import UserContext from 'contexts/UserContext';
import React, { useContext } from 'react'
import Customers from 'pages/Customers';
import Dashboard from "pages/Dashboard";
import People from "pages/People";

const Index = () => {
  const { ecommerceExperienceFeatureFlag, eventCreationDisableFeatureFlag } = useContext(UserContext);

  let indexComponent;
  if (ecommerceExperienceFeatureFlag) {
    indexComponent = <Customers />;
  } else if (eventCreationDisableFeatureFlag) {
    indexComponent = <People />;
  } else {
    indexComponent = <Dashboard />;
  }

  return (
    indexComponent
  )
}

export default Index;
