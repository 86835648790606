import React, { useMemo } from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import useStyles from 'pages/login/_styles/login'
import Container from '@material-ui/core/Container'
import Logo from 'assets/Logo.svg'
import queryParams from "utils/queryParams"

export default function AcceptInvitation () {
  const classes = useStyles();
  const invitationToken = queryParams.get().token;
  const accountName = queryParams.get().account_name;
  // TODO: Implement invitation acceptance with Auth0

  return useMemo(() => (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <img alt='logo' className={classes.logo} src={Logo} />
        <div className="text-center">
          Join {accountName}'s team on Concierge.
        </div>
        <div id="sign-in-with-auth0-button" className="w-full my-6 flex justify-center items-center">
          {/* <button
            className="flex items-center justify-center w-full rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            type="button"
            onClick={requestGoogleAccessToken}>
              <img className="mr-4" src={GoogleLogo} alt="Google Logo" />
              Continue with Google
          </button> */}
        </div>
        <Typography variant='body2'>
          {'By signing up, you agree to our '}
          <Link href='https://www.conciergeteam.co/terms' className={classes.forgotPassLink}>
            Terms of Use
          </Link>
          {' and our '}
          <Link href='https://www.conciergeteam.co/privacy' className={classes.forgotPassLink}>
            Privacy Policy.
          </Link>
        </Typography>
      </div>
    </Container>
  ),
  [
    accountName,
    classes.forgotPassLink,
    classes.logo,
    classes.paper
  ])
}
