import React, { useContext, useEffect } from "react"
import useRouter from "hooks/useRouter"
import { classNames } from "utils/misc"
import UserContext from "contexts/UserContext"

const Tabs = ({ title, tabs, current }) => {
  const { navigate } = useRouter()
  const { associateAccounts, fetchAssociateAccounts } = useContext(UserContext)

  useEffect(() => {
    if (associateAccounts.length) return

    const getAssociateAccounts = async () => {
      fetchAssociateAccounts();
    };

    getAssociateAccounts();
  }, [])

  const onChange = tab => {
    navigate(`/settings/${tab}`)
  }

  return (
    <div className="border-b border-gray-200 pb-0">
      <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
      <div className="mt-3 sm:mt-4">
        <nav className="-mb-px flex space-x-6">
          {tabs.map(tab =>
            associateAccounts.length === 0 && tab.id === "advanced" && current !== "advanced" ? null : (
              <a
                key={tab.name}
                onClick={() => onChange(tab.id)}
                className={classNames(
                  tab.id === current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-3 text-sm font-medium cursor-pointer"
                )}
                aria-current={tab.id === current ? "page" : undefined}
              >
                {tab.name}
              </a>
            )
          )}
        </nav>
      </div>
    </div>
  )
}

export default Tabs
