import React, { useCallback, useContext, useEffect, useState } from 'react'
import MainLayout from 'components/MainLayout'
import Dropzone from "components/Dropzone";
import useApi from "hooks/useApi";
import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { IMPORT_CUSTOMER_RECORDS } from "utils/mutations";
import { enqueueSnackbar } from "notistack";
import useRollbar from "hooks/useRollbar";
import UserContext from "contexts/UserContext";

import { useFlatfile, FlatfileProvider, Sheet, Space } from "@flatfile/react";
import { blueprint } from 'components/import/blueprint'

const FlatfilePortal = ({ eventId }) => {
  const { openPortal } = useFlatfile();  

  const { apolloClient } = useApi();
  const { captureError } = useRollbar();

  useEffect(() => {
    openPortal();
  }, [])

  const handleSubmit = async ({ sheet, event }) => {
    const { spaceId } = event.context;
    const { records, ...validData } = await sheet.validData();

    if (!records.length) {
      console.log('empty csv');
      return
    }

    const data = {
      eventId: parseInt(eventId),
      spaceId,
      ...validData,
      records: records.map(({ id, values }) => ({
          id,
          ...Object.fromEntries(Object.entries(values).map(([key, { value }]) => [key, value]))
      }))
    };

    await apolloClient.mutate({
      mutation: IMPORT_CUSTOMER_RECORDS,
      variables: data
    });

    // try {
    //   await apolloClient.mutate({
    //     mutation: IMPORT_CUSTOMER_RECORDS,
    //     variables: data
    //   });
    //   enqueueSnackbar("CSV file uploaded successfully! We'll email you when your import is complete.", {
    //     autoHideDuration: 3000,
    //     variant: "success",
    //   });
    // } catch (e) {
    //   enqueueSnackbar("Failed to upload csv file:" + e.message, {
    //     autoHideDuration: 3000,
    //     variant: "error",
    //   });
    //   console.error(e);
    //   captureError(e);
    // }
  }

  return (
    <Space
      config={{
        namespace: "portal",
        metadata: {
          sidebarConfig: {
            showSidebar: false,
          },
        },
      }}
    >
      <Sheet
        config={blueprint}
        onSubmit={handleSubmit}
      />
    </Space>
  );
};

const PeopleImportViaFlatFile = ({ eventId }) => {
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;

  const { ecommerceExperienceFeatureFlag, setEventId, currentEventName } = useContext(UserContext);

  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const pages = [
    ecommerceExperienceFeatureFlag ? { name: currentEventName, href: '/customers', current: false } : { name: currentEventName, href: `/events/${eventId}/people`, current: false },
    { name: 'Import Subscribers from CSV', href: `/events/${eventId}/people/flatfile-import`, current: true }
  ];

  return (
    <MainLayout pages={pages}>
      <div className='mt-2'>
        <FlatfileProvider publishableKey={publishableKey} config={{ displayAsModal: false, }}>
          <FlatfilePortal eventId={eventId} />
        </FlatfileProvider>
      </div>
    </MainLayout>
  )
}

export default PeopleImportViaFlatFile;
