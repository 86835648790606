import React, { useMemo, useCallback, useEffect, useState, useContext } from 'react'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import CustomersContext from 'contexts/CustomersContext'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import useStyles from 'pages/customers/broadcastDialog/_styles/secondStep'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import cx from 'classnames'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import numeral from 'numeral'

const options = [
  { label: 'In 30 minutes' },
  { label: 'Today' },
  { label: 'Tomorrow' },
  { label: 'Custom' }
]

const getMinutes = date => {
  const d = moment(date)
  if (d._isValid) return numeral(d.minutes()).format('00')
  return numeral(moment().minutes()).format('00')
}

const getHours = date => {
  const d = moment(date)
  if (d._isValid) return d.hours()
  return ''
}

const getPeriod = date => {
  const d = moment(date)
  if (d._isValid) return d.format('A')
  return ''
}

const SecondStep = () => {
  const {
    broadcastDate,
    broadcastStep,
    setBroadcastDate,
    setBroadcastDateOption,
    broadcastDateOption
  } = useContext(CustomersContext)
  const classes = useStyles()
  const [date, setDate] = useState(new Date(broadcastDate) || new Date())
  const [hour, setHour] = useState(getHours(broadcastDate))
  const [minutes, setMinutes] = useState(getMinutes(broadcastDate))
  const [period, setPeriod] = useState(getPeriod(broadcastDate))
  const [timezone, setTimezone] = useState('')

  useEffect(() => {
    const current = moment(date)
    if (current._isValid && broadcastStep === 1) {
      const currentDate = `${current.format('MM-DD-YYYY')}, ${hour}:${minutes} ${period}`
      const newDate = moment(currentDate, 'MM-DD-YYYY, h:mm A')
      setBroadcastDate(newDate)
    }
  }, [broadcastStep, date, hour, minutes, period, setBroadcastDate])

  useEffect(() => {
    if (broadcastDateOption === 0) {
      const newDate = moment().add(30, 'minutes')
      setDate(newDate)
      setMinutes(newDate.format('mm'))
      setHour(newDate.format('h'))
      setPeriod(newDate.format('A'))
    }
    if (broadcastDateOption === 1) {
      const newDate = moment().add(1, 'hour')
      setDate(newDate)
      setMinutes(newDate.format('mm'))
      setHour(newDate.format('h'))
      setPeriod(newDate.format('A'))
    }
    if (broadcastDateOption === 2) {
      const newDate = moment().add(1, 'day')
      setDate(newDate)
      setMinutes(newDate.format('mm'))
      setHour(newDate.format('h'))
      setPeriod(newDate.format('A'))
    }
  }, [broadcastDateOption])

  useEffect(() => {
    const date = new Date().toString()
    const locale = date.match(/\((.*)\)/).pop().replace('Standard ', '')
    const tz = date.split(' ')[5]
    setTimezone(`${locale} (${tz})`)
  }, [])

  const changeDate = useCallback(date => {
    setDate(date)
    setBroadcastDateOption(3)
  }, [setBroadcastDateOption])

  const changeHour = useCallback(e => {
    const { value } = e.target
    if (value <= 12 && value > 0) {
      setHour(e.target.value)
      setBroadcastDateOption(3)
    }
  }, [setBroadcastDateOption])

  const changeMinutes = useCallback(e => {
    const minutes = e.target.value
    if (minutes >= 0 && minutes < 60) {
      setMinutes(numeral(minutes).format('00'))
      setBroadcastDateOption(3)
    }
  }, [setBroadcastDateOption])

  const changePeriod = useCallback(e => {
    setPeriod(e.target.value)
    setBroadcastDateOption(3)
  }, [setBroadcastDateOption])

  return useMemo(() => (
    <Grid container className={cx(broadcastStep !== 1 && classes.hidden)}>
      <Grid item xs={4} className={classes.container}>
        <Grid container direction='column' justifyContent='flex-start'>
          <Typography className={classes.shortcuts}>SHORTCUTS</Typography>
          {options.map((o, idx) => (
            <Button
              onClick={() => setBroadcastDateOption(idx)}
              key={o.label}
              className={classes.button}
              color={broadcastDateOption === idx ? 'primary' : 'default'}
              variant={broadcastDateOption === idx ? 'contained' : 'text'}
            >
              {o.label}
            </Button>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={8} className={classes.container} container alignItems='center'>
        <Grid container alignItems='center' justifyContent='center' className={classes.datePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              orientation='landscape'
              variant='static'
              openTo='date'
              value={date}
              onChange={changeDate}
              disablePast
              disableToolbar
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container alignItems='center' justifyContent='center'>
          <TextField
            className={classes.timeInput}
            value={hour}
            onChange={changeHour}
            inputProps={{
              type: 'number',
              min: 1,
              max: 12,
              step: 1
            }}
            variant='outlined'
          />
          <div className={classes.timeDivisor}>:</div>
          <TextField
            className={classes.timeInput}
            value={minutes}
            onChange={changeMinutes}
            inputProps={{
              type: 'number',
              min: 0,
              max: 59,
              step: 15
            }}
            variant='outlined'
          />
          <TextField
            select
            value={period}
            onChange={changePeriod}
            className={cx(classes.options, classes.selectInput)}
            variant='outlined'
          >
            <MenuItem key='AM' value='AM'> AM </MenuItem>
            <MenuItem key='PM' value='PM'> PM </MenuItem>
          </TextField>
          <div className={classes.timezoneContainer}>
            <Typography component='span' className={classes.timezoneTitle}>Time zone</Typography>
            <Typography component='span' className={classes.timezone}>{timezone}</Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  ), [broadcastDateOption, broadcastStep, changeDate, changeHour, changeMinutes, changePeriod, classes.button, classes.container, classes.datePicker, classes.hidden, classes.options, classes.selectInput, classes.shortcuts, classes.timeDivisor, classes.timeInput, classes.timezone, classes.timezoneContainer, classes.timezoneTitle, date, hour, minutes, period, setBroadcastDateOption, timezone])
}

export default SecondStep
