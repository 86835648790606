import React, { Fragment, useCallback, useState } from "react";
import { XMarkIcon } from '@heroicons/react/24/outline'
import ImagePreviewModal from "components/ImagePreviewModal";

const ImagePreview = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const onPreviewOpen = useCallback((attachment)=> setPreviewOpen(true), []);
  const onPreviewClose = useCallback(()=> setPreviewOpen(false), []);

  return (
    <Fragment>
      <ImagePreviewModal
          name={props.fileName}
          url={props.fileUrl}
          open={previewOpen}
          onClose={onPreviewClose} />

      <div className="relative">
        <img
          alt="preview"
          className="max-h-[150px] h-[150px] hover:cursor-pointer"
          onClick={onPreviewOpen}
          src={props.fileUrl}
        />
        <div className="absolute hover:cursor-pointer hover:opacity-50 drop-shadow-lg z-10 right-12 top-[0.5rem]">
          <XMarkIcon className="h-6 w-6" onClick={props.handleDelete} />
        </div>
      </div>
    </Fragment>
  );
};

export default ImagePreview;
