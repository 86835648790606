import React, {
  useCallback,
  useMemo,
  forwardRef,
  useContext,
  useState
} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomersContext from 'contexts/CustomersContext'
import useStyles from 'pages/customers/_styles/createListDialog'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ConfirmDismissDialog = () => {
  const {
    dismissFromList,
    confirmDismissDialog,
    closeConfirmDismissDialog
  } = useContext(CustomersContext)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const onSave = useCallback(async () => {
    setLoading(true)
    await dismissFromList()
    closeConfirmDismissDialog()
    setLoading(false)
  }, [closeConfirmDismissDialog, dismissFromList])

  return useMemo(() => (
    <Dialog
      open={confirmDismissDialog}
      TransitionComponent={Transition}
      fullWidth
      onClose={closeConfirmDismissDialog}
      classes={{
        paper: classes.paper
      }}
      maxWidth='xs'
    >
      <DialogTitle>Are you sure you want to dismiss customers?</DialogTitle>
      <DialogActions className={classes.actions}>
        <Button onClick={closeConfirmDismissDialog}>
            Cancel
        </Button>
        <Button onClick={onSave} variant='contained' color='primary'>
          {loading ? (
            <Grid container justifyContent='center'>
              <CircularProgress size={30} />
            </Grid>
          ) : 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  ), [classes.actions, classes.paper, closeConfirmDismissDialog, confirmDismissDialog, loading, onSave])
}

export default ConfirmDismissDialog
