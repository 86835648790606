import React, {
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useState,
} from "react";
import throttle from "lodash/throttle";
import Grid from "@material-ui/core/Grid";
import CustomersContext from "contexts/CustomersContext";
import AutocompleteInput from "pages/customers/filters/inputs/AutocompleteInput";
import CheckboxInput from "pages/customers/filters/inputs/CheckboxInput";

const ActiveList = () => {
  const {
    state: { activeList },
    dispatch,
    contactLists,
    fetchContactLists,
  } = useContext(CustomersContext);

  const { id, dismissed } = activeList;

  const [curActiveList, setCurActiveList] = useState("");
  const [inputValue, setInputValue] = useState("");

  const createChips = useCallback(() => {
    if (contactLists && id) {
      const selected = contactLists.find((o) => o.id === id);
      if (selected && selected.name) {
        const chip = `Active List${
          dismissed === "true" ? " (include dismissed)" : ""
        }: ${selected.name}`;
        dispatch({
          type: "ACTIVE_LIST_UPDATE",
          params: { key: "chip", value: chip },
        });
      }
    }
  }, [contactLists, dismissed, dispatch, id]);

  const onChange = useCallback(
    (key, v) => {
      if (v)
        dispatch({ type: "ACTIVE_LIST_UPDATE", params: { key, value: v } });
      else {
        dispatch({ type: "CLEAR", key: "activeList" });
      }
    },
    [dispatch]
  );

  const onInput = useCallback(
    (_event, newInputValue) => {
      setInputValue(newInputValue);
      fetchContactLists(inputValue);
    },
    [inputValue, setInputValue, fetchContactLists]
  );

  useEffect(() => {
    const updateOptions = async () => {
      if (id && !contactLists) fetchContactLists();
      else {
        const activeListString = JSON.stringify(activeList);
        if (JSON.stringify(activeList) !== curActiveList && id) {
          setCurActiveList(activeListString);
          createChips();
        }
      }
    };

    updateOptions();
  }, [
    contactLists,
    activeList,
    id,
    fetchContactLists,
    curActiveList,
    createChips,
  ]);

  const options = useMemo(() => contactLists || [], [contactLists]);

  return useMemo(
    () => (
      <Grid container spacing={1} direction="row">
        <AutocompleteInput
          textValue={id}
          label="Active List Names"
          keyValue="id"
          onChange={onChange}
          onLoadOptions={fetchContactLists}
          options={options}
          inputValue={inputValue}
          setInputValue={onInput}
        />
        <CheckboxInput
          label="Include Dismissed"
          keyValue="dismissed"
          textValue={dismissed}
          onChange={onChange}
        />
      </Grid>
    ),
    [
      dismissed,
      fetchContactLists,
      id,
      onChange,
      options,
      inputValue,
      onInput
    ]
  );
};

export default ActiveList;
