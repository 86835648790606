import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF'
  },
  submitButton: {
    width: '100%'
  },
  formError: {
    color: '#e07072',
    textAlign: 'center',
    marginTop: '20px'
  },
  centered: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  errorIcon: {
    verticalAlign: 'sub',
    fontSize: '16px'
  },
  card: {
    padding: '30px'
  }
}))
