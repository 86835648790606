import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'

type Props = {
  onChange: Function,
  textValue: string,
  label: string,
  keyValue: string,
}

const TextInput = ({ keyValue, label, onChange, textValue }: Props) => {
  return (
    <Grid item xs={12}>
      <TextField
        id={keyValue}
        label={label}
        fullWidth
        value={textValue}
        onChange={e => onChange(keyValue, e.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position='start'><div /></InputAdornment>
        }}
      />
    </Grid>
  )
}

export default React.memo(TextInput)
