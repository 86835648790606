import React, { useEffect, useState } from "react"
import MainLayout from "components/MainLayout"
import Tabs from "components/Tabs"
import { settingsTabs } from "utils/navigation"
import { GET_TEAM_MEMBERS } from "utils/queries"
import useApi from "hooks/useApi"
import useRollbar from "hooks/useRollbar"

const TeamSettings = () => {
  const { query } = useApi()
  const { captureError } = useRollbar()

  const [teamMembers, setTeamMembers] = useState([])
  const [loading, setLoading] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [pages, setPages] = useState([{ name: "Settings", href: "/settings", current: true }])

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        setLoading(true)

        const queryString = GET_TEAM_MEMBERS()
        const res = await query(queryString)

        setLoading(false)
        setTeamMembers(res?.data?.teamMembers)
      } catch (e) {
        captureError(e)
        setLoading(false)
      }
    }

    fetchTeamMembers()
  }, [])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: "Settings", href: `/settings`, current: false },
        { name: "Team", href: `/settings/team`, current: true }
      ])
    } else if (scrollPosition < 120) {
      setPages([{ name: "Settings", href: `/settings`, current: false }])
    }
  }, [scrollPosition])

  return (
    <MainLayout pages={pages}>
      <Tabs title="Settings" tabs={settingsTabs} current="team" />
      <div className="mt-8 flow-root w-full max-w-700">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Phone
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {teamMembers.length > 0
                    ? teamMembers.map(member => (
                        <tr key={member.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {member.firstname ?? ''} {member.lastname ?? ''}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.email}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.mobilephone ?? ''}</td>
                        </tr>
                      ))
                    : !loading && (
                        <tr>
                          <td className="text-center py-5" colSpan="{3}">
                            No team member.
                          </td>
                        </tr>
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default TeamSettings
