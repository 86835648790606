import React, { useEffect, useState, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Grid, Backdrop, CircularProgress } from '@material-ui/core'
import useStyles from 'pages/billing/_styles/billing'
import Topbar from '../components/Topbar'
import { ORG_PAYMENT_METHOD } from '../utils/queries'
import PaymentMethod from './billing/PaymentMethod'
import Typography from '@material-ui/core/Typography'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const { REACT_APP_CONCIERGE_STRIPE_PUBLIC_KEY } = process.env
const stripePromise = loadStripe(REACT_APP_CONCIERGE_STRIPE_PUBLIC_KEY)

const Billing = () => {
  const [paymentMethodLabel, setPaymentMethodLabel] = useState(null)
  const [eventName, setEventName] = useState(null)
  const [skipQuery, setSkipQuery] = useState(false)
  const classes = useStyles()

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const [getOrgPaymentMethod, { loading }] = useLazyQuery(ORG_PAYMENT_METHOD, {
    onCompleted: (data) => {
      const paymentMethod = data.me.event.paymentMethod
      setSkipQuery(true)
      setEventName(data.me.event.name)
      const paymentMethodInfo = paymentMethod ? `${capitalize(paymentMethod.brand)} ending in ${paymentMethod.last4Digits} (Expires  ${paymentMethod.expMonth}/${paymentMethod.expYear})` : null
      setPaymentMethodLabel(paymentMethodInfo)
    }
  })

  useEffect(() => {
    if (!skipQuery) getOrgPaymentMethod()
  }, [getOrgPaymentMethod, skipQuery])

  return useMemo(() => (
    <div className={classes.root}>
      <Topbar />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {!loading &&
        <main className={classes.content}>
          <Grid container alignItems='center' style={{ margin: '25px' }}>
            <Grid item xs={12}>
              <div style={{ minHeight: '45px' }} />
              <Typography className={classes.pageTitle}>
              Account {eventName}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            alignItems='center'
            justifyContent='center'
          >
            {!loading &&
              <Grid item xs={6}>
                <Elements stripe={stripePromise}>
                  <PaymentMethod paymentMethodLabel={paymentMethodLabel} />
                </Elements>
              </Grid>}
          </Grid>
        </main>}
    </div>
  ), [classes.backdrop, classes.content, classes.pageTitle, classes.root, loading, eventName, paymentMethodLabel])
}
export default Billing
