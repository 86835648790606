import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import Proptypes from 'prop-types'

const RangeDate = ({ onChange, from: fromInit, to: toInit }) => {
  const [from, setFrom] = useState(moment(fromInit)._isValid ? moment(fromInit) : null)
  const [to, setTo] = useState(moment(toInit)._isValid ? moment(toInit) : null)

  useEffect(() => {
    onChange(from, to)
  }, [from, onChange, to])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        justifyContent='space-between'
        spacing={1}
        direction='row'
      >
        <Grid item xs={6}>
          <KeyboardDatePicker
            label='From'
            variant='inline'
            format='MM/dd/yy'
            id='fromDate'
            fullWidth
            value={from}
            onChange={setFrom}
            maxDate={to}
            InputProps={{
              startAdornment: <InputAdornment position='start'><div /></InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            label='To'
            value={to}
            id='toDate'
            variant='inline'
            format='MM/dd/yy'
            onChange={setTo}
            fullWidth
            minDate={from}
            InputProps={{
              startAdornment: <InputAdornment position='start'><div /></InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

RangeDate.propTypes = {
  onChange: Proptypes.func,
  from: Proptypes.string,
  to: Proptypes.string
}

export default React.memo(RangeDate)
