import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  main: {
    height: 360
  },
  paper: {
    // height: `calc(100vh - ${theme.spacing(8)}px) `,
    position: 'relative'
  },
  actions: {
    justifyContent: 'space-between',
    margin: theme.spacing(1, 2)
  },
  buttonTest: {
    marginRight: theme.spacing()
  },
  stepLabel: {
    flexDirection: 'column',
    '& .MuiStepIcon-completed': {
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
      border: `1px solid ${theme.palette.primary.main}`,
      height: 32,
      width: 32,
      borderRadius: '50%'
    },
    '& .MuiStepIcon-active': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      border: 'none',
      fontSize: 28
    },
    '& .MuiStepIcon-active text': {
      fill: 'white'
    },
    '& .MuiStepIcon-text': {
      fill: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 12
    },
    '& .MuiStepLabel-label': {
      marginTop: theme.spacing(),
      color: theme.palette.primary.main
    },
    '& .MuiStepLabel-active': {
      fontWeight: 'bold',
      marginTop: theme.spacing(),
      color: theme.palette.primary.main
    }
  },
  stepper: {
    width: '50vw',
    '& .MuiStepConnector-alternativeLabel': {
      right: 'calc(50% + 30px)',
      left: 'calc(-50% + 30px)',
      top: 18
    }
  },
  previous: {
    marginRight: theme.spacing()
  }
}))
