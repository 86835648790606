import React, { useContext, useEffect, useState } from "react";
import useRouter from "hooks/useRouter";
import { classNames } from "utils/misc";
import UserContext from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ClipboardDocumentIcon, IdentificationIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import QRCodeAlert from "./QRCodeAlert";
import DownloadContactCardAlert from "./DownloadContactCardAlert";

const AccountMenu = ({ textSize = 'xs' }) => {
  const { path } = useRouter();
  const { logout } = useAuth0();
  const { eventSettings, currentEventPhone, currentEventPhoneFormatted } = useContext(UserContext);

  const [openQRAlert, setOpenQRAlert] = useState(false);
  const [openContactCardAlert, setOpenContactCardAlert] = useState(false);

  const [phoneCopied, setPhoneCopied] = useState(false);

  const copyEventPhone = () => {
    setPhoneCopied(true);

    navigator.clipboard.writeText(currentEventPhoneFormatted)

    setTimeout(() => {
      setPhoneCopied(false);
    }, 1000);
  }

  return (
    <div className="p-3 flex flex-col absolute bottom-0">
      {currentEventPhone && (
        <>
          <div className="flex items-center gap-[12px] m-2">
            {eventSettings?.settings && (
              <>
                {eventSettings.settings.qr_code_url && <QrCodeIcon className="cursor-pointer h-6 w-6 text-indigo-100 hover:text-white" onClick={() => setOpenQRAlert(true)} />}
                {eventSettings.settings.contact_card_url && <IdentificationIcon className="cursor-pointer h-6 w-6 text-indigo-100 hover:text-white" onClick={() => setOpenContactCardAlert(true)} />}
              </>
            )}
            {phoneCopied ? <p className="text-white">copied!</p> : <ClipboardDocumentIcon className="cursor-pointer h-6 w-6 text-indigo-100 hover:text-white" onClick={copyEventPhone} />}
          </div>
          <div className="text-white p-3">
            <h4>Phone Number</h4>
            <a href={`sms:${currentEventPhone}?body=Send%20this%20message%20to%20connect%20with%20the%20event%20team.`} className="font-bold">{currentEventPhoneFormatted}</a>
          </div>
        </>
      )}
      <a
        href="/settings"
        className={classNames(
          path === "settings" ? "bg-indigo-800 text-white" : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
          `group w-full p-3 rounded-md flex flex-col text-${textSize} font-medium`
        )}
      >
        Settings
      </a>
      <a
        onClick={logout}
        className={classNames(
          path === "settings" ? "bg-indigo-800 text-white" : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
          `group w-full p-3 rounded-md flex flex-col text-${textSize} font-medium hover:cursor-pointer`
        )}
      >
        Sign Out
      </a>

      {openQRAlert && (
        <QRCodeAlert
          open={openQRAlert}
          setOpen={setOpenQRAlert}
          phoneFormatted={currentEventPhoneFormatted}
          qr_code={eventSettings.settings.qr_code_url}
        />
      )}
      {openContactCardAlert && (
        <DownloadContactCardAlert
          open={openContactCardAlert}
          setOpen={setOpenContactCardAlert}
          contact_card_url={eventSettings.settings.contact_card_url}
        />
      )}
    </div>
  )
};

export default AccountMenu;
