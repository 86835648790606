import { useMemo, useCallback } from 'react'
import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import useRouter from 'hooks/useRouter'
import useRollbar from 'hooks/useRollbar'
import { useAuth0 } from "@auth0/auth0-react"

const { REACT_APP_GQL_URL } = process.env
const options = { uri: REACT_APP_GQL_URL }

const useApi = () => {
  const { captureError } = useRollbar()
  const { navigateToLogin, path } = useRouter()
  const { getAccessTokenSilently } = useAuth0();

  const errorLink = useCallback(onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) captureError(graphQLErrors)
    if (networkError) console.log(`[Network error]: ${networkError}`)
  }), [])

  const httpLink = useMemo(() => ApolloLink.split(
    operation => operation.getContext().hasUpload,
    createHttpLink(options),
    createUploadLink(options)
  ), [])

  const authLink = useMemo(() => setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();

    if (!token && path !== '/new-password') {
      navigateToLogin(false)
      return
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  }), [getAccessTokenSilently, navigateToLogin, path])

  const apolloClient = useMemo(() => new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache()
  }), [authLink, errorLink, httpLink])

  const mutate = useCallback(async (mutation, data) => {
    try {
      const res = await apolloClient.mutate({
        mutation,
        ...data
      })
      return res
    } catch (e) {
      captureError(e)
      return e
    }
  }, [apolloClient, captureError])

  const query = useCallback(async (query, data) => {
    try {
      const res = await apolloClient.query({
        query,
        ...data,
        fetchPolicy: 'no-cache'
      })
      return res
    } catch (e) {
      captureError(e)
      return null
    }
  }, [apolloClient, captureError])

  return {
    query,
    mutate,
    apolloClient
  }
}

export default useApi
