import React, { useMemo, useCallback, useEffect, useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomersContext from 'contexts/CustomersContext'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RangeDate from 'pages/customers/filters/inputs/RangeDate'
import { formatDateDisplay, formatDateQuery, underlineToCapital } from 'utils/formatter'
import SelectInput from 'pages/customers/filters/inputs/SelectInput'
import { eventHistory } from 'constants/options'

const EventHistory = () => {
  const {
    state: {
      eventHistory: {
        id,
        option,
        from,
        to
      }
    },
    dispatch,
    getEventHistoryOptions
  } = useContext(CustomersContext)
  const [options, setOptions] = useState(null)

  const createChips = useCallback(() => {
    if (!id) return
    const name = underlineToCapital(id)
    if (option === 'custom') {
      const clause = from && to ? 'Between' : (from ? 'After' : (to ? 'Before' : ''))
      const division = from && to ? ' - ' : ''
      const chip = from || to ? `${clause} ${formatDateDisplay(from) || ''}${division}${formatDateDisplay(to) || ''}` : ''
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: `${name}: ${chip}`, key: 'chip' } })
    } else if (option) {
      const { chip } = eventHistory[option]
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: `${name}: ${chip}`, key: 'chip' } })
    }
  }, [dispatch, from, id, option, to])

  const onChange = useCallback((key, e) => {
    if (!e) return
    const v = e.target ? e.target.value : e
    if (key === 'option' && v === 'custom') {
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: undefined, key: 'from' } })
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: undefined, key: 'to' } })
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: v, key: 'option' } })
    } else { dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { key, value: v } }) }
  }, [dispatch])

  const onChangeCustom = useCallback((newFrom, newTo, label) => {
    const isCustom = option === 'custom'

    if (isCustom && id) {
      const fromValue = formatDateQuery(newFrom)
      const toValue = formatDateQuery(newTo)

      if (![fromValue, toValue].includes('Invalid date')) {
        if (from !== fromValue) dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: fromValue, key: 'from' } })
        if (to !== toValue) dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: toValue, key: 'to' } })
        if (option !== 'custom') dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: 'custom', key: 'option' } })
      }
    } else if (id && option !== '') {
      const name = underlineToCapital(id)
      dispatch({
        type: 'EVENT_HISTORY_UPDATE',
        params: {
          from,
          to,
          chip: `${name}: ${label}`,
          option
        }
      })
    }
  }, [option, id, from, dispatch, to])

  const onLoadOptions = useCallback(async () => {
    const options = await getEventHistoryOptions()
    if (options) setOptions(options)
  }, [getEventHistoryOptions])

  const renderOptions = useMemo(() => (
    id && Object.keys(eventHistory).map(o => (
      <Grid
        key={o}
        container
      >
        <FormControlLabel
          value={o}
          control={<Radio />}
          label={eventHistory[o].label}
        />
        {o === 'custom' && option === 'custom' && <RangeDate from={from} to={to} onChange={onChangeCustom} />}
      </Grid>
    ))
  ), [id, from, to, onChangeCustom, option])

  const selectedOption = useMemo(() => (option || 'all'), [option])

  const onChangeRadio = useCallback(e => onChange('option', e), [onChange])
  const [firstLoad, setFirstLoad] = useState(true)

  const getOptionRange = useCallback(() => {
    if (option && !['all', 'custom'].includes(option)) {
      const { from, to } = eventHistory[option]
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: from, key: 'from' } })
      dispatch({ type: 'EVENT_HISTORY_UPDATE', params: { value: to, key: 'to' } })
    }
  }, [dispatch, option])

  useEffect(() => {
    const init = async () => {
      if (id && !options && firstLoad) {
        onLoadOptions()
        setFirstLoad(false)
      }
    }
    init()
    createChips()
    getOptionRange()
  }, [createChips, firstLoad, getOptionRange, id, onLoadOptions, options])

  return useMemo(() => (
    <Grid
      container
      spacing={1}
      direction='row'
    >
      <Grid item xs={12}>
        <SelectInput
          label='Event Type List'
          textValue={id}
          keyValue='id'
          onChange={onChange}
          onLoadOptions={onLoadOptions}
          options={options}
        />
        <RadioGroup value={selectedOption} onChange={onChangeRadio}>
          {renderOptions}
        </RadioGroup>
      </Grid>
    </Grid>
  ), [id, onChange, onChangeRadio, onLoadOptions, options, renderOptions, selectedOption])
}

export default React.memo(EventHistory)
