import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import MainLayout from 'components/MainLayout';
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/outline";

import { classNames } from "utils/misc";
import useLists from 'hooks/useLists'
import usePeople from 'hooks/usePeople';
import { ListsProvider } from 'contexts/ListsContext'
import UserContext from "contexts/UserContext";

import RenameModal from 'components/lists/RenameModal';
import ArchiveModal from 'components/lists/ArchiveModal';
import useSpinner from 'hooks/useSpinner'

import useApi from 'hooks/useApi'
import { CREATE_LIST } from "utils/mutations"
import useRollbar from 'hooks/useRollbar'
import { enqueueSnackbar } from "notistack"

const Lists = ({ eventId }) => {
  const listsHooks = useLists();
  const peopleHooks = usePeople();

  const { mutate } = useApi();
  const { captureError } = useRollbar();

  const { lists, fetchLists, setName, loading } = listsHooks;
  const { createList } = peopleHooks;
  const { setEventId, currentEventName, excludeSmsFeatureFlag, displayListAddedDateFeatureFlag } = useContext(UserContext);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [pages, setPages] = useState([]);

  const [listId, setListId] = useState(null);
  const [listName, setListName] = useState("");
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const [addingNewList, setAddingNewList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listNameError, setListNameError] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const onView = useCallback((e, list) => {
    window.location.href = `/events/${eventId}/people?alid=${list.id}&desc=false&sort=${displayListAddedDateFeatureFlag ? 'created_at' : 'firstname'}`;
  }, [listId]);

  const onRename = useCallback((e, list) => {
    setRenameModalOpen(true);
  }, [setRenameModalOpen]);
  
  const onArchive = useCallback((e, list) => {
    setArchiveModalOpen(true);
  }, [setArchiveModalOpen]);

  const menuActions = [
    { label: "View", action: onView },
    { label: "Rename", action: onRename },
    { label: "Archive", action: onArchive },
  ];

  const getLists = async () => {
    fetchLists(eventId);
  };

  useEffect(() => {
    getLists();
  }, [fetchLists]);

  useEffect(() => setPages([
    { name: currentEventName, href: `/events/${eventId}/lists`, current: false },
  ]), [currentEventName]);

  useEffect(() => {
    setEventId(eventId);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/lists`, current: false },
        { name: "Lists", href: `/events/${eventId}/lists`, current: true },
      ]);
    } else if (scrollPosition < 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/lists`, current: false },
      ]);
    }
  }, [scrollPosition])

  const { renderSpinner } = useSpinner(isLoading);
  const newListSaveDisabled = useMemo(() => newListName.trim() === "" || isLoading, [newListName, isLoading]);

  const onAddNewListClick = useCallback((e) => setAddingNewList(true), []);

  const onAddNewListCancelClick = useCallback((e) => {
    setAddingNewList(false);
    setNewListName("");
    setListNameError(false);
  }, []);

  const onNewListNameChange = (e) => {
    setNewListName(e.target.value)

    const nameInput = e.target.value.trim();
    
    if (nameInput) {
      const nameExists = !!lists?.find((list) => list.name.toLowerCase() == nameInput.toLowerCase());
      setListNameError(nameExists)
    }
  };

  const onSaveNewList = async () => {
    setIsLoading(true);
    
    try {
      await mutate(CREATE_LIST(eventId, newListName));
      
      getLists();
      setNewListName("");
      setAddingNewList(false);
    } catch (e) {
      captureError(e);
      enqueueSnackbar("Failed to create list", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }

    setIsLoading(false);
  };

  return (
    <ListsProvider value={listsHooks}>
      <MainLayout pages={pages} >
        <RenameModal 
          handleClose={() => setRenameModalOpen(false)} 
          refresh={() => fetchLists(eventId)}
          id={listId}
          open={renameModalOpen}
          originalName={listName} />
        <ArchiveModal 
          handleClose={() => setArchiveModalOpen(false)} 
          refresh={() => fetchLists(eventId)}
          id={listId}
          open={archiveModalOpen}
          originalName={listName} />
        <div className='w-full max-w-700'>
          <div className="flex flex-wrap items-center justify-between mb-2">
            <h1 id="primary-heading" className="font-bold text-3xl">
              Lists
            </h1>
            <button
              type="button"
              onClick={onAddNewListClick}
              disabled={addingNewList}
              className="inline-flex items-center mb-2 mt-2 sm:m-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <PlusIcon className="w-3 h-3 text-white mr-1" /> New list
            </button>
          </div>
          { !loading &&
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="min-w-[4rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Name
                  </th>
                  <th scope="col" className="min-w-[4rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    # of People
                  </th>
                  {/* <th scope="col" className="hidden sm:block min-w-[4rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Created At
                  </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {addingNewList && 
                  <tr>
                    <td colSpan={2}>
                      <div className="flex items-center px-2 sm:pl-3 sm:pr-5 py-3 gap-x-2 sm:gap-x-4">
                        <input
                          className={classNames(
                            listNameError ? 'ring-red-600 text-red-600 focus:ring-red-600' : 'ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 text-gray-900',
                            'rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset w-full sm:w-[50%]'
                          )}
                          type="text"
                          name="new-list-name"
                          placeholder="New list name"
                          value={newListName}
                          onChange={onNewListNameChange}
                          autoFocus={true}
                        />
                        <button
                          type="button"
                          className="rounded bg-indigo-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                          disabled={newListSaveDisabled || listNameError} 
                          onClick={onSaveNewList}
                        >
                          Save
                        </button>
                        <button className="cursor-pointer text-gray-600 disabled:opacity-50" disabled={isLoading} onClick={onAddNewListCancelClick}>Cancel</button>
                        <div className="">
                          { renderSpinner }
                        </div>
                      </div>
                    </td>
                  </tr>
                }
                {lists.length > 0 ? lists.map((list) => (
                  <tr key={list.id}>
                    <td className="px-3 py-4 text-sm text-gray-900">
                      <a href={`/events/${eventId}/people?alid=${list.id}&desc=false&sort=${displayListAddedDateFeatureFlag ? 'created_at' : 'firstname'}`}>
                        {list.name}
                      </a>
                    </td>
                    <td className='px-3 py-4 text-sm text-gray-900 flex justify-between'>
                      {list.size}
                      <div className="menu-actions flex flex-shrink-0 self-center">
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              { menuActions.map((menuAction) => (
                                (menuAction.label === "View" || list.name !== "All" ) &&
                                <Menu.Item key={menuAction.label}>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        setListId(list.id);
                                        setListName(list.name);
                                        menuAction.action(e, list);
                                        // fetchLists(eventId);
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'flex px-4 py-2 text-sm'
                                      )}
                                    >
                                      <span>{menuAction.label}</span>
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </td>
                  </tr>
                )) : (!loading && !addingNewList) ? (
                  <tr>
                    <td className='text-center py-5' colSpan={2}>No lists.</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          }
      </div>
      </MainLayout>
    </ListsProvider>
  )
};

export default Lists;
