
import React, { useCallback, useContext } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import useRouter from "hooks/useRouter";
import CustomersContext from "contexts/CustomersContext";
import { formatDate, formatTime, getTimeZone, timeZoneAbbreviation } from "utils/formatter";
import UserContext from "contexts/UserContext";

const Table = ({ status }) => {
  const {
    broadcasts,
    broadcastsSortBy,
    broadcastsSortByOrder,
    setBroadcastsSortBy,
    setBroadcastsSortByOrder,
    setBroadcastsPage,
    hasPrevious,
    hasNext,
    loading
  } = useContext(CustomersContext);
  const { navigate } = useRouter();

  const { eventId } = useContext(UserContext);

  const sortIcon = useCallback(sort => {
    if (!sort) return;
    if (broadcastsSortByOrder === "desc") return <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />;
    return <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />;
  }, [broadcastsSortByOrder]);

  const sortForField = useCallback(field => {
    return broadcastsSortBy === field;
  }, [broadcastsSortBy]);

  const onClickFieldHeader = useCallback(field => {
    let fieldSortBy = sortForField(field);
    if (fieldSortBy) {
      const newOrder = broadcastsSortByOrder === "asc" ? "desc" : "asc";
      setBroadcastsSortByOrder(newOrder);
    } else {
      setBroadcastsSortBy(field);
      setBroadcastsSortByOrder("asc");
    }
  }, [sortForField, broadcastsSortByOrder, setBroadcastsSortByOrder, setBroadcastsSortBy]);

  const onClickTitle = useCallback(() => {
    onClickFieldHeader("name");
  }, [onClickFieldHeader]);

  const onClickSendDate = useCallback(() => {
    onClickFieldHeader("send_datetime_utc");
  }, [onClickFieldHeader]);

  const onBroadcastClicked = useCallback((broadcast) => {
    if (broadcast.status === "draft" && broadcast.channel === "sms") {
      navigate(`/events/${eventId}/broadcasts/edit/${broadcast.id}`);
    } else {
      navigate(`/events/${eventId}/broadcasts/${broadcast.id}`);
    }
  }, [eventId, navigate]);

  const groupBroadcastsByDate = (data => {
    let groupedData = data.reduce((acc, obj) => {
      // We need to convert the date to a local timezone in order to group otherwise we would get inconsistent groups
      const date = formatDate(obj.sendDatetimeUtc);
      if (acc[date])
        acc[date].push(obj);
      else
        acc[date] = [obj];
      return acc;
    }, {});

    let result = [];

    Object.keys(groupedData).forEach((val, index) => {
      result.push({
        date: val,
        items: groupedData[val]
      });
    });

    return result;
  });

  return (
    <div className="mt-4 flow-root">
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="relative">
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead className="sticky top-[64px] bg-[#fafafa] z-5">
                <tr>
                  <th scope="col" className="max-w-[4rem] sm:max-w-[6rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <button onClick={onClickSendDate} className="group inline-flex">
                      {status === "sent" ? "Sent" : "Send"} at:
                      <span className={`ml-2 flex-none rounded ${sortForField("send_datetime_utc") ? "bg-gray-100 text-gray-900 group-hover:bg-gray-200" : "invisible text-gray-400 group-hover:visible group-focus:visible"}`}>
                        {sortIcon(sortForField("send_datetime_utc"))}
                      </span>
                    </button>
                  </th>
                  <th scope="col" className="min-w-[4rem] max-w-[3rem] sm:max-w-[18rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    <button onClick={onClickTitle} className="group inline-flex">
                      Name
                      <span className={`ml-2 flex-none rounded ${sortForField("name") ? "bg-gray-100 text-gray-900 group-hover:bg-gray-200" : "invisible text-gray-400 group-hover:visible group-focus:visible"}`}>
                        {sortIcon(sortForField("name"))}
                      </span>
                    </button>
                  </th>
                  <th scope="col" className="max-w-[4rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <button className="group inline-flex">
                      Recipients
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {broadcasts.length > 0?
                  (broadcastsSortBy == "send_datetime_utc" ?
                    groupBroadcastsByDate(broadcasts).map(group => 
                    <React.Fragment key={group.date}>
                      <tr>
                        <td className='sticky top-[112px] px-3 py-2 bg-gray-100 font-bold' colSpan={3}>
                          {group.date || "No Send date"}
                        </td>
                      </tr>
                      { group.items.map((broadcast) => (
                        <tr onClick={() => onBroadcastClicked(broadcast)} className="align-baseline hover:cursor-pointer" key={broadcast.id}>
                          <td className="max-w-[4rem] sm:max-w-[6rem] px-3 py-4 text-sm text-gray-900">
                            <p>{formatTime(broadcast.sendDatetimeUtc)}</p>
                          </td>
                          <td className={`${broadcast.list ? '' : 'align-baseline'} max-w-[3rem] sm:max-w-[18rem] py-4 pr-3 text-sm`}>
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm leading-6 text-gray-900">
                                {broadcast.name}
                              </p>
                            </div>
                          </td>
                          <td className="align-baseline max-w-[4rem] px-3 py-4 text-sm text-gray-900">
                            {broadcast.list?.name}
                          </td>
                        </tr>)) }
                    </React.Fragment>)
                    :
                    broadcasts.map((broadcast) => (
                      <tr onClick={() => onBroadcastClicked(broadcast)} className="hover:cursor-pointer" key={broadcast.id}>
                        <td className="max-w-[4rem] sm:max-w-[6rem] px-3 py-4 text-sm text-gray-900">
                          <p>{formatDate(broadcast.sendDatetimeUtc)}</p>
                          <p>{formatTime(broadcast.sendDatetimeUtc)}</p>
                        </td>
                        <td className={`${broadcast.list ? '' : 'align-baseline'} max-w-[3rem] sm:max-w-[18rem] py-4 pr-3 text-sm`}>
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm leading-6 text-gray-900">{broadcast.name}</p>
                          </div>
                        </td>
                        <td className="align-baseline max-w-[4rem] px-3 py-4 text-sm text-gray-900">
                          {broadcast.list?.name}
                        </td>
                      </tr>
                    ))
                  )
                  :
                  !loading &&
                <tr>
                  <td className='text-center py-5' colSpan={3}>No broadcasts.</td>
                </tr>
                }
              </tbody>
              <tfoot>
                <tr><td className='px-3 py-4 border-t-[1px] bt-gray-600 sticky bottom-[0px] bg-[#fafafa] z-5' colSpan={3}>All times in {getTimeZone()} ({timeZoneAbbreviation()})</td></tr>
              </tfoot>
            </table>
            <nav
              className="flex items-center justify-end"
              aria-label="Pagination"
            >
              <div className="flex flex-1 justify-end items-center sm:justify-end">
                { hasPrevious &&
                  <button
                    onClick={() => setBroadcastsPage((page) => page - 1)}
                    className="disabled:opacity-25 background-transparent outline-none focus:outline-none relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900"
                  >
                    Previous
                  </button>
                }
                { hasPrevious && hasNext && "|" }
                { hasNext &&
                  <button
                    onClick={() => setBroadcastsPage((page) => page + 1)}
                    className="disabled:opacity-25 background-transparent outline-none focus:outline-none relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900"
                  >
                    Next
                  </button>
                }
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
