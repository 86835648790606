import { useCallback, useContext, useState } from "react";
import { GET_REMINDER } from "utils/queries";
import { useSnackbar } from "notistack";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { CREATE_REMINDER, DELETE_REMINDER } from "utils/mutations";
import { defaultNearest30minutesDate } from "utils/misc";
import moment from "moment";
import UserContext from "contexts/UserContext";

const useReminder = () => {
  const { apolloClient, query } = useApi();
  const { captureError } = useRollbar();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [dueAt, setDueAt] = useState(defaultNearest30minutesDate());
  const [note, setNote] = useState("");

  const { eventId } = useContext(UserContext);

  const fetchReminder = useCallback(async (customerId) => {
    try {
      if (!customerId) {
        return;
      }

      if (!eventId) {
        return;
      }

      setLoading(true);
      const {
        data: { reminder }
      } = await query(
        GET_REMINDER({ eventId, customerId })
      );

      setReminder(reminder);
    } catch (e) {
      captureError(e);
      console.log("Failed to load customer", e);
    } finally {
      setLoading(false);
    }
  }, [eventId, captureError, query]);

  const onCreate = useCallback(async (customerId) => {
    try {
      setLoading(true);

      const {
        data: { createReminder }
      } = await apolloClient.mutate({
        mutation: CREATE_REMINDER,
        variables: {
          eventId: parseInt(eventId),
          customerId: parseInt(customerId),
          dueAt: moment(dueAt).utc().format(),
          note: note
        }
      });
      setReminder(createReminder);

      enqueueSnackbar("Reminder has been created", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
      return false;
    }

    return true;
  }, [eventId, note, dueAt, apolloClient, enqueueSnackbar, captureError]);

  const onDelete = useCallback(async (id) => {
    if (!id) {
      enqueueSnackbar("Reminder id is required!" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: DELETE_REMINDER,
        variables: {
          id: parseInt(id)
        }
      });
      setReminder(null);
      enqueueSnackbar("Reminder has been deleted", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  }, [apolloClient, enqueueSnackbar, captureError]);

  return {
    loading,
    setLoading,
    reminder,
    setReminder,
    dueAt,
    setDueAt,
    note,
    setNote,
    fetchReminder,
    onCreate,
    onDelete
  };
};

export default useReminder;
