import useStyles from 'pages/customers/_styles/filters'
import React, { useMemo, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import CustomersContext from 'contexts/CustomersContext'
import FilterOption from 'pages/customers/filters/FilterOption'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const Filters = () => {
  const classes = useStyles()
  const {
    hasFilters,
    openNewSegmentDialog,
    openSegmentsDialog,
    hasSearchConditions,
    resetAll
  } = useContext(CustomersContext)

  return useMemo(() => (
    <div>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Typography className={classes.pageTitle}>Filters</Typography>
        </Grid>
        <Grid container className={classes.optionsContainer}>
          {hasFilters &&
            <Grid item>
              <Button
                onClick={resetAll}
                color='primary'
              >
              Reset
              </Button>
            </Grid>}
          {hasSearchConditions &&
            <Grid item>
              <Button
                onClick={openNewSegmentDialog}
                color='primary'
              >
              Save
              </Button>
            </Grid>}
          <Grid item>
            <Button
              onClick={openSegmentsDialog}
            >
              Saved Segments
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <FilterOption
        keyName='contactInformation'
        title='Contact Information'
      />
      <FilterOption
        keyName='subscriptions'
        title='Subscriptions'
      />
      <FilterOption
        keyName='lifetimeSpend'
        title='Lifetime Spend'
      />
      <FilterOption
        keyName='lastPurchase'
        title='Most Recent Order Date'
      />
      <FilterOption
        keyName='purchaseCount'
        title='Number of Orders'
      />
      <FilterOption
        keyName='assignedAssociate'
        title='Assigned Associate'
      />
      <FilterOption
        keyName='customerProperties'
        title='Customer Properties'
      />
      <FilterOption
        keyName='activeList'
        title='List'
      />
      <FilterOption
        keyName='lastContacted'
        title='Last Contacted'
      />
    </div>
  ), [classes.optionsContainer, classes.pageSubtitle, classes.pageTitle, hasFilters, hasSearchConditions, openNewSegmentDialog, openSegmentsDialog, resetAll])
}

export default Filters
