export default {
  customer: {
    name: "Customer's",
    mergeTags: {
      first_name: {
        name: 'First Name',
        value: '{{customer.firstname}}'
      },
      last_name: {
        name: 'Last Name',
        value: '{{customer.lastname}}'
      },
      referral_code: {
        name: 'Referral Code',
        value: '{{customer.referral_code}}'
      }
    }
  },
  associate: {
    name: 'Your',
    mergeTags: {
      bio: {
        name: 'Bio',
        value: '{{associate.bio}}'
      },
      calendar: {
        name: 'Calendar Link',
        value: '{{associate.calendar_link}}'
      },
      city: {
        name: 'City',
        value: '{{associate.city}}'
      },
      signature: {
        name: 'Email Signature',
        value: '{{associate.email_signature}}'
      },
      associate_first_name: {
        name: 'First Name',
        value: '{{associate.firstname}}'
      },
      associate_last_name: {
        name: 'Last Name',
        value: '{{associate.lastname}}'
      },
      photo_url: {
        name: 'Photo',
        value: '{{associate.photo_url}}'
      },
      title: {
        name: 'Title',
        value: '{{associate.title}}'
      }
    }
  },
  unsubscribe_link: {
    name: 'Email Unsubscribe Link',
    value: '{{unsubscribe_link}}'
  }
}
