import {
  lastPurchase,
  purchaseCount,
  lastContacted,
  eventHistory
} from 'constants/options'

export default {
  fullname: {
    parent: 'fullname',
    key: 'id'
  },
  email: {
    parent: 'contactInformation',
    key: 'email'
  },
  phone: {
    parent: 'contactInformation',
    key: 'phone'
  },
  address1: {
    parent: 'address',
    key: 'address1'
  },
  address2: {
    parent: 'address',
    key: 'address2'
  },
  city: {
    parent: 'address',
    key: 'city'
  },
  state: {
    parent: 'address',
    key: 'state'
  },
  zip: {
    parent: 'address',
    key: 'zip'
  },
  product_type: {
    parent: 'purchaseHistory',
    key: 'category'
  },
  vendor: {
    parent: 'purchaseHistory',
    key: 'brand'
  },
  title: {
    parent: 'purchaseHistory',
    key: 'description'
  },
  from: {
    parent: 'emailHistory',
    key: 'efrom'
  },
  subject: {
    parent: 'emailHistory',
    key: 'subject'
  },
  to: {
    parent: 'emailHistory',
    key: 'eto'
  },
  body: {
    parent: 'emailHistory',
    key: 'body'
  },
  lifetime_spend: {
    parent: 'lifetimeSpend'
  },
  last_order_created_at: {
    parent: 'lastPurchase',
    options: lastPurchase
  },
  orders_count: {
    parent: 'purchaseCount',
    options: purchaseCount,
    key: 'from'
  },
  price: {
    parent: 'purchaseHistory'
  },
  sent_at: {
    parent: 'emailHistory'
  },
  last_contacted: {
    parent: 'lastContacted',
    options: lastContacted,
    nullValue: 'never',
    key: 'option'
  },
  associate_id: {
    parent: 'assignedAssociate',
    key: 'id',
    nullValue: 'unassigned'
  },
  list_id: {
    parent: 'activeList',
    key: 'id'
  },
  opened: {
    parent: 'emailHistory',
    key: 'opened'
  },
  event: {
    parent: 'eventHistory',
    options: eventHistory
  },
  subscribed_to_email: {
    parent: 'subscriptions',
    key: 'email'
  },
  sms_opted_in: {
    parent: 'subscriptions',
    key: 'phone'
  }
}
