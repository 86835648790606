import React, { Fragment, useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Combobox, Transition } from '@headlessui/react'
import { classNames } from 'utils/misc'

const AutocompleteCombo = ({
    className = "",
    labelText,
    options,
    selectedValue,
    onChange,
    optionKeyAttribute,
    optionLabelAttribute,
    secondaryOptionLabelAttribute,
    fullyRounded = false,
    canBeCleared = true 
  }) => {

  const roundedClass = fullyRounded ? "full" : "md";

  const [query, setQuery] = useState("");

  const filteredPeople = useMemo(() =>
    query === '' ? options : options.filter(list => list.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))), 
    [options, query]
  );

  return (
    <Combobox className={className} as="div" by={optionKeyAttribute} value={selectedValue} onChange={onChange} nullable={canBeCleared}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">{labelText}</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className={`w-full h-9 rounded-${roundedClass} border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm`}
          onChange={(e) => setQuery(e.target.value)}
          displayValue={(option) => option && option[optionLabelAttribute]}
        />
        <Combobox.Button className={`absolute inset-y-0 right-0 flex items-center rounded-r-${roundedClass} px-2 focus:outline-none`}>
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        { selectedValue !== null && canBeCleared &&
          <Combobox.Button onClick={() => onChange(null)} className={`absolute inset-y-0 right-0 flex items-center rounded-r-${roundedClass} mr-8 focus:outline-none`}>
            <XMarkIcon className="h-5 w-5 text-gray-400" />
          </Combobox.Button>
        }

        {options && options.length > 0 && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className={`absolute z-8 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((option) => (
                  <Combobox.Option
                    key={option[optionKeyAttribute]}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex">
                          <span className={classNames('truncate', selected && 'font-semibold')}>{option[optionLabelAttribute]}</span>
                          <span
                            className={classNames(
                              'ml-2 truncate text-gray-500',
                              active ? 'text-indigo-200' : 'text-gray-500'
                            )}
                          >
                            {option[secondaryOptionLabelAttribute]}
                          </span>
                        </div>
    
                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        )}
      </div>
    </Combobox>
  )
};

export default React.memo(AutocompleteCombo);
