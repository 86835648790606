import React, { useCallback, useContext, useEffect, useState } from 'react'
import useCustomer from 'hooks/useCustomer';
import MainLayout from 'components/MainLayout';
import MessageHistory from 'components/MessageHistory';
import SendMessage from 'components/SendMessage';
import SimpleSendMessage from 'components/SimpleSendMessage';
import useCustomerMessages from 'hooks/useCustomerMessages';
import useRouter from "hooks/useRouter";
import moment from "moment";
import ReminderModal from "components/ReminderModal";
import useReminder from "hooks/useReminder";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { defaultNearest30minutesDate } from "utils/misc";
import { ReminderProvider } from "contexts/ReminderContext";
import { formatDateWithTimezone } from "utils/formatter";
import queryParams from 'utils/queryParams'
import UserContext from "contexts/UserContext";
import { ClockIcon, EnvelopeIcon, PencilIcon, DevicePhoneMobileIcon, TableCellsIcon, ShieldCheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import PeopleListsDialog from "components/people/PeopleListsDialog";
import EmptyPersonState from 'components/person/EmptyPersonState';
import { hasFlag } from 'country-flag-icons'
import PhoneVerificationAlert from 'components/person/PhoneVerificationAlert';
import { Tooltip } from "react-tooltip";
import ScheduledMessagesDialog from 'components/ScheduledMessagesDialog';
import CustomersContext from "contexts/CustomersContext";

const PersonView = ({ eventId, id, showReminderModal = false }) => {
  const { customer, fetchCustomer, loading:customerLoading, phoneVerification, fetchPhoneVerification } = useCustomer();
  const smsOptInDate = moment(customer.smsOptInDate);
  const smsOptOutDate = moment(customer.smsOptOutDate);
  const { fetchCustomerMessages, messages, MESSAGES_PER_PAGE, page, setPage, messagesTotal, loading, fetchMessage } = useCustomerMessages();
  const { navigate } = useRouter();
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(showReminderModal || false);
  const reminderHooks = useReminder();
  const { reminder, setDueAt, setNote, loading: isReminderLoading, fetchReminder, onDelete: onDeleteReminder } = reminderHooks;
  const { ecommerceExperienceFeatureFlag, setEventId, currentEventName, excludeSmsFeatureFlag } = useContext(UserContext);
  const { upcomingBroadcasts, fetchUpcomingBroadcasts } = useContext(CustomersContext);

  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [pages, setPages] = useState([]);

  const [peopleListsDialog, setPeopleListsDialog] = useState(false);
  const [phoneVerificationAlert, setPhoneVerificationAlert] = useState(false);
  const [scheduledMessagesDialog, setScheduledMessagesDialog] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => setPages([
    { name: currentEventName, href: `/events/${eventId}/people`, current: false },
  ]), [currentEventName]);

  useEffect(() => {
    setEventId(eventId);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
        { name: customer.fullName, href: `/events/${eventId}/person/${customer.id}`, current: true }
      ]);
    } else if (scrollPosition < 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
      ]);
    }
  }, [scrollPosition]);

  useEffect(() => {    
    // Whenever a query param in the style of ?anchor=element_id comes
    // with the page we parse it and scroll that element_id into view.
    // This is used mainly to react to new messages notifications.
    const anchorId = queryParams.get().anchor;
    const hashElement = document.getElementById(anchorId);
    if (hashElement) {
      setTimeout(() => {
        hashElement.scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
        });
      }, 500);
    }
  }, []);

  // Fetch customer data
  useEffect(() => {
    const initCustomer = async () => {
      await fetchCustomer(id);
      await fetchPhoneVerification(eventId, id);
    };
    initCustomer();
  }, [id, fetchCustomer]);

  // Fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      await fetchCustomerMessages(id);
    };
    fetchMessages();
  }, [id, fetchCustomerMessages]);

  // Fetch upcoming broadcasts
  useEffect(() => {
    const fetchCustomerBroadcasts = async () => {
      await fetchUpcomingBroadcasts(id, eventId);
    };
    fetchCustomerBroadcasts();
  }, [id, fetchUpcomingBroadcasts]);

  // Fetch active reminder
  useEffect(() => {
    const initReminder = async () => {
      await fetchReminder(id);
    };
    initReminder();
  }, [id, fetchReminder]);

  const onMessageSendComplete = useCallback(async () => {
    await fetchCustomerMessages(id);
    setPage(1);
    
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  }, [id, fetchCustomerMessages, setPage]);

  const onPreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const onNextPage = useCallback(() => {
    if (page < messagesTotal / MESSAGES_PER_PAGE) {
      setPage(page + 1);
    }
  }, [page, messagesTotal, MESSAGES_PER_PAGE, setPage]);

  const onEditPerson = useCallback(() => {
    navigate(`/events/${eventId}/person/edit/${id}`);
  }, [id, eventId, navigate]);

  const openPeopleListsDialog = () => {
    setPeopleListsDialog(true);
  }

  const closePeopleListsDialog = () => {
    setPeopleListsDialog(false);
  }

  const openScheduleMessagesDialog = () => {
    setScheduledMessagesDialog(true);
  }

  const closeScheduleMessagesDialog = () => {
    setScheduledMessagesDialog(false);
  }

  const onAddReminderButtonClicked = useCallback(() => {
    setDueAt(defaultNearest30minutesDate());
    setNote("");
    setIsReminderModalOpen(true);
    window.history.replaceState(window.history.state, "", `/events/${eventId}/person/${id}/reminders/new`);
  }, [id, setDueAt, setNote, eventId]);

  const onCancelReminderModal = useCallback(() => {
    setIsReminderModalOpen(false);
    window.history.replaceState(window.history.state, "", `/events/${eventId}/person/${id}`);
  }, [id, eventId]);

  const onSaveReminderModal = useCallback(() => {
    setIsReminderModalOpen(false);
    window.history.replaceState(window.history.state, "", `/events/${eventId}/person/${id}`);
  }, [id, eventId]);

  const onNewMessageCallback = useCallback(async (data) => {
    // Check if the customer id is the same of the message coming in
    if (parseInt(data.customer_id) === parseInt(id)) {
      await fetchMessage(data.id);
      setPage(1);
      
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
        // Do not show the message notification
      return true;
    }
    // If the message notification is from a different customer we show the notification
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchMessage, setPage]);

  const unsubscribed = (customer.smsOptOutDate && smsOptOutDate > smsOptInDate) || (customer.smsOptOutDate && !customer.smsOptInDate);
  const customerPhone = customer.phone;
  
  return (
    <ReminderProvider value={reminderHooks}>
      <MainLayout pages={pages} onNewMessageCallback={onNewMessageCallback}>
        { (!customerLoading && Object.getOwnPropertyNames(customer).length !== 0 && customer.active) ? (
          <div className='w-full max-w-700'>
            {/* Reminder Modal */}
            {isReminderModalOpen &&
              <ReminderModal
                customerId={id}
                open={isReminderModalOpen}
                onCancel={onCancelReminderModal}
                onSaveReminder={onSaveReminderModal} />
            }
    
            {/* Active Reminder status bar */}
            {!isReminderLoading && reminder &&
              <div className="flow-root rounded-md bg-blue-50 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CalendarIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-blue-800">{formatDateWithTimezone(reminder.dueAt)}</h3>
                    <div className="mt-2 text-sm text-blue-700">
                      <p>{reminder.note}</p>
                    </div>
                    <div className="mt-4">
                      <div className="-mx-2 -my-1.5 flex">
                        <button
                          type="button"
                          className="rounded-md bg-blue-50 px-2 py-1.5 text-sm font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
                          onClick={() => onDeleteReminder(reminder.id)}
                        >
                          Dismiss
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
    
            <div className="flex items-center">
              <h1 id="primary-heading" className="font-bold text-3xl">
                {customer.fullName}
              </h1>
    
              <button
                onClick={onEditPerson}
                type="button"
                className="ml-4 mt-[2px] inline-flex items-center rounded bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <PencilIcon className="w-3 h-3 mr-1" />
                Edit
              </button>
              <button
                onClick={openPeopleListsDialog}
                type="button"
                className="ml-4 mt-[2px] inline-flex items-center rounded bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <TableCellsIcon className="w-3 h-3 mr-1" />
                Lists
              </button>
    
              {!isReminderLoading && !reminder && ecommerceExperienceFeatureFlag &&
                <button
                  onClick={onAddReminderButtonClicked}
                  type="button"
                  className="ml-2 mt-[2px] inline-flex items-center rounded bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <ClockIcon className='w-3 h-3 mr-1' />
                  Add reminder
                </button>
              }
            </div>
            {customer.formattedPhone &&
              <p className='flex items-center pt-4'>
                {Object.keys(phoneVerification).length ? (
                  phoneVerification.validNumber && !['fixedVoip', 'landline'].includes(phoneVerification.lineTypeIntelligence?.type) ? (
                    <>
                      <ShieldCheckIcon className="h4 w-4 mr-2" id="verified-phone-number" />
                      <Tooltip anchorSelect="#verified-phone-number">Verified mobile phone number.</Tooltip>
                      {hasFlag(phoneVerification.countryCode) ? (
                        <>
                          <Tooltip anchorSelect="#country-flag">{phoneVerification.countryCode}</Tooltip>
                          <img 
                            alt={phoneVerification.countryCode} 
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${phoneVerification.countryCode}.svg`} 
                            className="h4 w-4 mr-2" 
                            id="country-flag"
                          />
                          +{phoneVerification.callingCountryCode} {phoneVerification.nationalFormat.replace(/^0+/, '')}
                        </>
                      ) : (
                        `${phoneVerification.countryCode} +${phoneVerification.callingCountryCode} ${phoneVerification.nationalFormat.replace(/^0+/, '')}`
                      )}
                    </>
                  ) : (
                    <>
                      <ExclamationTriangleIcon className="h4 w-4 mr-2 cursor-pointer" onClick={() => setPhoneVerificationAlert(true)} />
                      {customer.phone}
                    </>
                  )
                ) : customer.phone}
                <span className='ml-3'>
                  {customer.smsOptedIn &&
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                      <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Subscribed
                    </span>
                  }
                  {unsubscribed &&
                    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                      <svg className="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Unsubscribed
                    </span>
                  }
                  {!customer.smsOptedIn && !customer.smsOptOutDate && customer.phone &&
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                      <svg className="h-1.5 w-1.5 fill-gray-400" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Not yet subscribed
                    </span>
                  }
                </span>
              </p>
            }
            {customer.email &&
              <p className='flex items-center pt-2'>
                <EnvelopeIcon className='h-4 w-4 mr-2' />
                {customer.email}
              </p>
            }
    
            {messages.length > 0 &&
              <MessageHistory 
                messages={messages} 
                page={page} 
                invertOrder={true} 
                loading={loading} 
                perPage={MESSAGES_PER_PAGE} 
                totalCount={messagesTotal} 
                onPreviousPage={onPreviousPage} 
                onNextPage={onNextPage} 
              />
            }
            {upcomingBroadcasts?.upcomingCount && (
              <>
                <p className="text-md font-medium px-2 py-2 pb-0">
                  {upcomingBroadcasts.upcomingCount} future {upcomingBroadcasts.upcomingCount == 1 ? 'message' : 'messages'} scheduled 
                  <span className="text-blue-600 cursor-pointer" onClick={openScheduleMessagesDialog}> View Messages</span>
                </p>
                {scheduledMessagesDialog && 
                  <ScheduledMessagesDialog 
                    open={scheduledMessagesDialog} 
                    closeScheduleMessagesDialog={closeScheduleMessagesDialog} 
                    broadcasts={upcomingBroadcasts.upcomingBroadcastsForCustomer} 
                    firstname={customer.firstname}
                    eventId={eventId}
                  />
                }
              </>
            )}
            {excludeSmsFeatureFlag ?
              <SimpleSendMessage customerId={id} unsubscribed={unsubscribed} onSendComplete={onMessageSendComplete} />
              : <SendMessage customerId={id} msgLoading={loading} messages={messages} unsubscribed={unsubscribed} customerPhone={customerPhone} editPerson={onEditPerson} onSendComplete={onMessageSendComplete} page={page} />
            }
            {peopleListsDialog && <PeopleListsDialog eventId={eventId} customerId={id} peopleListsDialog={peopleListsDialog} closePeopleListsDialog={closePeopleListsDialog} />}
            {phoneVerificationAlert && <PhoneVerificationAlert pv={phoneVerification} pvAlert={phoneVerificationAlert} setPvAlert={setPhoneVerificationAlert} />}
          </div>
        ) : !customerLoading ? (
          <div className='w-full max-w-700'>
            <EmptyPersonState eventId={eventId}/>
          </div>
        ) : null }
      </MainLayout>
    </ReminderProvider>
  )
}

export default PersonView;
