import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import ImagePreview from './ImagePreview'
import { AddAPhoto } from '@material-ui/icons'
import prettyBytes from 'pretty-bytes';
import { XCircleIcon } from "@heroicons/react/20/solid";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 2,
  height: '150px',
  textAlign: 'center',
  borderRadius: 20,
  cursor: 'pointer',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const Dropzone = ({
  handleFile, attachments, handleDelete,
  acceptedFormats = "image/*", maxFiles = 0, maxSize = 500 * 1000}
) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleFile(acceptedFiles[0]);
    acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
    setFiles([acceptedFiles[0]]);
  }, [handleFile]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: acceptedFormats, onDrop, maxFiles, maxSize });

  const [files, setFiles] = useState(attachments ? [].concat(attachments) : []);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragAccept, isDragActive, isDragReject]);

  const deleteAttachment = useCallback(() => {
    setFiles([]);
    handleDelete(null);
  }, [handleDelete]);

  useEffect(() => setFiles(attachments ? [].concat(attachments) : []), [attachments]);

  return (
    <section className='container' style={{ width: '270px', height: '150px', borderRadius: '43px' }}>
      {
        !files.length &&
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <br />
            <br />
            <AddAPhoto />
            <p><b>Upload a file</b> or drag and drop</p>
            <p>{acceptedFormats === "image/*" ? "PNG, JPG, GIF" : ".CSV file"} up to {prettyBytes(maxSize)}</p>
          </div>
      }
      { files.length > 0 && acceptedFormats === "image/*" &&
        <ImagePreview fileName={files[0].name} fileUrl={files[0].preview} handleDelete={deleteAttachment} />
      }
      { files.length > 0 && acceptedFormats !== "image/*" &&

        <div className="border-2 border-[#eeeeee] rounded-2xl border-dashed">
          <strong>Uploaded file:</strong>
          <p className="mt-6">
            {files[0].name}
          </p>
          <button className="p-4 mt-6 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-1.5 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" onClick={deleteAttachment}>
            <XCircleIcon className="w-3 h-3 text-white mr-1" />
            Remove file
          </button>
        </div>
      }
    </section>
  )
}

export default Dropzone;
