import React, { useMemo, useContext } from 'react'
import CustomersContext from 'contexts/CustomersContext'
import Grid from '@material-ui/core/Grid'
import useStyles from 'pages/customers/broadcastDialog/_styles/thirdStep'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import { countFormat } from 'utils/formatter'
import moment from 'moment'
import cx from 'classnames'

const timezone = () => {
  const date = new Date().toString()
  const locale = date.match(/\((.*)\)/).pop().replace('Standard ', '')
  return locale
}

const ThirdStep = () => {
  const classes = useStyles()
  const {
    selectedTemplate,
    sendTestEmail,
    broadcastStep,
    selectedCustomers,
    broadcastDate
  } = useContext(CustomersContext)

  const customerCount = useMemo(() => {
    const count = Object.keys(selectedCustomers).length
    return `${countFormat(count)} customer${count === 1 ? '' : 's'}`
  }, [selectedCustomers])

  return useMemo(() => (
    <Grid container className={cx(classes.container, broadcastStep !== 2 && classes.hidden)} justifyContent='center'>
      <Grid item xs={12} md={8} container className={classes.item}>
        <Grid item xs={6}>
          <Typography className={classes.summaryTitle}>Summary</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.buttonTest}
            onClick={sendTestEmail}
            startIcon={<SendIcon />}
            variant='outlined'
            color='primary'
          >
            Send test email
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container className={classes.item}>
        <Grid item xs={6}>
          <Typography className={classes.rowTitle}>Audience size</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{customerCount}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container className={classes.item}>
        <Grid item xs={6}>
          <Typography className={classes.rowTitle}>Email template</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{selectedTemplate?.title || '(no title)'}</Typography>
          <Typography className={classes.subject}>{selectedTemplate?.subject || '(no subject)'}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container className={classes.item}>
        <Grid item xs={6}>
          <Typography className={classes.rowTitle}>Send Time</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{moment(broadcastDate).format('LLLL')}</Typography>
          <Typography>{moment(broadcastDate).fromNow()}</Typography>
          <Typography className={classes.timezone}>{timezone()}</Typography>
        </Grid>
      </Grid>
    </Grid>
  ), [broadcastDate, broadcastStep, classes.buttonTest, classes.container, classes.hidden, classes.item, classes.rowTitle, classes.subject, classes.summaryTitle, classes.timezone, customerCount, selectedTemplate, sendTestEmail])
}

export default ThirdStep
