import { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ClipboardDocumentIcon, IdentificationIcon, VideoCameraIcon, XMarkIcon } from "@heroicons/react/24/outline"

export default function DownloadContactCardAlert({ open, setOpen, contact_card_url }) {
  const [urlCopied, setUrlCopied] = useState(false);

  const copyDownloadUrl = () => {
    setUrlCopied(true);

    navigator.clipboard.writeText(contact_card_url)

    setTimeout(() => {
      setUrlCopied(false);
    }, 1000);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="flex justify-between">
                  <Dialog.Title as="h3" className="text-xl font-medium">
                    Share Contact Card
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-center my-3">
                  <VideoCameraIcon className="h-4 w-4" />
                  <a href="https://txtmsg.app/l/demo_link_preview_with_contact_card" target="_blank" className="text-blue-600 ml-2">
                    How it works
                  </a>
                </div>
                <p className="mt-3 text-base text-black/50">
                  Share this link to make it easier for people to remember your phone number. People who save the contact card to their address book will have an improved messaging experience.
                </p>
                <div className="mt-5 flex flex-wrap items-center gap-2">
                  <a className="text-blue-600" href={contact_card_url} target="_blank">
                    Contact Card Link
                  </a>
                  {urlCopied ? <p>copied!</p> : <ClipboardDocumentIcon className="cursor-pointer h-5 w-5" onClick={copyDownloadUrl} />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
