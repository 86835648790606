import { useCallback, useContext } from 'react'
import useApi from 'hooks/useApi'
import {
  EVENT_ID,
  ASSOCIATE_OPTIONS,
  CUSTOMER_PROPERTIES,
  SEGMENTS,
  EVENT_HISTORY
} from 'utils/queries'
import useRollbar from 'hooks/useRollbar'
import UserContext from "contexts/UserContext"

const useCustomersOptions = () => {
  const { query } = useApi()
  const { eventId } = useContext(UserContext);
  const { captureError } = useRollbar()

  const getSegmentOptions = useCallback(async () => {
    try {
      const {
        data: {
          event: { segments }
        }
      } = await query(SEGMENTS(eventId))

      return segments.filter(s => s.active)
    } catch (e) {
      captureError(e)
      console.log('Failed to fetch segments', e)
      return null
    }
  }, [eventId, query, captureError])

  const getAssociateOptions = useCallback(async () => {
    try {
      const {
        data: {
          event: { associates }
        }
      } = await query(ASSOCIATE_OPTIONS(eventId))

      return associates.map(a => ({ name: a.fullname, id: a.id }))
    } catch (e) {
      captureError(e)
      console.log('Failed to fetch associates', e)
      return null
    }
  }, [eventId, query, captureError])

  const getCustomerPropertiesOptions = useCallback(async () => {
    try {
      const {
        data: {
          event: { customerProperties }
        }
      } = await query(CUSTOMER_PROPERTIES(eventId))

      return customerProperties.filter(cp => cp.active)
    } catch (e) {
      captureError(e)
      console.log('Failed to fetch customer propertiess', e)
      return null
    }
  }, [eventId, query, captureError])

  const getEventHistoryOptions = useCallback(async () => {
    try {
      const {
        data: {
          event: { shopifyEventTypes, eventTypes }
        }
      } = await query(EVENT_HISTORY(eventId))

      const events = [...shopifyEventTypes, ...eventTypes]
      const ids = events.map(e => e.body)
      const res = events
        .filter((e, index) => e.active && e.body && e.title && ids.indexOf(e.body) === index)
        .map(e => ({
          name: e.title,
          id: e.body
        }))
      return res
    } catch (e) {
      captureError(e)
      console.log('Failed to fetch event history options', e)
      return null
    }
  }, [eventId, query, captureError])

  return {
    getAssociateOptions,
    getCustomerPropertiesOptions,
    getSegmentOptions,
    getEventHistoryOptions
  }
}

export default useCustomersOptions
