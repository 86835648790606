import React, { useEffect, useMemo, useState, useCallback, forwardRef, useContext } from 'react'
import useStyles from 'pages/customers/broadcastDialog/_styles/saveDraft'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomersContext from 'contexts/CustomersContext'
import Slide from '@material-ui/core/Slide'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SaveDraft = () => {
  const {
    closeBroadcastDraftDialog,
    discardBroadcast,
    closingDialog,
    listName,
    createDraft,
    broadcastDraftDialog
  } = useContext(CustomersContext)
  const classes = useStyles()

  const [name, setName] = useState(listName)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setName(listName)
  }, [listName])

  const onChange = useCallback(e => setName(e.target.value), [])

  const onSave = useCallback(async () => {
    setLoading(true)
    await createDraft(name)
    setLoading(false)
  }, [createDraft, name])

  return useMemo(() => (
    <Dialog
      open={broadcastDraftDialog}
      TransitionComponent={Transition}
      fullWidth
      onClose={closeBroadcastDraftDialog}
      classes={{
        paper: classes.paper
      }}
      maxWidth='xs'
    >
      <DialogTitle>Save broadcast as draft</DialogTitle>
      <div className={classes.inputContainer}>
        <TextField
          placeholder='Broadcast Name'
          onChange={onChange}
          variant='outlined'
          value={name}
          classes={{
            root: classes.inputRoot
          }}
        />
      </div>
      <DialogActions className={classes.actions}>
        {closingDialog &&
          <Button onClick={discardBroadcast} className={classes.discard}>
            Discard
          </Button>}
        <Button onClick={closeBroadcastDraftDialog}>
            Cancel
        </Button>
        <Button onClick={onSave} variant='contained' color='primary' disabled={!name}>
          {loading ? (
            <Grid container justifyContent='center'>
              <CircularProgress size={30} />
            </Grid>
          ) : 'Save Draft'}
        </Button>
      </DialogActions>
    </Dialog>
  ), [broadcastDraftDialog, classes.actions, classes.discard, classes.inputContainer, classes.inputRoot, classes.paper, closeBroadcastDraftDialog, closingDialog, discardBroadcast, loading, name, onChange, onSave])
}

export default SaveDraft
