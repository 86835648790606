import React, {
  useMemo,
  useCallback,
  useState,
  useContext
} from "react";
import AppBar from "@material-ui/core/AppBar";
import useStyles from "./_styles/topbar";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Searchbar from "components/Searchbar";
import useRouter from "hooks/useRouter";
import UserContext from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import useAuth from "hooks/useAuth";

const Topbar = () => {
  // We need to reference the useAuth hook here so that we make sure the fetchMe useEffect runs on
  // every page.
  useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth0();
  const { user } = useContext(UserContext);
  const { path } = useRouter();
  const withSearchBarPaths = ["/", "/customers"];
  const withSearchBar = withSearchBarPaths.includes(path);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return useMemo(
    () => (
      <>
        <AppBar
          color="inherit"
          position="fixed"
          className={classes.appBar}
          classes={{ root: classes.root }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={7}>
              { withSearchBar &&
                <div className="ml-28">
                  <Searchbar />
                </div>
              }
            </Grid>
            <Grid item xs={3} className={classes.userMenu}>
              <Button
                onClick={handleClick}
                className={classes.avatar}
                endIcon={<ExpandMoreIcon />}
              >
                {user?.firstName} {user?.lastName}
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List component="nav" aria-label="logout">
                  <ListItem button onClick={logout}>
                    <ListItemText primary="Sign Out" />
                  </ListItem>
                </List>
              </Popover>
            </Grid>
          </Grid>
        </AppBar>
      </>
    ),
    [
      anchorEl,
      classes.appBar,
      classes.avatar,
      classes.root,
      classes.userMenu,
      user,
      handleClick,
      handleClose,
      logout,
      withSearchBar,
    ]
  );
};

export default Topbar;
