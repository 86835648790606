import React, { Fragment, useState, useContext, useEffect } from "react"
import MainLayout from "components/MainLayout"

import { PeopleProvider } from "contexts/PeopleContext"
import UserContext from "contexts/UserContext"
import usePeople from "hooks/usePeople"
import Table from "components/people/Table"
import Searchbar from "components/people/Searchbar"
import Filters from "components/people/Filters"
import useRouter from "hooks/useRouter"
import { Menu, Transition } from "@headlessui/react"
import { ListBulletIcon, ChevronDownIcon, DocumentPlusIcon, UserIcon } from "@heroicons/react/20/solid"
import AddPeopleToListDialog from "components/people/AddPeopleToListDialog";
import { classNames } from "utils/misc";

const People = ({ eventId }) => {
  const customersHooks = usePeople()
  const { navigate } = useRouter()
  const { ecommerceExperienceFeatureFlag, excludeImportsFeatureFlag, setEventId, currentEventName } = useContext(UserContext)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [pages, setPages] = useState([])
  
  const { activeList, selectedListName } = customersHooks;

  const [listsDialog, setListsDialog] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(
    () => setPages([{ name: currentEventName, href: `/events/${eventId}/people`, current: false }]),
    [currentEventName]
  )

  useEffect(() => {
    setEventId(eventId)
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/people`, current: false },
        { name: "People", href: `/events/${eventId}/people`, current: true }
      ])
    } else if (scrollPosition < 120) {
      setPages([{ name: currentEventName, href: `/events/${eventId}/people`, current: false }])
    }
  }, [scrollPosition])

  const openListsDialog = () => {
    setListsDialog(true);
  }

  const closeListsDialog = () => {
    setListsDialog(false);
  }

  return (
    <PeopleProvider value={customersHooks}>
      <MainLayout pages={pages}>
        <div className="w-full max-w-700">
          <div className="flex flex-wrap items-center justify-between">
            <h1 id="primary-heading" className="font-bold text-3xl">
              People
            </h1>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-white font-medium border border-transparent bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Add
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm text-start w-full"
                          )}
                          onClick={() => navigate(`/events/${eventId}/person/new`)}
                        >
                          <UserIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          New person to this event
                        </button>
                      )}
                    </Menu.Item>
                    {/* {!excludeImportsFeatureFlag && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm text-start w-full"
                            )}
                            onClick={() => navigate(`/events/${eventId}/people/import`)}
                          >
                            <DocumentPlusIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            People from a file import
                          </button>
                        )}
                      </Menu.Item>
                    )} */}
                    {(activeList && selectedListName !== '' && selectedListName !== 'All') && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm w-full"
                            )}
                            onClick={openListsDialog}
                          >
                            <ListBulletIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            People to this list
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {ecommerceExperienceFeatureFlag ? (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm text-start w-full"
                            )}
                            onClick={() => navigate(`/events/${eventId}/people/import`)}
                          >
                            <DocumentPlusIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            People from a file import
                          </button>
                        )}
                      </Menu.Item>
                    ) : (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm text-start w-full"
                            )}
                            onClick={() => navigate(`/events/${eventId}/people/flatfile-import`)}
                          >
                            <DocumentPlusIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            People from a file import
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <div className="mt-4">
            <Searchbar />
          </div>

          <Filters />

          <Table />
        </div>

        {listsDialog && activeList ? (
          <AddPeopleToListDialog eventId={eventId} activeList={activeList} selectedListName={selectedListName} listsDialog={listsDialog} closeListsDialog={closeListsDialog} />
        ) : null}
      </MainLayout>
    </PeopleProvider>
  )
}

export default People
