import { useContext, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CREATE_SMS_BROADCAST_FOR_CUSTOMER } from "utils/mutations";
import useApi from "hooks/useApi";
import useRollbar from "hooks/useRollbar";
import { useSnackbar } from "notistack";
import useRouter from "hooks/useRouter";
import { XMarkIcon } from '@heroicons/react/24/outline'

import { getTimeZone, timeZoneAbbreviation } from "utils/formatter";
import DateTimeSelector from './DateTimeSelector';
import CustomersContext from 'contexts/CustomersContext';

const ScheduleDialog = ({ open, setOpen, eventId, customerId, message, setMessage }) => {
  const { navigate } = useRouter();
  const { fetchUpcomingBroadcasts } = useContext(CustomersContext);

  const { captureError } = useRollbar();
  const { enqueueSnackbar } = useSnackbar();
  const { apolloClient } = useApi();

  const [dateTime, setDateTime] = useState(null)
  const [dateTimeError, setDateTimeError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [messageScheduled, setMessageScheduled] = useState(false);
  const [broadcastId, setBroadcastId] = useState('');

  const scheduleMessage = async () => {
    if (dateTime < new Date()) {
      setDateTimeError(true);
      return;
    } 

    try {
      setLoading(true);

      const resp = await apolloClient.mutate({
        mutation: CREATE_SMS_BROADCAST_FOR_CUSTOMER,
        variables: {
          eventId: parseInt(eventId),
          customerIds: [parseInt(customerId)],
          sendDatetimeUtc: dateTime.toUTCString(),
          messageBody: message
        },
      });

      setBroadcastId(resp.data?.createSmsBroadcastForCustomer?.id);
      setMessageScheduled(true);
      setMessage("");

      fetchUpcomingBroadcasts(customerId, eventId)
    } catch (e) {
      enqueueSnackbar("Failed to schedule message:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);

      setOpen(false);
      setLoading(false);
    }
  };

  const navigateToBroadcast = () => {
    navigate(`/events/${eventId}/broadcasts/${broadcastId}`);
  };

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-5 pt-5 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    { messageScheduled ? 'Your message has been scheduled' : 'Schedule message delivery' }
                  </Dialog.Title>
                
                { !messageScheduled && 
                  <p className="mt-2 text-sm text-gray-500">
                    All times in {getTimeZone()} ({timeZoneAbbreviation()})
                  </p>
                }
                <div className='mt-4'>
                  <div className="text-sm text-gray-500">
                    { messageScheduled ? (
                      <>
                        <p>You can view, edit, or cancel your scheduled message <span className='text-blue-600 cursor-pointer' onClick={navigateToBroadcast}>here</span></p>
                      </>
                    ) : 'Select when your message should be sent (at least three minutes from now):' }
                  </div>
                  { !messageScheduled && 
                    <div className='flex justify-start mt-2'>
                      <DateTimeSelector dateTime={dateTime} setDateTime={setDateTime} dateTimeError={dateTimeError} setDateTimeError={setDateTimeError} />
                    </div>
                  }
                </div>
                
                <div className="mt-6 flex justify-end">
                  <button
                    type="button"
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setOpen(false)}
                    data-autofocus
                  >
                    {!messageScheduled ? 'Cancel' : 'Close'} 
                  </button>
                  { !messageScheduled && (
                    <button
                      type="button"
                      className="rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none ml-3 disabled:opacity-25"
                      onClick={scheduleMessage}
                      disabled={dateTimeError || loading}
                    >
                      Schedule Message
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ScheduleDialog;