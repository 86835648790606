import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  paper: {
  },
  inputRoot: {
    // color: 'inherit',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1)
    },
    width: '100%',
    padding: theme.spacing(0, 2, 2, 2)
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  discard: {
    color: theme.palette.error.main
  }
}))
