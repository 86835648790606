import { alpha, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  paper: {
    top: '20%',
    position: 'fixed'
  },
  inputContainer: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3, 0, 3),
      width: 'auto'
    }
  },
  inputRoot: {
    width: '100%',
    color: 'inherit'
  },
  actions: {
    justifyContent: 'space-between',
    margin: theme.spacing(1, 2)
  },
  inputInput: {
    padding: theme.spacing(),
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}))
